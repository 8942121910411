/* eslint-disable camelcase */
/* eslint-disable no-param-reassign */
import { format } from 'date-fns';
import {
  AxisBase2D,
  AxisMarkerAnnotation,
  DateTimeNumericAxis,
  EAutoRange,
  EAxisAlignment,
  EDataPointWidthMode,
  EErrorDirection,
  EErrorMode,
  ELabelPlacement,
  EllipsePointMarker,
  ENumericFormat,
  EStrokePaletteMode,
  FastErrorBarsRenderableSeries,
  FastLineRenderableSeries,
  HlcDataSeries,
  IDataSeries,
  IPointMarkerPaletteProvider,
  IPointMetadata,
  IRenderableSeries,
  NumberRange,
  NumericAxis,
  OverviewRangeSelectionModifier,
  parseColorToUIntArgb,
  SciChartSurface,
  SmartDateLabelProvider,
  TPointMarkerArgb,
  TSciChart,
  VerticalLineAnnotation,
  XyDataSeries,
  XyScatterRenderableSeries,
  XySeriesInfo,
} from 'scichart';
import { appTheme } from 'scichart-example-dependencies';

import { ResAlarmHistoryV3 } from '@/apis/alarm/type';
import { COLORS } from '@/components/charts/config/trend';
import { PARAMS } from '@/constants/data';
import { TLayoutProperty } from '@/constants/types';
import { alarmColors } from '@/pages/CentralMain/config/colors';
import { AlarmLevel, Data, TrendKey, WaveKeys } from '@/pages/CentralMain/types';
import adjustToNearest from '@/utils/adjustToNearest';
import { makeTransparent } from '@/utils/makeTranspatent';

import { getFormattedTimeValue } from './createXYDataSeries';
import CustomTickProvider from './CustomTickProvider';
import { getParamRangeAdjust, getParamTrendRange } from './paramConfig';
import { TLineTimeValue } from '../../../type';

export const makeYAxis = (
  id: string,
  title: string,
  wasmContext: TSciChart,
  param: TrendKey | WaveKeys,
  color: string,
  isRight = false,
  isWave = false,
  showDecimals = false,
) => {
  const range = getParamTrendRange(param);
  return new NumericAxis(wasmContext, {
    id: `${id}`,
    visibleRange: range ? new NumberRange(range.min, range.max) : undefined,
    // Amy: min, max 모두 계산하기 때문에 auto range 끄기
    autoRange: EAutoRange.Never,
    axisAlignment: isRight ? EAxisAlignment.Right : EAxisAlignment.Left,
    zoomExtentsToInitialRange: false,
    drawMinorGridLines: false,
    axisBorder: { borderTop: 5, borderBottom: 5 },
    // Amy : title 따로 구현 (색상 따로 넣기 위해)
    // axisTitle: `${title}`,
    axisTitleStyle: { color },
    isVisible: !isRight,
    drawLabels: !isWave,
    maxAutoTicks: 3,
    labelFormat: ENumericFormat.Decimal,
    labelPrecision: showDecimals ? 1 : 0,
    axisThickness: 5,
  });
};

// Amy: custom param 의 경우에 어떤 파라미터들이 들어가는지 정의 (nibp의 경우는 데이터를 따로 만들기 때문에 빈 배열을 return )
export const getCustomTrendParams = (param: TrendKey): (keyof Data['params'])[] => {
  switch (param) {
    case 'ibp1':
      return ['ibp1_d', 'ibp1_m', 'ibp1_s'];
    case 'ibp2':
      return ['ibp2_d', 'ibp2_m', 'ibp2_s'];
    case 'nibp':
      return [];
    case 'ppause/pmean/peep':
      return ['ppause', 'pmean', 'peep'];
    case 'rr/rr_spont/etco2_rr':
      return ['rr', 'rr_spont', 'etco2_rr'];
    case 'vti/vte':
      return ['vti', 'vte'];
    case 'vme/vme_s':
      return ['vme', 'vme_s'];
    case 'fio2/spo2':
      return ['fio2', 'spo2'];
    case 'ti/te/tc':
      return ['ti', 'te', 'tc'];
    case 'flow/fi_peak/fe_peak':
      return ['flow', 'fi_peak', 'fe_peak'];
    case 'p_0.1/auto_peep':
      return ['p_0.1', 'auto_peep'];
    case 'vico2/veco2':
      return ['vico2', 'veco2'];
    case 'pi/etco2/ico2':
      return ['pi', 'etco2', 'ico2'];
    case 'sf_ratio/rox':
      return ['sf_ratio', 'rox'];
    default:
      return [param];
  }
};

export const getTitleArr = (param: TrendKey): string[] => {
  switch (param) {
    case 'nibp':
      return ['NIBP'];
    case 'ibp1':
      return ['IBP①'];
    case 'ibp2':
      return ['IBP②'];
    default: {
      const individualParam = getCustomTrendParams(param);
      return individualParam.map((item) => PARAMS[item].label);
    }
  }
};

export const getTitleColorArr = (
  param: TrendKey,
  nibpColor: string,
  findParamColor: (param: TLayoutProperty) => string,
): string[] => {
  if (param === 'nibp') return [nibpColor];
  const individualParam = getCustomTrendParams(param);
  return individualParam.map((item) => findParamColor({ property: item, type: 'params' }));
};

export const getLineSeries = (
  wasmContext: TSciChart,
  sciChartSurface: SciChartSurface,
  dataSeries: XyDataSeries[],
  params: TrendKey[],
  paramColors: string[],
  hlcSeries: HlcDataSeries,
  nibpColor: string,
  nibpRange: { min: number; max: number },
) => {
  let index = 0;
  const lineSeriesArr: (FastLineRenderableSeries | FastErrorBarsRenderableSeries)[] = [];
  const numberRangeArr: NumberRange[] = [];
  params.forEach((param, i) => {
    const paramColor = param === 'nibp' ? nibpColor : paramColors[index];
    const yAxis = makeYAxis(
      `Y${i}`,
      // title(params[i]),
      '',
      wasmContext,
      params[i],
      paramColor,
      false,
      false,
      param === 'tmp2' || param === 'tmp1',
    );
    sciChartSurface.yAxes.add(yAxis);
    yAxis.axisTitleStyle.rotation = 0;
    let range = { min: Number.MAX_VALUE, max: -Number.MAX_VALUE };

    // Amy: nibp, ibp 의 경우 s/m/d 를 모두 넣어줘야하기 때문
    if (param === 'nibp') {
      const errorBarSeries = new FastErrorBarsRenderableSeries(wasmContext, {
        yAxisId: yAxis.id,
        dataSeries: hlcSeries,
        errorMode: EErrorMode.Both,
        errorDirection: EErrorDirection.Vertical,
        dataPointWidthMode: EDataPointWidthMode.Absolute,
        dataPointWidth: 15,
        strokeThickness: 4,
        stroke: nibpColor,
        pointMarker: new EllipsePointMarker(wasmContext, {
          width: 12,
          height: 3,
          strokeThickness: 0,
          fill: nibpColor,
        }),
      });
      range = nibpRange;
      sciChartSurface.renderableSeries.add(errorBarSeries);
      lineSeriesArr.push(errorBarSeries);
    } else {
      const params: (keyof Data['params'])[] = getCustomTrendParams(param);
      const paramLength = params.length;
      for (let i = 0; i < paramLength; i += 1) {
        const lineSeries = new FastLineRenderableSeries(wasmContext, {
          yAxisId: yAxis.id,
          stroke: paramColors[index],
          strokeThickness: 2,
        });
        lineSeries.dataSeries = dataSeries[index];

        range.min = Math.min(
          dataSeries[index].getWindowedYRange(dataSeries[index].getXRange(), false)?.min || Number.MAX_VALUE,
          range?.min || Number.MAX_VALUE,
        );
        range.max = Math.max(
          dataSeries[index].getWindowedYRange(dataSeries[index].getXRange(), false)?.max || Number.MIN_VALUE,
          range?.max || Number.MIN_VALUE,
        );

        sciChartSurface.renderableSeries.add(lineSeries);
        index += 1;
        lineSeriesArr.push(lineSeries);
      }
    }
    if (
      range &&
      !(
        range.min === Infinity ||
        range.max === -Infinity ||
        range.min === -Infinity ||
        range.max === Infinity ||
        range.min === Number.MAX_VALUE ||
        range.max === Number.MIN_VALUE
      )
    ) {
      let majorTicks: number[] = [];
      const minorTicks: number[] = [];
      const { min, max } = range;

      const adjustNum = getParamRangeAdjust(param);

      const { adjustedMin, adjustedMax } = adjustToNearest(min, max, adjustNum);
      const gap = (adjustedMax - adjustedMin) / 2;
      majorTicks = [adjustedMin, adjustedMin + gap, adjustedMax];

      if (adjustedMin === adjustedMax) {
        majorTicks = [adjustedMin - adjustNum, adjustedMin, adjustedMin + adjustNum];
      }

      // Todo Amy : 어디선가 visible range 가 변해서 임시 막음
      yAxis.visibleRangeChanged.subscribe(() => {
        yAxis.tickProvider = new CustomTickProvider(wasmContext, majorTicks, minorTicks);
        yAxis.visibleRange = new NumberRange(majorTicks.at(0), majorTicks.at(2));
      });
      yAxis.visibleRange = new NumberRange(majorTicks.at(0), majorTicks.at(2));
    }
    numberRangeArr.push(yAxis.visibleRange);
  });

  return { lineSeriesArr, numberRangeArr };
};

// Overview
// A function to filter and convert renderable series for the overview
const customTransformFunction = (
  renderableSeries: IRenderableSeries,
  wasmContext: TSciChart,
  isErrorbarSeries: boolean,
) => {
  // Convert to a different Renderable Series type
  return isErrorbarSeries
    ? new FastErrorBarsRenderableSeries(wasmContext, {
        yAxisId: renderableSeries.yAxisId,
        dataSeries: renderableSeries.dataSeries,
        errorMode: EErrorMode.Both,
        errorDirection: EErrorDirection.Vertical,
        dataPointWidth: 15,
        dataPointWidthMode: EDataPointWidthMode.Absolute,
        strokeThickness: 1,
        stroke: renderableSeries.stroke,
        pointMarker: new EllipsePointMarker(wasmContext, {
          width: 12,
          height: 3,
          strokeThickness: 0,
          fill: renderableSeries.stroke,
        }),
      })
    : new FastLineRenderableSeries(wasmContext, {
        stroke: renderableSeries.stroke,
        dataSeries: renderableSeries.dataSeries,
        yAxisId: renderableSeries.yAxisId,
      });
};

export const alarmYAxis = (wasmContext: TSciChart, isOverview = false) =>
  new NumericAxis(wasmContext, {
    id: 'alarm',
    visibleRange: new NumberRange(-1, 1),
    axisAlignment: isOverview ? EAxisAlignment.Right : EAxisAlignment.Left,
    zoomExtentsToInitialRange: false,
    maxAutoTicks: 3,
    drawMinorGridLines: false,
    axisTitle: 'alarm',
    isVisible: false,
    stackedAxisLength: isOverview ? '18%' : '20',
    autoRange: EAutoRange.Never,
  });

const getColorFromMetadata = (metadata: any) => {
  const pointColorArgb = parseColorToUIntArgb(metadata.pointColor);
  const selectedColorArgb = 0xffffffff;
  const fill = metadata.isSelected ? selectedColorArgb : pointColorArgb;
  return fill;
};

export const pointPaletteProvider: IPointMarkerPaletteProvider = {
  strokePaletteMode: EStrokePaletteMode.SOLID,
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  onAttached(): void {},
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  onDetached(): void {},
  overridePointMarkerArgb(
    xValue: number,
    yValue: number,
    index: number,
    opacity?: number,
    metadata?: IPointMetadata & { label: string },
  ): TPointMarkerArgb {
    const fill = getColorFromMetadata(metadata);
    return { stroke: fill, fill };
  },
};

export const makeAlarmLineSeries = (wasmContext: TSciChart, alarmDataSeries: IDataSeries, isOverview: boolean) =>
  new XyScatterRenderableSeries(wasmContext, {
    dataSeries: alarmDataSeries,
    stroke: appTheme.VividBlue,
    pointMarker: new EllipsePointMarker(wasmContext, {
      width: isOverview ? 10 : 15,
      height: isOverview ? 10 : 15,
      strokeThickness: 0,
    }),
    yAxisId: 'alarm',
  });

export const addOverviewSeries = (
  overviewSurface: SciChartSurface,
  lineSeriesArr: (FastLineRenderableSeries | FastErrorBarsRenderableSeries)[],
  wasmContext: TSciChart,
  params: TrendKey[],
  paramColors: string[],
  numberRangeArr: NumberRange[],
) => {
  params.forEach((param, i) => {
    const yAxis = makeYAxis(`Y${i}`, `Y${i}`, wasmContext, params[i], paramColors[i], true);
    yAxis.visibleRange = numberRangeArr[i];
    overviewSurface.yAxes.add(yAxis);
  });
  lineSeriesArr.forEach((_, i) => {
    overviewSurface.renderableSeries.add(
      customTransformFunction(lineSeriesArr[i], wasmContext, lineSeriesArr[i] instanceof FastErrorBarsRenderableSeries),
    );
  });
};

export const overviewCustomize = (
  colorMode: string,
  overviewSelectionModifier: OverviewRangeSelectionModifier,
  isGripHandle: boolean,
) => {
  const unselectedBg = colorMode === 'light' ? '#e9ebf2' : 'transparent';

  overviewSelectionModifier.rangeSelectionAnnotation.svgString = `<svg width="50" height="50" preserveAspectRatio="none" xmlns="http://www.w3.org/2000/svg">
    <rect width="100%" height="100%" style="fill: gray">
    </rect>
    </svg>`;
  // Customize the unselected area
  overviewSelectionModifier.unselectedsvgString = `<svg width="50" height="50" preserveAspectRatio="none" xmlns="http://www.w3.org/2000/svg">
    <rect width="100%" height="100%" style="fill:${unselectedBg}">
    </rect>
    </svg>`;
  overviewSelectionModifier.rangeSelectionAnnotation.isEditable = !!isGripHandle;
  overviewSelectionModifier.rangeSelectionAnnotation.adornerSvgStringTemplate = (
    x1: number,
    y1: number,
    x2: number,
    y2: number,
  ) => {
    const colorLine = colorMode === 'light' ? 'black' : 'white';
    const gridpHandleFill = COLORS.GripHandleColor;
    const gripHandleHalfWidth = 3;
    const gripHandleHeight = '40%';
    const gripHandleYCoord = '30%';
    const width = x2 - x1;
    const height = y2 - y1;
    const gripHandle = `<rect x="${
      0 - gripHandleHalfWidth
    }" y="${gripHandleYCoord}" width="7" height="${gripHandleHeight}" fill="${gridpHandleFill}" rx="5" stroke="${colorLine}" />
        <rect x="${
          width - gripHandleHalfWidth
        }" y="${gripHandleYCoord}" width="7" height="${gripHandleHeight}" fill="${gridpHandleFill}" rx="5" stroke="${colorLine}" />`;
    return `<svg x="${x1}" y="${y1}" width="${width}px" height="${height}px" viewBox="0 0 ${width} ${height}" overflow="visible" xmlns="http://www.w3.org/2000/svg">
        <line x1="0" y1="0" x2="${width}" y2="0" stroke="${colorLine}" stroke-width="2" />
        <line x1="0" y1="${height}" x2="${width}" y2="${height}" stroke="${colorLine}" stroke-width="2" />
        <line x1="0" y1="0" x2="0" y2="${height}" stroke="${colorLine}" stroke-width="2" />
        <line x1="${width}" y1="0" x2="${width}" y2="${height}" stroke="${colorLine}" stroke-width="2" />
        ${isGripHandle && gripHandle}
        </svg>`;
  };
};

// Amy: annotation 찾을 때 id 로 찾을 수 없어서 color 로 찾는다.
export const MOVE_ANNOTATION_COLOR = '#42f54b';
export const verticalLineAnnotationProps = (surface: SciChartSurface, initValue: number, color: string) => {
  return {
    xAxisId: surface.xAxes.get(0).id,
    yAxisId: surface.yAxes.get(0).id,
    x1: initValue,
    stroke: MOVE_ANNOTATION_COLOR,
    strokeThickness: 3,
    isEditable: false,
    showLabel: true,
    labelPlacement: ELabelPlacement.Top,
    axisLabelFill: color,
  };
};

export const XAxis = (wasmContext: TSciChart, xVisibleRange: NumberRange) =>
  new DateTimeNumericAxis(wasmContext, {
    axisAlignment: EAxisAlignment.Bottom,
    drawMajorGridLines: true,
    drawMajorBands: false,
    visibleRange: xVisibleRange,
    drawMinorGridLines: false,
    labelProvider: new SmartDateLabelProvider({ cursorLabelFormat: ENumericFormat.Date_DDMMHHMM }),
    majorDelta: 1.0 / 24.0,
    autoRange: EAutoRange.Never,
  });

export const FormatLabel = (dataValue: number, hasSecond = false) => {
  const date = new Date(dataValue);
  // if (date.getHours() === 0 && date.getMinutes() === 0) {
  //   return format(date, 'MM-dd');
  // }
  return format(date, hasSecond ? 'yyyy-MM-dd HH:mm:ss' : 'yyyy-MM-dd HH:mm');
};

export const overviewAxisCustom = (overviewAxis: AxisBase2D, min: number, max: number, wasmContext: TSciChart) => {
  const countTick = 4;
  overviewAxis.labelProvider.formatLabel = (dataValue: number) => FormatLabel(dataValue);
  overviewAxis.isVisible = true;
  overviewAxis.axisAlignment = EAxisAlignment.Top;
  overviewAxis.autoTicks = false;
  const delta = (max - min) / (countTick - 1);
  const midValues = new Array(countTick - 2).fill(0).map((_, index) => min + delta * (index + 1));
  const majorTicks = [min, ...midValues, max];
  const minorTicks: number[] = [];
  overviewAxis.tickProvider = new CustomTickProvider(wasmContext, majorTicks, minorTicks);
  // Amy: 어떤 이유에서 인지 데이터가 하나일 떄 min max 가 달라져서 임의로 설정 (앞 뒤로 history 가 잘려서 조금 더 보여준다.)
  // overviewAxis.visibleRangeLimit = new NumberRange(min - 10000, max + 10000);
};

// Amy: Alarm 넣는 부분
export const makeAlarmColor = (levels: AlarmLevel[]) => {
  const priorityMap: { [key in AlarmLevel]: number } = {
    HIGH: 4,
    MEDIUM: 3,
    LOW: 2,
    INFO: 1,
    unknown: 0,
    PHYSICAL: 0,
    MECHANICAL: 0,
  };

  let highest: AlarmLevel = 'unknown';

  levels.forEach((level) => {
    if (priorityMap[level] > priorityMap[highest]) {
      highest = level;
    }
  });

  return alarmColors[highest].bgColor;
};

interface GroupedAlarms {
  alarm_date_time: string;
  alarms: ResAlarmHistoryV3['data'];
}

const formatDateTimeToMinute = (date: Date): string => {
  const year = date.getUTCFullYear();
  const month = String(date.getUTCMonth() + 1).padStart(2, '0');
  const day = String(date.getUTCDate()).padStart(2, '0');
  const hours = String(date.getUTCHours()).padStart(2, '0');
  const minutes = String(date.getUTCMinutes()).padStart(2, '0');
  return `${year}-${month}-${day}T${hours}:${minutes}:00.000Z`;
};
const groupByMinute = (alarms: ResAlarmHistoryV3['data'], fromTime?: string): GroupedAlarms[] => {
  let firstTimeMin = '';
  const grouped = alarms.reduce<Record<string, ResAlarmHistoryV3['data']>>((acc, alarm, index) => {
    const date = new Date(alarm.alarm_date_time);
    // Amy : 첫 시간의 경우 00 초에 넣을 수 없기 떄문 (wave 일 때만)
    // Amy : 알람
    if (index === 0 && fromTime) {
      firstTimeMin = fromTime;
    }
    const isSameFirstTimeMin = (time: string) => {
      return (
        time &&
        firstTimeMin &&
        formatDateTimeToMinute(new Date(time)) === formatDateTimeToMinute(new Date(firstTimeMin))
      );
    };
    const key = isSameFirstTimeMin(alarm.alarm_date_time) ? firstTimeMin : formatDateTimeToMinute(date); // Extract up to the minute

    if (!acc[key]) {
      acc[key] = [];
    }
    acc[key].push(alarm);
    return acc;
  }, {});

  return Object.keys(grouped).map((key) => ({
    alarm_date_time: key,
    alarms: grouped[key],
  }));
};

const formatDateTimeToSecond = (date: Date): string => {
  const year = date.getUTCFullYear();
  const month = String(date.getUTCMonth() + 1).padStart(2, '0');
  const day = String(date.getUTCDate()).padStart(2, '0');
  const hours = String(date.getUTCHours()).padStart(2, '0');
  const minutes = String(date.getUTCMinutes()).padStart(2, '0');
  const seconds = String(date.getUTCSeconds()).padStart(2, '0');
  return `${year}-${month}-${day}T${hours}:${minutes}:${seconds}.000Z`;
};
export const groupBySeconds = (alarms: ResAlarmHistoryV3['data'], fromTime?: string): GroupedAlarms[] => {
  let firstTimeMin = '';
  const grouped = alarms.reduce<Record<string, ResAlarmHistoryV3['data']>>((acc, alarm, index) => {
    const date = new Date(alarm.alarm_date_time);
    // Amy : 첫 시간의 경우 00 초에 넣을 수 없기 떄문 (wave 일 때만)
    // Amy : 알람
    if (index === 0 && fromTime) {
      firstTimeMin = fromTime;
    }
    const isSameFirstTimeSeconds = (time: string) => {
      return (
        time &&
        firstTimeMin &&
        formatDateTimeToSecond(new Date(time)) === formatDateTimeToSecond(new Date(firstTimeMin))
      );
    };
    const key = isSameFirstTimeSeconds(alarm.alarm_date_time) ? firstTimeMin : formatDateTimeToSecond(date); // Extract up to the minute

    if (!acc[key]) {
      acc[key] = [];
    }
    acc[key].push(alarm);
    return acc;
  }, {});

  return Object.keys(grouped).map((key) => ({
    alarm_date_time: key,
    alarms: grouped[key],
  }));
};

export const FormatLabelToSecond = (dataValue: number) => {
  const date = new Date(dataValue);
  return format(date, 'yyyy-MM-dd HH:mm:ss');
};

// Amy: 이전의 알람을 넣어서 tooltip 까지 띄워주던 버전
export const addAlarmLineSeries = (
  sciChartSurface: SciChartSurface,
  wasmContext: TSciChart,
  alarmData: ResAlarmHistoryV3['data'],
  fromTime = '',
) => {
  const groupedAlarms = groupByMinute(alarmData, fromTime);
  const waveMultiplier = 1;
  const timeStamps = groupedAlarms.map((item) => getFormattedTimeValue(item.alarm_date_time) * waveMultiplier);

  const alarmDataSeries = new XyDataSeries(wasmContext, {
    xValues: timeStamps,
    yValues: new Array(timeStamps.length).fill(0),
    metadata: timeStamps.map((_, index) => ({
      label: groupedAlarms[index].alarms.map((item) => item.alarm_msg),
      pointColor: makeAlarmColor(groupedAlarms[index].alarms.map((item) => item.alarm_level as AlarmLevel)),
      isSelected: false,
      times: groupedAlarms[index].alarms.map((item) => item.alarm_date_time),
      clearedTimes: groupedAlarms[index].alarms.map((item) => item.cleared_date_time),
    })),
  });
  const yAxis = alarmYAxis(wasmContext);
  sciChartSurface.yAxes.add(yAxis);

  const alarmLineSeries = makeAlarmLineSeries(wasmContext, alarmDataSeries, false);
  alarmLineSeries.paletteProvider = pointPaletteProvider;

  alarmLineSeries.rolloverModifierProps.markerColor = appTheme.DarkIndigo;
  alarmLineSeries.rolloverModifierProps.tooltipColor = appTheme.Background;
  alarmLineSeries.rolloverModifierProps.tooltipDataTemplate = (seriesInfo: XySeriesInfo): string[] => {
    const valuesWithLabels: string[] = [];
    // Line Series
    if (seriesInfo.pointMetadata) {
      const { label, times, clearedTimes } = seriesInfo.pointMetadata as {
        label: string[];
        times: string[];
        clearedTimes: (string | null)[];
      };

      label.forEach((item, index) => {
        const clearedTime = clearedTimes[index];
        valuesWithLabels.push(
          `${FormatLabelToSecond(getFormattedTimeValue(times[index]))} : ${item} ${
            clearedTime ? `(Cleared at ${FormatLabelToSecond(getFormattedTimeValue(clearedTime))})` : ''
          }`,
        );
      });

      // valuesWithLabels.push(`Metadata Selected: ${seriesInfo.pointMetadata.isSelected}`);
    }
    return valuesWithLabels;
  };
  sciChartSurface.renderableSeries.add(alarmLineSeries);

  return { alarmLineSeries };
};

export const addAlarmAxis = (sciChartSurface: SciChartSurface, wasmContext: TSciChart) => {
  const yAxis = alarmYAxis(wasmContext);
  sciChartSurface.yAxes.add(yAxis);
};

// Amy: 초단위로 alarm 띄워주기
export const addAlarmAnnotation = async (
  sciChartSurface: SciChartSurface,
  alarmData: ResAlarmHistoryV3['data'],
  fromTime = '',
  isOverview = false,
  lineTime?: TLineTimeValue,
) => {
  // Amy: alarm 을 분별로 그룹하고, 최대 레벨의 색상
  const groupedAlarmsColor = groupBySeconds(alarmData, fromTime).map((item) => {
    return {
      time: item.alarm_date_time,
      color: makeAlarmColor(item.alarms.map((alarm) => alarm.alarm_level as AlarmLevel)),
    };
  });

  const verticalLineAnnotations = groupedAlarmsColor.map(({ time, color }) => {
    const alarmAnnotation = new VerticalLineAnnotation({
      xAxisId: sciChartSurface.xAxes.get(0).id,
      yAxisId: sciChartSurface.yAxes.get(0).id,
      x1: getFormattedTimeValue(time),
      stroke: makeTransparent(
        new Date(time).getTime() === (lineTime?.time || '') ? lineTime?.color || color : color,
        0.5,
      ),
      strokeThickness: isOverview ? 3 : 3,
      isEditable: false,
      showLabel: false,
      labelPlacement: ELabelPlacement.Top,
      strokeDashArray: [5, 5],
    });
    sciChartSurface.annotations.add(alarmAnnotation);
    return alarmAnnotation;
  });

  // 종 모양 SVG → `data:image/svg+xml`로 변환
  const bellSvg = (color: string) => `
<svg xmlns="http://www.w3.org/2000/svg" width="15" height="15" viewBox="0 0 24 24">
  <path style="fill:${color};" 
    d="M12 2C13.1 2 14 2.9 14 4V5.09C17.39 5.56 20 8.47 20 12V17L22 19V20H2V19L4 17V12C4 8.47 6.61 5.56 10 5.09V4C10 2.9 10.9 2 12 2ZM10 21H14V23H10V21Z"/>
</svg>`;

  // Base64로 변환 (이미지로 사용하기 위해)
  const bellImageSrc = (color: string) => `data:image/svg+xml;base64,${btoa(bellSvg(color))}`;

  let customAnnotations: AxisMarkerAnnotation[] = [];
  if (!isOverview)
    customAnnotations = groupedAlarmsColor.map(({ time, color }) => {
      const bellImage = new Image();

      bellImage.src = bellImageSrc(
        new Date(time).getTime() === (lineTime?.time || '') ? lineTime?.color || color : color,
      );
      const alarmAnnotation = new AxisMarkerAnnotation({
        x1: getFormattedTimeValue(time),
        y1: 0,
        image: bellImage,
        xAxisId: 'topXAxis',
        // verticalAnchorPoint: EVerticalAnchorPoint.Center,
        // horizontalAnchorPoint: EHorizontalAnchorPoint.Center,
        // xCoordinateMode: ECoordinateMode.DataValue, // 데이터 기준 X축 위치 지정
        // yCoordinateMode: ECoordinateMode.Relative, // 상대적 위치 (위에 고정)
        // annotationLayer: EAnnotationLayer.AboveChart,
        // svgString:
        //   `<svg id="Capa_1" xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24">` + // ✅ 크기 조정
        //   `<g transform="translate(0,0)">` +
        //   `<path style="fill:${
        //     new Date(time).getTime() === (lineTime?.time || '') ? lineTime?.color || color : color
        //   };fill-opacity:1;stroke:none;cursor:pointer;"` +
        //   ` d="M12 2C13.1 2 14 2.9 14 4V5.09C17.39 5.56 20 8.47 20 12V17L22 19V20H2V19L4 17V12C4 8.47 6.61 5.56 10 5.09V4C10 2.9 10.9 2 12 2ZM10 21H14V23H10V21Z"/>` +
        //   `</g>` +
        //   `</svg>`,
        // image: htmlImageElement,
      });
      sciChartSurface.annotations.add(alarmAnnotation); // 알람 추가
      return alarmAnnotation;
    });
  return { verticalLineAnnotations, customAnnotations };
};

// Amy: hover 지점 찾아서 tooltip 띄워주는 예제 코드
// const containsAlarmTime = (arr: ResAlarmHistoryV3['data'], x: number) => {
//   console.log(x);
//   console.log(getFormattedTimeValue(arr[0].alarm_date_time));
//   return arr.some(
//     (item) =>
//       getFormattedTimeValue(item.alarm_date_time) + 10 > x && getFormattedTimeValue(item.alarm_date_time) - 10 < x,
//   );
// };

// console.log(alarmDataRef.current);

// alarmDataRef.current?.forEach((data) => {
//   const customAnnotation = new CustomAnnotation({
//     x1: getFormattedTimeValue(data.alarm_date_time),
//     y1: 0.3,
//     yCoordinateMode: ECoordinateMode.Relative,
//     verticalAnchorPoint: EVerticalAnchorPoint.Bottom,
//     horizontalAnchorPoint: EHorizontalAnchorPoint.Center,
//     onClick: () => {
//       console.log('here');
//     },
//     svgString:
//       '<svg id="Capa_1" xmlns="http://www.w3.org/2000/svg">' +
//       '  <g' +
//       '     inkscape:label="Layer 1"' +
//       '     inkscape:groupmode="layer"' +
//       '     id="layer1"' +
//       '     transform="translate(-55.430212,-77.263552)">' +
//       '    <rect' +
//       '       style="fill:#C0D4EE;fill-opacity:1;stroke:#333333;stroke-width:0.26458332;stroke-miterlimit:4;stroke-dasharray:none;stroke-opacity:0.66666667"' +
//       '       id="rect4528"' +
//       '       width="13.229166"' +
//       '       height="15.875"' +
//       '       x="55.562504"' +
//       '       y="77.395844"' +
//       '       rx="2"' +
//       '       ry="2" />' +
//       '    <text' +
//       '       xml:space="preserve"' +
//       '       style="font-style:normal;font-weight:normal;font-size:10.58333302px;line-height:1.25;font-family:sans-serif;letter-spacing:0px;word-spacing:0px;fill:#333333;fill-opacity:1;stroke:none;stroke-width:0.26458332"' +
//       '       x="57.688622"' +
//       '       y="89.160347"' +
//       '       id="text4540"><tspan' +
//       '         sodipodi:role="line"' +
//       '         id="tspan4538"' +
//       '         x="57.688622"' +
//       '         y="89.160347"' +
//       '         style="font-style:normal;font-variant:normal;font-weight:bold;font-stretch:normal;font-family:sans-serif;-inkscape-font-specification:\'sans-serif Bold\';fill:#333333;fill-opacity:1;stroke-width:0.26458332">N</tspan></text>' +
//       '  </g>' +
//       '</svg>',
//   });
//   sciChartSurface.annotations.add(customAnnotation);
// });

// const tooltipSvgTemplate = (seriesInfos: any, svgAnnotation: any) => {
//   const width = 120;
//   const height = 120;
//   const seriesInfo = seriesInfos[0];
//   if (!seriesInfo?.isWithinDataBounds) {
//     return '<svg></svg>';
//   }
//   const x = seriesInfo ? seriesInfo.formattedXValue : '';
//   const y = seriesInfo ? seriesInfo.formattedYValue : '';

//   // this calculates and sets svgAnnotation.xCoordShift and svgAnnotation.yCoordShift.  Do not set x1 or y1 at this point.
//   adjustTooltipPosition(width, height, svgAnnotation);

//   return `<svg width="${width}" height="${height}">
//          <circle cx="50%" cy="50%" r="50%" fill="green"/>
//          <svg width="100%">
//              <text y="40" font-size="13" font-family="Verdana" dy="0" fill="white">
//                  <tspan x="50%" text-anchor="middle" dy="1.2em">Some Title</tspan>
//                  <tspan x="50%" text-anchor="middle" dy="1.2em">x: ${x} y: ${y}</tspan>
//              </text>
//          </svg>
//      </svg>`;
// };

// const cursorModifier = new CursorModifier({
//   showTooltip: true,
//   showAxisLabels: true,
//   showYLine: false,
//   tooltipSvgTemplate,
//   hitTestRadius: 1.0,
// });

// sciChartSurface.domCanvas2D.addEventListener('mousemove', (mouseEvent) => {
//   const premultipliedX = mouseEvent.offsetX * DpiHelper.PIXEL_RATIO;
//   const premultipliedY = mouseEvent.offsetY * DpiHelper.PIXEL_RATIO;

//   const HIT_TEST_RADIUS = 10 * DpiHelper.PIXEL_RATIO;

//   const hitTestInfo = lineSeriesArr[0].hitTestProvider.hitTestDataPoint(
//     premultipliedX,
//     premultipliedY,
//     HIT_TEST_RADIUS,
//   );
//   const x = hitTestInfo.hitTestPointValues?.x;
//   // sciChartSurface.chartModifiers.add(cursorModifier);
//   if (alarmDataRef.current) {
//     if (containsAlarmTime(alarmDataRef.current, x)) {
//       console.log('here');
//       if (!sciChartSurface.chartModifiers.get(0)) sciChartSurface.chartModifiers.add(cursorModifier);
//     } else {
//       sciChartSurface.chartModifiers.removeAt(0);
//     }
//   }
// });
