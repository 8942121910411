import { PropsWithChildren, useEffect, useMemo } from 'react';

import { Box, Flex, HStack, Icon, Text } from '@chakra-ui/react';
import { addMinutes, format } from 'date-fns';

import { chartConfig, customChartConfig } from '@/components/charts/template/rechart/chartConfig';
import IbpChart from '@/components/charts/template/rechart/IbpChart';
import MultiChart from '@/components/charts/template/rechart/MultiChart';
import NibpChart from '@/components/charts/template/rechart/NibpChart';
import SingleChart from '@/components/charts/template/rechart/SingleChart';
import type {
  BpSingleData,
  ChartData,
  MultiChartSingleData,
  SingleChartSingleData,
  FormatConfig,
} from '@/components/charts/template/rechart/type';
import { PARAMS } from '@/constants/data';
import { useA4Page } from '@/hooks/usePdf';
import { useTrendData } from '@/hooks/useTrendData';
import {
  CUSTOM_TREND_KEYS,
  type CustomTrendKeys,
} from '@/pages/CentralMain/organisms/TrendModal/utils/trend-table-formatter';
import { getCustomTrendParams } from '@/pages/CentralMain/organisms/TrendModalTemp/organisms/SciTrend/util/trendConfig';
import { TrendKey, type ParamKeys } from '@/pages/CentralMain/types';
import { useTrendReportStoreContext } from '@/pages/ReportProcessor/TrendReport/useTrendReportStore';
import { isInPeriod, parseUTC0String } from '@/utils/dateTimeUtils';

import { CHART_WIDTH_IN_MM } from './constant';

const ChartWrapper = ({
  children,
  label,
  isLastChart = false,
  dataKey,
  xDomain,
}: PropsWithChildren<{
  label: string;
  xDomain: number[];
  isLastChart?: boolean;
  dataKey: string;
  // isFirstChart?: boolean;
}>) => {
  const { convertMmToPx } = useA4Page({});
  const dataKeyLength = dataKey.split('/').length;
  return (
    <Box fontSize={`${convertMmToPx(2.5)}px`}>
      {/* title */}
      <HStack color="#000000">
        <Text fontWeight={500} ml={`${convertMmToPx(1)}px`}>
          {label}
        </Text>
        {dataKeyLength > 1 && (
          <HStack fontWeight={500}>
            <Text>(</Text>
            <Icon w={`${convertMmToPx(7)}px`} viewBox="0 0 300 100">
              <line x1="0" y1="50" x2="300" y2="50" strokeWidth="6" stroke="currentColor" />
            </Icon>
            <Text>/</Text>
            <Icon w={`${convertMmToPx(7)}px`} viewBox="0 0 300 100">
              <line x1="0" y1="50" x2="300" y2="50" strokeWidth="12" stroke="currentColor" strokeDasharray="60" />
            </Icon>
            {dataKeyLength > 2 && (
              <>
                <Text>/</Text>
                <Icon w={`${convertMmToPx(7)}px`} viewBox="0 0 300 100">
                  <line x1="0" y1="50" x2="300" y2="50" strokeWidth="12" stroke="currentColor" strokeDasharray="20" />
                </Icon>
              </>
            )}
            <Text>)</Text>
          </HStack>
        )}
      </HStack>
      {/* Chart */}
      <Flex w="full" justify="flex-end" align="center" mb={isLastChart ? 0 : `${convertMmToPx(0.5)}px`}>
        {children}
      </Flex>
      {isLastChart && (
        <HStack
          w={convertMmToPx(CHART_WIDTH_IN_MM - 7)}
          justify="space-between"
          ml={convertMmToPx(3)}
          mt={-convertMmToPx(0.3)}
        >
          <Box>{format(xDomain[0], 'yyyy-MM-dd HH:mm')}</Box>
          <Box>{format((xDomain[0] + xDomain[1]) / 2, 'yyyy-MM-dd HH:mm')}</Box>
          <Box>{format(xDomain[1], 'yyyy-MM-dd HH:mm')}</Box>
        </HStack>
      )}
    </Box>
  );
};

const isMultiParamKey = (key: string): boolean => {
  const paramKeys = getCustomTrendParams(key as TrendKey);
  if (paramKeys.length > 1) {
    return true;
  }
  return false;
};
const getYDomain = (
  data: ChartData[string],
  domain: { min: number; max: number },
  type: 'multiChart' | 'nibpChart' | 'singleChart',
): { min: number; max: number } => {
  if (!data) {
    return domain;
  }
  if (type === 'multiChart') {
    const min = Math.min(
      ...(data
        .map((e) => [e.y1, e.y2, e.y3])
        .flat()
        .filter((e) => !Number.isNaN(e)) as number[]),
      domain.min,
    );
    const max = Math.max(
      ...(data
        .map((e) => [e.y1, e.y2, e.y3])
        .flat()
        .filter((e) => !Number.isNaN(e)) as number[]),
      domain.max,
    );
    return { min, max };
  }
  if (type === 'nibpChart') {
    const min = Math.min(
      ...(data
        .map((e) => e.smd)
        .flat()
        .filter((e) => !Number.isNaN(e)) as number[]),
      domain.min,
    );
    const max = Math.max(
      ...(data
        .map((e) => e.smd)
        .flat()
        .filter((e) => !Number.isNaN(e)) as number[]),
      domain.max,
    );
    return { min, max };
  }
  const min = Math.min(...(data.map((e) => e.y).filter((e) => !Number.isNaN(e)) as number[]), domain.min);
  const max = Math.max(...(data.map((e) => e.y).filter((e) => !Number.isNaN(e)) as number[]), domain.max);

  return { min, max };
};

interface Props {
  id: string;
  from: Date;
  to: Date;
  targetParamKeys: string[];
  page?: number;
}
const DynamicTrend = ({ id, from, to, targetParamKeys, page = 1 }: Props) => {
  const { convertMmToPx } = useA4Page({});

  const { deviceData } = useTrendData(id, from, addMinutes(to, 1));
  const formattedData: ChartData = useMemo(() => {
    if (!deviceData) {
      return {};
    }
    const getTargetData = (): Array<any> => {
      return deviceData || [];
    };
    const makeNumber = (data: string | number | null) => (data === null ? null : Number(data));

    const parseDataSet = (
      // eslint-disable-next-line @typescript-eslint/no-unused-vars, camelcase
      objs?: ({ [key in string]: string | number | null } & { date_time: string })[],
    ): ChartData => {
      if (!objs || objs.length === 0) {
        return {};
      }
      const dataMap: ChartData = {};

      const keys = targetParamKeys;
      keys.forEach((key) => {
        dataMap[key] = [];
      });

      objs.forEach((obj) => {
        const x = parseUTC0String(obj.date_time).setSeconds(0, 0);
        if (isInPeriod(x, { from, to })) {
          keys.forEach((key) => {
            if (key === 'nibp') {
              const bpData: BpSingleData = {
                dt: x,
                smd: [makeNumber(obj[`${key}_s`]), makeNumber(obj[`${key}_m`]), makeNumber(obj[`${key}_d`])],
              };
              dataMap[key]?.push(bpData);
            } else if (isMultiParamKey(key)) {
              const [y1, y2, y3] = getCustomTrendParams(key as TrendKey);
              const multiParamData: MultiChartSingleData = {
                x,
                y1: makeNumber(obj[y1]),
                y2: makeNumber(obj[y2]),
                y3: makeNumber(obj[y3]),
              };
              dataMap[key]?.push(multiParamData);
            } else {
              const y = makeNumber(obj[key]);
              dataMap[key]?.push({ x, y });
            }
          });
        }
      });

      return dataMap;
    };

    return parseDataSet(getTargetData());
  }, [deviceData, from, targetParamKeys, to]);

  const xDomain = useMemo(() => {
    const dataFrom = from;
    const dataTo = addMinutes(dataFrom, 15);
    return [dataFrom.getTime(), dataTo.getTime()];
  }, [from]);

  const updateIsDataProcessed = useTrendReportStoreContext((state) => state.updateIsDataProcessed);
  const processedTrigger = useMemo(() => {
    return deviceData.length > 0 && Object.values(formattedData).every((value) => (value?.length || 0) > 0);
  }, [deviceData, formattedData]);
  useEffect(() => {
    let timer: NodeJS.Timeout;
    if (processedTrigger === true) {
      timer = setTimeout(() => {
        updateIsDataProcessed(page - 1);
      }, 0);
    }
    return () => {
      clearTimeout(timer);
    };
  }, [page, processedTrigger, updateIsDataProcessed]);

  return (
    <>
      <Box pos="relative" w="100%" px={`${convertMmToPx(10)}px`} h={`${convertMmToPx(125)}px`}>
        {targetParamKeys.map((dataKey, index) => {
          let formatConfig: FormatConfig | undefined;
          if (CUSTOM_TREND_KEYS.includes(dataKey as CustomTrendKeys)) {
            formatConfig = customChartConfig[dataKey as CustomTrendKeys];
          } else {
            formatConfig = chartConfig[dataKey as ParamKeys];
          }
          if (!formatConfig) {
            const paramConfig = PARAMS[dataKey as ParamKeys];
            if (!paramConfig) {
              formatConfig = {
                label: '',
                domain: { min: 0, max: 100 },
              };
            } else {
              const min = Number(paramConfig.valueDomain.min);
              const max = Number(paramConfig.valueDomain.max);

              formatConfig = {
                label: paramConfig.label,
                domain: { min, max },
              };
            }
          }

          const { label, domain } = formatConfig;

          const data = formattedData[dataKey] || [];

          if (dataKey === 'nibp') {
            const yDomain = getYDomain(data, domain, 'nibpChart');
            return (
              <ChartWrapper
                key={dataKey}
                label={label}
                isLastChart={index === targetParamKeys.length - 1}
                // isFirstChart={index === 0}
                dataKey={dataKey}
                xDomain={xDomain}
              >
                <NibpChart
                  data={data as unknown as BpSingleData[]}
                  xDomain={xDomain}
                  yDomain={[yDomain.min, yDomain.max]}
                  width={convertMmToPx(CHART_WIDTH_IN_MM)}
                  height={convertMmToPx(20)}
                />
              </ChartWrapper>
            );
          }

          if (dataKey.includes('ibp')) {
            const yDomain = getYDomain(data, domain, 'multiChart');
            return (
              <ChartWrapper
                key={dataKey}
                label={label}
                isLastChart={index === targetParamKeys.length - 1}
                // isFirstChart={index === 0}
                dataKey={dataKey}
                xDomain={xDomain}
              >
                <IbpChart
                  data={data as unknown as MultiChartSingleData[]}
                  xDomain={xDomain}
                  yDomain={[yDomain.min, yDomain.max]}
                  width={convertMmToPx(CHART_WIDTH_IN_MM)}
                  height={convertMmToPx(20)}
                />
              </ChartWrapper>
            );
          }

          if (isMultiParamKey(dataKey)) {
            const yDomain = getYDomain(data, domain, 'multiChart');
            return (
              <ChartWrapper
                key={dataKey}
                label={label}
                isLastChart={index === targetParamKeys.length - 1}
                // isFirstChart={index === 0}
                dataKey={dataKey}
                xDomain={xDomain}
              >
                <MultiChart
                  data={data as unknown as MultiChartSingleData[]}
                  xDomain={xDomain}
                  yDomain={[yDomain.min, yDomain.max]}
                  width={convertMmToPx(CHART_WIDTH_IN_MM)}
                  height={convertMmToPx(20)}
                />
              </ChartWrapper>
            );
          }

          const yDomain = getYDomain(data, domain, 'singleChart');
          return (
            <ChartWrapper
              key={dataKey}
              label={label}
              isLastChart={index === targetParamKeys.length - 1}
              // isFirstChart={index === 0}
              dataKey={dataKey}
              xDomain={xDomain}
            >
              <SingleChart
                data={data as unknown as SingleChartSingleData[]}
                xDomain={xDomain}
                yDomain={[yDomain.min, yDomain.max]}
                width={convertMmToPx(CHART_WIDTH_IN_MM)}
                height={convertMmToPx(20)}
              />
            </ChartWrapper>
          );
        })}
      </Box>
    </>
  );
};

export default DynamicTrend;
