import { type CustomTrendKeys } from '@/pages/CentralMain/organisms/TrendModal/utils/trend-table-formatter';
import { type ParamKeys } from '@/pages/CentralMain/types';

import { FormatConfig } from './type';

// config
export const chartConfig: {
  [key in ParamKeys]?: FormatConfig;
} = {
  hr: { label: 'HR', domain: { min: 0, max: 300 } },
  spo2: { label: 'SpO₂', domain: { min: 0, max: 100 } },
  rr: { label: 'RR', domain: { min: 0, max: 150 } },
  tmp1: { label: 'TEMP', domain: { min: 0, max: 50 } },
};
export const customChartConfig: {
  [key in CustomTrendKeys]: FormatConfig;
} = {
  ibp1: { label: 'IBP①', domain: { min: 0, max: 300 } },
  ibp2: { label: 'IBP②', domain: { min: 0, max: 300 } },
  nibp: { label: 'NIBP', domain: { min: 0, max: 300 } },
  tmp: { label: 'TMP', domain: { min: 0, max: 40 } },
  ve_min: { label: 'VE min', domain: { min: 0, max: 300 } },
  st: { label: 'ST', domain: { min: 0, max: 300 } },
  'flow/fi_peak/fe_peak': {
    label: 'Flow / Fi-peak / Fe-peak',
    domain: { min: -276, max: 276 },
  },
  'ppause/pmean/peep': {
    label: 'Ppause / Pmean / PEEP',
    domain: { min: 0, max: 39 },
  },
  'ti/te/tc': {
    label: 'TI / TE / TC',
    domain: { min: 0, max: 7.8 },
  },
  'fio2/spo2': {
    label: 'FiO₂ / SpO₂',
    domain: { min: 0, max: 100 },
  },
  'p_0.1/auto_peep': {
    label: 'P 0.1 / AutoPEEP',
    domain: { min: 0, max: 100 },
  },
  'pi/etco2/ico2': {
    label: 'PI / EtCO₂ / iCO₂',
    domain: { min: 0, max: 100 },
  },
  'rr/rr_spont/etco2_rr': {
    label: 'RR / RR spont / EtCO₂ RR',
    domain: { min: 0, max: 17 },
  },
  'sf_ratio/rox': {
    label: 'S/F Ratio / ROX',
    domain: { min: 0, max: 500 },
  },
  'vico2/veco2': {
    label: 'ViCO₂ / VeCO₂',
    domain: { min: 0, max: 750 },
  },
  'vme/vme_s': {
    label: 'VMe / VMe S.',
    domain: { min: 0, max: 363 },
  },
  'vti/vte': {
    label: 'VTi / VTe',
    domain: { min: 0, max: 3000 },
  },
};
