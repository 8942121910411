export const TREND_DATA = {
  spo2: [
    98, 97, 97, 97, 97, 97, 97, 97, 97, 97, 97, 96, 96, 96, 96, 96, 96, 96, 96, 95, 95, 95, 94, 94, 94, 93, 93, 93, 93,
    93, 92, 92, 92, 91, 92, 92, 93, 93, 92, 92, 92, 92, 92, 93, 93, 93, 93, 93, 94, 94, 95, 95, 95, 96, 96, 96, 96, 96,
    96, 96, 97, 97, 97, 97, 97, 97, 97, 97, 97, 98,
  ],
  estimated_tidal_volume: [
    489.0, 487.0, 483.0, 498.0, 484.0, 492.0, 467.0, 502.0, 473.0, 486.0, 485.0, 480.0, 454.0, 433.0, 429.0, 425.0,
    441.0, 432.0, 440.0, 423.0, 402.0, 408.0, 374.0, 368.0, 340.0, 324.0, 303.0, 314.0, 300.0, 319.0, 316.0, 301.0,
    310.0, 315.0, 320.0, 311.0, 300.0, 305.0, 278.0, 291.0, 272.0, 270.0, 276.0, 288.0, 319.0, 321.0, 327.0, 315.0,
    364.0, 379.0, 406.0, 394.0, 403.0, 449.0, 440.0, 432.0, 435.0, 463.0, 447.0, 469.0, 477.0, 501.0, 496.0, 467.0,
    487.0, 484.0, 498.0, 482.0, 509.0, 488.0,
  ],
  v_delivery: [
    994.0, 1159.0, 1051.0, 1089.0, 1178.0, 1131.0, 1194.0, 1171.0, 1122.0, 1027.0, 1227.0, 1208.0, 1345.0, 1346.0,
    1489.0, 1484.0, 1444.0, 1450.0, 1460.0, 1587.0, 1678.0, 1670.0, 1816.0, 1900.0, 1952.0, 2127.0, 2223.0, 2126.0,
    2210.0, 2174.0, 2226.0, 2287.0, 2389.0, 2419.0, 2428.0, 2383.0, 2190.0, 2182.0, 2426.0, 2456.0, 2400.0, 2444.0,
    2230.0, 2190.0, 2168.0, 2174.0, 2159.0, 2152.0, 1885.0, 1811.0, 1701.0, 1735.0, 1520.0, 1448.0, 1465.0, 1437.0,
    1418.0, 1336.0, 1380.0, 1252.0, 1214.0, 1098.0, 1155.0, 1169.0, 1127.0, 1186.0, 1107.0, 1056.0, 1099.0, 1014.0,
  ],
  rr: [
    12, 13, 12, 12, 12, 12, 12, 12, 13, 13, 14, 14, 15, 16, 16, 16, 17, 17, 18, 17, 17, 17, 18, 18, 18, 18, 18, 17, 17,
    18, 18, 19, 18, 19, 19, 18, 18, 17, 18, 19, 19, 19, 20, 19, 19, 19, 18, 18, 18, 17, 17, 17, 17, 16, 16, 16, 16, 16,
    16, 16, 16, 15, 14, 14, 14, 14, 13, 12, 12, 12,
  ],
  pr: [
    81, 81, 80, 80, 79, 78, 77, 77, 77, 76, 74, 72, 71, 69, 68, 69, 70, 69, 66, 63, 62, 60, 60, 60, 60, 61, 62, 61, 61,
    62, 62, 61, 61, 61, 60, 60, 61, 61, 62, 62, 62, 61, 62, 63, 63, 62, 63, 63, 64, 64, 65, 63, 66, 67, 69, 68, 69, 71,
    72, 74, 75, 75, 76, 77, 78, 79, 81, 81, 81, 81,
  ],
  pip: [
    19.8, 19.4, 19.6, 19.5, 19.4, 19.3, 19.3, 19.3, 19.4, 19.6, 18.9, 18.8, 18.5, 18.4, 18.1, 18.0, 18.1, 18.2, 18.1,
    17.7, 17.2, 17.2, 16.8, 16.4, 16.2, 15.7, 15.4, 15.7, 15.3, 15.6, 15.3, 15.1, 14.6, 14.5, 14.6, 14.6, 15.3, 15.3,
    14.6, 14.6, 14.5, 14.6, 15.1, 15.3, 15.6, 15.3, 15.7, 15.4, 16.4, 16.8, 17.2, 17.2, 17.7, 18.2, 18.1, 18.0, 18.1,
    18.4, 18.5, 18.8, 18.9, 19.6, 19.4, 19.3, 19.3, 19.3, 19.4, 19.5, 19.6, 19.8,
  ],
  pi: [
    0.5, 2.17, 1.11, 1.77, 1.8, 2.25, 2.48, 2.0, 2.14, 0.78, 3.24, 3.47, 5.17, 4.67, 6.7, 6.76, 6.67, 5.88, 6.15, 8.28,
    10.03, 9.26, 10.96, 12.28, 13.89, 15.59, 16.97, 15.61, 17.35, 15.9, 16.89, 17.76, 19.38, 19.85, 19.56, 19.46, 18.99,
    18.86, 19.31, 19.76, 19.85, 19.16, 17.38, 16.94, 16.02, 16.77, 15.64, 17.07, 12.49, 10.96, 9.51, 9.29, 7.72, 5.62,
    5.84, 6.47, 6.66, 5.16, 4.65, 3.93, 4.0, 1.38, 1.57, 2.15, 2.05, 2.34, 2.34, 1.18, 1.18, 0.6,
  ],
  peep: [
    3.26, 6.09, 4.23, 4.98, 3.65, 3.53, 6.26, 4.58, 3.64, 4.73, 6.99, 6.0, 4.78, 5.95, 6.48, 8.88, 8.4, 5.73, 6.77, 7.0,
    6.77, 8.6, 8.14, 10.56, 10.29, 12.71, 11.24, 13.97, 14.48, 14.1, 11.37, 13.65, 14.74, 13.02, 13.39, 14.99, 12.25,
    13.3, 13.38, 14.63, 14.79, 13.25, 11.92, 14.81, 11.15, 14.95, 12.05, 11.51, 8.9, 10.94, 9.19, 7.42, 9.07, 4.96,
    5.64, 5.2, 7.0, 6.5, 6.18, 6.53, 5.23, 3.05, 4.39, 4.58, 4.33, 3.73, 5.86, 4.34, 3.44, 4.73,
  ],
  flow: [
    16.01, 16.22, 12.59, 13.33, 15.21, 15.06, 16.19, 14.62, 14.63, 13.26, 19.21, 19.11, 21.64, 21.7, 24.92, 26.79,
    24.62, 25.15, 24.4, 28.6, 34.73, 33.93, 38.64, 41.33, 43.44, 47.44, 49.91, 47.92, 51.28, 48.75, 51.58, 54.83, 57.69,
    59.79, 58.03, 57.91, 57.36, 58.81, 58.32, 59.54, 60.14, 59.09, 57.14, 56.12, 49.07, 51.27, 49.45, 50.93, 41.16,
    38.04, 37.38, 36.51, 32.58, 24.37, 25.81, 26.25, 25.49, 21.66, 21.42, 18.94, 17.66, 12.43, 13.12, 15.53, 15.13,
    15.27, 14.51, 12.55, 11.36, 11.25,
  ],
  fio2: [
    30, 30.8, 29.5, 28.9, 29.5, 28, 28, 29, 31, 33, 35, 34, 41, 42, 45, 46, 47, 49, 50, 48, 47, 49, 50, 51, 52, 53, 51,
    47, 48, 51, 54, 55, 52, 55, 56, 52, 50, 49, 52, 56, 55, 57, 60, 57, 55, 56, 52, 51, 53, 49, 48, 49, 47, 45, 46, 45,
    43, 44, 45, 43, 42, 39, 37, 36, 38, 35, 32, 29, 28, 30,
  ],
  hr: [
    70, 72, 73, 73, 74, 74, 76, 75, 75, 76, 78, 77, 77, 79, 81, 80, 82, 82, 83, 81, 80, 81, 80, 80, 79, 80, 79, 81, 83,
    82, 82, 84, 85, 87, 86, 85, 86, 85, 86, 87, 88, 86, 87, 88, 88, 89, 88, 87, 87, 85, 83, 82, 80, 81, 80, 78, 77, 75,
    74, 74, 73, 71, 72, 72, 73, 72, 72, 71, 70, 70,
  ],
  tmp1: [
    36, 36.01, 36.08, 36.0, 35.93, 36.01, 36.07, 36.12, 36.23, 36.14, 36.28, 36.4, 36.48, 36.5, 36.67, 36.77, 36.81,
    36.97, 37.14, 37.3, 37.23, 37.4, 37.58, 37.71, 37.85, 37.93, 37.86, 37.76, 37.93, 38.01, 38.13, 38.2, 38.2, 38.15,
    38.32, 38.34, 38.29, 38.18, 38.01, 38.03, 38.03, 37.99, 37.8, 37.84, 37.72, 37.77, 37.76, 37.84, 37.7, 37.68, 37.52,
    37.53, 37.46, 37.28, 37.17, 37.0, 36.97, 37.0, 36.95, 36.79, 36.64, 36.72, 36.56, 36.41, 36.32, 36.39, 36.27, 36.1,
    36.19, 36.2,
  ],
  tmp2: [
    35.8, 35.88, 35.9, 35.98, 35.67, 35.88, 35.96, 36.14, 36.18, 36.12, 36.23, 36.38, 36.3, 36.45, 36.62, 36.65, 36.61,
    36.55, 36.56, 36.67, 36.66, 36.86, 36.99, 37.16, 37.19, 37.27, 37.46, 37.47, 37.56, 37.64, 37.56, 37.49, 37.69,
    37.77, 37.92, 37.82, 37.8, 37.88, 37.84, 37.81, 37.87, 37.83, 37.85, 37.84, 37.88, 37.96, 37.99, 37.81, 37.68,
    37.74, 37.81, 37.85, 37.89, 37.7, 37.51, 37.4, 37.41, 37.42, 37.27, 37.15, 37.18, 37.01, 37.04, 36.86, 36.92, 36.96,
    36.55, 36.0, 36.05, 36.2,
  ],
  ibp1_s: [
    92, 92.0, 94.0, 95.0, 95.0, 97.0, 98.0, 99.0, 99.0, 99.0, 99.0, 100.0, 102.0, 103.0, 104.0, 104.0, 105.0, 106.0,
    106.0, 106.0, 106.0, 106.0, 106.0, 107.0, 109.0, 108.0, 107.0, 108.0, 110.0, 110.0, 109.0, 108.0, 108.0, 110.0,
    112.0, 111.0, 110.0, 109.0, 109.0, 108.0, 109.0, 108.0, 107.0, 107.0, 107.0, 108.0, 106.0, 105.0, 105.0, 105.0,
    106.0, 105.0, 105.0, 106.0, 104.0, 104.0, 103.0, 103.0, 101.0, 102.0, 102.0, 100.0, 100.0, 98.0, 97.0, 96.0, 96.0,
    95.0, 94.0, 94,
  ],
  ibp1_d: [
    60, 61.0, 61.0, 62.0, 63.0, 62.0, 62.0, 62.0, 62.0, 62.0, 63.0, 64.0, 65.0, 65.0, 65.0, 65.0, 66.0, 66.0, 66.0,
    67.0, 68.0, 68.0, 68.0, 69.0, 69.0, 69.0, 70.0, 71.0, 71.0, 72.0, 73.0, 74.0, 74.0, 74.0, 74.0, 73.0, 73.0, 73.0,
    73.0, 73.0, 72.0, 72.0, 72.0, 71.0, 71.0, 72.0, 71.0, 70.0, 70.0, 70.0, 69.0, 69.0, 68.0, 68.0, 68.0, 67.0, 66.0,
    66.0, 66.0, 66.0, 66.0, 65.0, 65.0, 65.0, 64.0, 63.0, 63.0, 62.0, 61.0, 61,
  ],
  ibp1_m: [
    72, 72.0, 73.0, 74.0, 75.0, 75.0, 76.0, 76.0, 77.0, 78.0, 78.0, 78.0, 79.0, 78.0, 78.0, 80.0, 81.0, 82.0, 83.0,
    85.0, 87.0, 87.0, 87.0, 88.0, 90.0, 91.0, 91.0, 91.0, 90.0, 92.0, 92.0, 93.0, 95.0, 97.0, 96.0, 95.0, 96.0, 95.0,
    95.0, 93.0, 93.0, 92.0, 91.0, 90.0, 90.0, 90.0, 89.0, 88.0, 87.0, 86.0, 87.0, 86.0, 86.0, 84.0, 85.0, 83.0, 82.0,
    82.0, 83.0, 82.0, 83.0, 82.0, 80.0, 78.0, 78.0, 77.0, 75.0, 73.0, 73.0, 73,
  ],
  ibp2_s: [
    92, 91.0, 92.0, 92.0, 93.0, 95.0, 97.0, 97.0, 99.0, 100.0, 102.0, 102.0, 103.0, 103.0, 103.0, 102.0, 104.0, 105.0,
    106.0, 107.0, 107.0, 109.0, 111.0, 110.0, 111.0, 110.0, 112.0, 113.0, 113.0, 113.0, 114.0, 116.0, 117.0, 118.0,
    118.0, 119.0, 117.0, 115.0, 115.0, 115.0, 114.0, 113.0, 114.0, 112.0, 110.0, 109.0, 109.0, 107.0, 107.0, 105.0,
    104.0, 102.0, 100.0, 100.0, 98.0, 97.0, 97.0, 96.0, 96.0, 95.0, 94.0, 94.0, 95.0, 94.0, 95.0, 94.0, 95.0, 95.0,
    94.0, 93,
  ],
  ibp2_d: [
    60, 61.0, 62.0, 63.0, 64.0, 65.0, 66.0, 66.0, 66.0, 67.0, 68.0, 67.0, 67.0, 68.0, 68.0, 68.0, 68.0, 68.0, 67.0,
    68.0, 68.0, 69.0, 70.0, 71.0, 70.0, 70.0, 71.0, 71.0, 71.0, 72.0, 72.0, 72.0, 72.0, 71.0, 71.0, 71.0, 71.0, 70.0,
    70.0, 70.0, 70.0, 70.0, 70.0, 70.0, 70.0, 71.0, 72.0, 72.0, 72.0, 71.0, 71.0, 70.0, 70.0, 70.0, 69.0, 68.0, 68.0,
    68.0, 67.0, 66.0, 65.0, 64.0, 64.0, 64.0, 64.0, 63.0, 62.0, 61.0, 61.0, 60,
  ],
  ibp2_m: [
    70, 70.0, 72.0, 73.0, 75.0, 74.0, 75.0, 76.0, 78.0, 78.0, 78.0, 78.0, 78.0, 80.0, 80.0, 79.0, 80.0, 81.0, 81.0,
    83.0, 83.0, 84.0, 84.0, 85.0, 85.0, 84.0, 86.0, 87.0, 86.0, 85.0, 87.0, 88.0, 88.0, 88.0, 88.0, 88.0, 87.0, 85.0,
    86.0, 84.0, 82.0, 82.0, 80.0, 79.0, 79.0, 79.0, 77.0, 77.0, 77.0, 77.0, 75.0, 75.0, 75.0, 75.0, 75.0, 74.0, 74.0,
    73.0, 72.0, 71.0, 71.0, 71.0, 70.0, 70.0, 70.0, 71.0, 72.0, 71.0, 69.0, 69,
  ],
  etco2: [
    4.7, 4.72, 5.07, 5.06, 5.15, 5.34, 5.54, 5.89, 6.27, 6.54, 6.41, 6.27, 6.46, 6.6, 6.51, 6.56, 6.44, 6.76, 7.11,
    7.37, 7.76, 7.71, 7.52, 7.57, 7.77, 8.07, 7.97, 8.12, 8.02, 8.34, 8.64, 8.73, 8.86, 9.13, 9.04, 8.78, 8.72, 8.68,
    8.71, 8.71, 8.69, 8.35, 8.2, 8.1, 8.05, 7.84, 7.71, 7.52, 7.29, 7.3, 7.2, 7.18, 6.85, 6.59, 6.5, 6.47, 6.37, 6.22,
    5.82, 5.97, 6.01, 5.92, 5.76, 5.64, 5.52, 5.28, 5.29, 4.96, 5.0, 5.0,
  ],
  fico2: [
    2.0, 2.15, 2.04, 2.08, 2.11, 2.13, 2.14, 2.12, 2.09, 2.05, 2.26, 2.28, 2.36, 2.39, 2.48, 2.51, 2.48, 2.46, 2.48,
    2.61, 2.73, 2.74, 2.85, 2.97, 3.02, 3.15, 3.23, 3.16, 3.25, 3.18, 3.29, 3.42, 3.46, 3.42, 3.43, 3.18, 3.18, 3.43,
    3.42, 3.46, 3.42, 3.29, 3.18, 3.25, 3.16, 3.15, 3.23, 3.02, 2.97, 2.85, 2.74, 2.73, 2.61, 2.46, 2.48, 2.51, 2.48,
    2.39, 2.36, 2.28, 2.26, 2.05, 2.09, 2.12, 2.14, 2.13, 2.11, 2.08, 2.04, 2.0,
  ],
  ppeak: [
    15.0, 16.12, 15.3, 15.52, 15.7, 15.84, 15.9, 15.78, 15.6, 15.26, 16.86, 16.96, 17.52, 17.7, 18.56, 18.82, 18.54,
    18.36, 18.52, 19.54, 20.58, 20.64, 21.68, 22.72, 23.04, 24.18, 24.76, 24.2, 24.9, 24.32, 25.02, 26.2, 26.44, 26.2,
    26.26, 24.32, 24.32, 26.26, 26.2, 26.44, 26.2, 25.02, 24.32, 24.9, 24.2, 24.18, 24.76, 23.04, 22.72, 21.68, 20.64,
    20.58, 19.54, 18.36, 18.52, 18.82, 18.54, 17.7, 17.52, 16.96, 16.86, 15.26, 15.6, 15.78, 15.9, 15.84, 15.7, 15.52,
    15.3, 15.0,
  ],
  pmean: [
    5.0, 5.94, 5.35, 5.56, 5.75, 5.88, 5.95, 5.86, 5.7, 5.39, 6.49, 6.57, 7.01, 7.15, 7.78, 7.91, 7.77, 7.64, 7.77,
    8.42, 9.08, 9.12, 9.77, 10.43, 10.61, 11.39, 11.78, 11.42, 11.86, 11.49, 11.91, 12.6, 12.72, 12.6, 12.63, 11.49,
    11.49, 12.63, 12.6, 12.72, 12.6, 11.91, 11.49, 11.86, 11.42, 11.39, 11.78, 10.61, 10.43, 9.77, 9.12, 9.08, 8.42,
    7.64, 7.77, 7.91, 7.77, 7.15, 7.01, 6.57, 6.49, 5.39, 5.7, 5.86, 5.95, 5.88, 5.75, 5.56, 5.35, 5.0,
  ],
  ppause: [
    5.0, 5.14, 5.04, 5.08, 5.1, 5.12, 5.13, 5.12, 5.09, 5.05, 5.19, 5.21, 5.27, 5.29, 5.35, 5.38, 5.35, 5.34, 5.35,
    5.45, 5.55, 5.55, 5.64, 5.74, 5.78, 5.89, 5.96, 5.89, 5.98, 5.91, 5.98, 6.02, 6.13, 6.16, 6.13, 6.14, 5.98, 5.98,
    6.14, 6.13, 6.16, 6.13, 6.02, 5.98, 5.91, 5.98, 5.89, 5.96, 5.74, 5.64, 5.55, 5.55, 5.45, 5.34, 5.35, 5.38, 5.35,
    5.29, 5.27, 5.21, 5.19, 5.05, 5.09, 5.12, 5.13, 5.12, 5.1, 5.08, 5.04, 5.0,
  ],
  fi_peak: [
    60.0, 61.4, 60.4, 60.8, 61.0, 61.2, 61.3, 61.2, 60.9, 60.5, 61.9, 62.1, 62.7, 62.9, 63.5, 63.8, 63.5, 63.4, 63.5,
    64.5, 65.5, 65.5, 66.4, 67.4, 67.8, 68.9, 69.6, 68.9, 69.8, 69.1, 69.8, 70.2, 71.3, 71.6, 71.3, 71.4, 69.8, 69.8,
    71.4, 71.3, 71.6, 71.3, 70.2, 69.8, 69.1, 69.8, 68.9, 69.6, 67.4, 66.4, 65.5, 65.5, 64.5, 63.4, 63.5, 63.8, 63.5,
    62.9, 62.7, 62.1, 61.9, 60.5, 60.9, 61.2, 61.3, 61.2, 61.0, 60.8, 60.4, 60.0,
  ],
  fe_peak: [
    50.0, 51.1, 50.3, 50.6, 50.8, 50.9, 51.0, 50.9, 50.7, 50.4, 51.5, 51.5, 52.0, 52.2, 52.7, 52.8, 52.6, 52.5, 52.6,
    53.3, 54.1, 54.2, 54.8, 55.5, 55.8, 56.7, 57.2, 56.7, 57.3, 56.9, 57.3, 57.7, 58.5, 58.7, 58.5, 58.5, 57.3, 57.3,
    58.5, 58.5, 58.7, 58.5, 57.7, 57.3, 56.9, 57.3, 56.7, 57.2, 55.5, 54.8, 54.2, 54.1, 53.3, 52.5, 52.6, 52.8, 52.7,
    52.2, 52.0, 51.5, 51.5, 50.4, 50.7, 50.9, 51.0, 50.9, 50.8, 50.6, 50.3, 50.0,
  ],
  peep_h: [
    12, 11.5164076, 11.86183074, 11.73747841, 11.64075993, 11.57858377, 11.55785838, 11.59240069, 11.67530225,
    11.8134715, 11.3298791, 11.28842832, 11.07426598, 10.99827288, 10.77720207, 10.69430052, 10.78411054, 10.83937824,
    10.791019, 10.45941278, 10.10017271, 10.08635579, 9.78238342, 9.45768566, 9.31951641, 8.93264249, 8.69084629,
    8.92573402, 8.62176166, 8.84283247, 8.62867012, 8.46977547, 8.08290155, 8, 8.08981002, 8.07599309, 8.62867012,
    8.62867012, 8.07599309, 8.08981002, 8, 8.08290155, 8.46977547, 8.62867012, 8.84283247, 8.62176166, 8.92573402,
    8.69084629, 9.45768566, 9.78238342, 10.08635579, 10.10017271, 10.45941278, 10.83937824, 10.78411054, 10.69430052,
    10.77720207, 10.99827288, 11.07426598, 11.28842832, 11.3298791, 11.8134715, 11.67530225, 11.59240069, 11.55785838,
    11.57858377, 11.64075993, 11.73747841, 11.86183074, 12,
  ],
  peep_l: [
    5.0, 4.7582038, 4.93091537, 4.868739205, 4.820379965, 4.789291885, 4.7789291899999995, 4.796200345, 4.837651125,
    4.90673575, 4.66493955, 4.644214160000001, 4.53713299, 4.49913644, 4.388601035000001, 4.347150259999999, 4.39205527,
    4.41968912, 4.3955095, 4.2297063900000005, 4.0500863549999995, 4.0431778949999995, 3.89119171, 3.72884283,
    3.659758205, 3.466321245, 3.345423145, 3.46286701, 3.3108808300000003, 3.4214162349999997, 3.3143350600000003,
    3.234887735, 3.041450775, 3.0, 3.04490501, 3.037996545, 3.3143350600000003, 3.3143350600000003, 3.037996545,
    3.04490501, 3.0, 3.041450775, 3.234887735, 3.3143350600000003, 3.4214162349999997, 3.3108808300000003, 3.46286701,
    3.345423145, 3.72884283, 3.89119171, 4.0431778949999995, 4.0500863549999995, 4.2297063900000005, 4.41968912,
    4.39205527, 4.347150259999999, 4.388601035000001, 4.49913644, 4.53713299, 4.644214160000001, 4.66493955, 4.90673575,
    4.837651125, 4.796200345, 4.7789291899999995, 4.789291885, 4.820379965, 4.868739205, 4.93091537, 5.0,
  ],
  vico2: [
    500.0, 480.9915676470588, 498.7707, 492.37021235294117, 487.39205529411765, 484.19181117647054, 483.1250635294117,
    484.9029764705882, 489.16996823529405, 496.2816211764706, 471.3908358823529, 469.25734, 458.2342782352941,
    454.32286882352935, 442.9442241176471, 438.6772323529412, 443.2998070588235, 446.1444682352941, 443.6553894117647,
    426.58742235294113, 408.0971247058824, 407.3859594117647, 391.7403229411765, 375.0279388235294, 367.91628588235295,
    348.0036576470588, 335.5582647058823, 347.6480747058823, 332.0024382352941, 343.3810829411765, 332.3580211764706,
    324.17962, 304.2669917647059, 300.0, 304.62257470588236, 303.9114094117647, 332.3580211764706, 332.3580211764706,
    303.9114094117647, 304.62257470588236, 300.0, 304.2669917647059, 324.17962, 332.3580211764706, 343.3810829411765,
    332.0024382352941, 347.6480747058823, 335.5582647058823, 375.0279388235294, 391.7403229411765, 407.3859594117647,
    408.0971247058824, 426.58742235294113, 446.1444682352941, 443.2998070588235, 438.6772323529412, 442.9442241176471,
    454.32286882352935, 458.2342782352941, 469.25734, 471.3908358823529, 496.2816211764706, 489.16996823529405,
    484.9029764705882, 483.1250635294117, 484.19181117647054, 487.39205529411765, 492.37021235294117, 498.7707,
    494.11764705882354,
  ],
  veco2: [
    600.0, 575.4385964912279, 592.982456140351, 586.6666666666665, 583.1578947368421, 579.6491228070176,
    577.5438596491229, 578.9473684210525, 581.7543859649124, 588.7719298245614, 565.614035087719, 563.5087719298242,
    552.9824561403508, 549.4736842105262, 537.5438596491225, 534.0350877192981, 538.2456140350877, 541.0526315789471,
    538.9473684210528, 522.1052631578949, 503.1578947368422, 502.4561403508771, 487.71929824561397, 470.8771929824561,
    463.8596491228071, 444.21052631578925, 431.578947368421, 444.21052631578925, 428.77192982456165, 440.0000000000002,
    428.77192982456165, 421.75438596491205, 402.80701754385933, 400.0, 402.1052631578948, 402.80701754385933,
    428.77192982456165, 428.77192982456165, 402.80701754385933, 402.1052631578948, 400.0, 402.80701754385933,
    421.75438596491205, 428.77192982456165, 440.0000000000002, 428.77192982456165, 444.21052631578925, 431.578947368421,
    470.8771929824561, 487.71929824561397, 502.4561403508771, 503.1578947368422, 522.1052631578949, 541.0526315789471,
    538.2456140350877, 534.0350877192981, 537.5438596491225, 549.4736842105262, 552.9824561403508, 563.5087719298242,
    565.614035087719, 588.7719298245614, 581.7543859649124, 578.9473684210525, 577.5438596491229, 579.6491228070176,
    583.1578947368421, 586.6666666666665, 592.982456140351, 600.0,
  ],
  vmeco2: [
    290.0, 272.49999999999994, 282.49999999999994, 275.0000000000001, 277.50000000000006, 275.0000000000001,
    267.50000000000006, 277.50000000000006, 270.0, 275.0000000000001, 260.0, 255.0000000000001, 265.0000000000001,
    252.49999999999994, 245.0000000000001, 260.0, 247.50000000000006, 252.49999999999994, 250.0, 250.0,
    237.50000000000006, 232.49999999999994, 235.0000000000001, 222.50000000000017, 230.0, 220.0, 222.50000000000017,
    217.50000000000006, 222.50000000000017, 217.50000000000006, 207.50000000000006, 202.50000000000017, 210.0,
    202.50000000000017, 207.50000000000006, 205.0000000000001, 220.0, 210.0, 200.0, 212.50000000000017,
    207.50000000000006, 202.50000000000017, 210.0, 215.0000000000001, 220.0, 212.50000000000017, 225.0000000000001,
    210.0, 227.50000000000006, 240.0, 247.50000000000006, 245.0000000000001, 255.0000000000001, 252.49999999999994,
    262.49999999999994, 267.50000000000006, 272.49999999999994, 270.0, 265.0000000000001, 275.0000000000001, 280.0,
    287.50000000000006, 272.49999999999994, 282.49999999999994, 280.0, 277.50000000000006, 290.0, 285.0000000000001,
    297.50000000000006, 300.0,
  ],
  rsbi: [
    89.44983328460054, 88.97504862706847, 88.44668553227028, 87.70674494739089, 87.60615272420284, 86.81874750844074,
    84.58201917731725, 80.67995828709675, 78.88724855980229, 78.51547135659919, 76.29659579860831, 76.16358632689972,
    75.2669496653646, 74.32117587427365, 74.05347679724343, 73.98648552499476, 73.72631711297171, 73.2010572213095,
    72.41519612531451, 71.44897989082942, 71.36322749251438, 71.13014412200855, 69.89379098030723, 69.49606553547828,
    69.28576477833543, 68.79310186926949, 68.48493965034089, 68.29588157135595, 66.40403177631944, 65.61844150158004,
    64.46895543302051, 64.37781812514427, 64.21134854548465, 63.9114099906851, 60.833165004362414, 60.10559426464141,
    61.312956046120064, 63.093984176446995, 63.7593293995681, 64.00677139330816, 64.94579519834868, 66.07925492227135,
    66.19542649010789, 66.28434001052929, 66.61496146782848, 66.89245370420235, 70.84201195337535, 70.91164163793388,
    71.5514743440299, 71.91156910605682, 72.08786835676517, 75.51176350036673, 77.24210956309346, 78.46534034615946,
    79.17744784444638, 79.36996494532093, 79.5731723249631, 79.60600273996273, 80.21133387978266, 80.82234509620179,
    80.93207250191833, 81.72663498786714, 82.96677937350034, 83.36851175860181, 83.45108740469576, 83.46540464177426,
    85.80888463899055, 85.8857788013022, 87.12624489296158, 87.47255231682952,
  ],
  wobv: [
    0.77181649153652, 0.7647956486401863, 0.6838548041520534, 0.6468366999240303, 0.6507753664661534, 0.717941068611241,
    0.7481967900375482, 0.6978882684127082, 0.6825675471397774, 0.703192745596506, 0.8382312707282786,
    0.8371067862321535, 0.8080519611877365, 0.8991475031276366, 0.9395377481624531, 0.9579204028578323,
    0.884020091489761, 0.8622149995564519, 0.7183144228885241, 0.912094688835049, 1.0242978742572642,
    0.9923234381092743, 1.0281043054021408, 1.1093639933376576, 1.125702733721574, 1.326617631331029,
    1.3906612239664153, 1.273750762391645, 1.1845022325259285, 1.267866149037169, 1.2898448603818053,
    1.4338002242336496, 1.5, 1.384795402685111, 1.3661406083712606, 1.4709784065261555, 1.3480840514162176,
    1.291721304158824, 1.4444849686904715, 1.4250621174742237, 1.4609992629638362, 1.4011394628838594,
    1.2634521535854033, 1.2747510948455278, 1.2506851506972274, 1.3568418483993714, 1.3125235764520953,
    1.1993150842036875, 1.1281492958361463, 1.212342274399089, 0.9658224696266209, 1.045890380862816,
    1.0378540751301557, 0.9476568219232748, 0.8932023682433861, 0.7682156677804655, 0.8445828532833759,
    0.8626932992717796, 0.7470136494012652, 0.7551033605477557, 0.8303824711963117, 0.5, 0.6656263549982908,
    0.6453548205951174, 0.6689683732172057, 0.7753422851768956, 0.6704052759298138, 0.5427438140225898,
    0.7265665611086439, 0.6216781901685954,
  ],
  cl: [
    90.86945219327421, 75.04973220992049, 88.21802077977165, 99.17404092199067, 73.54357946753736, 74.70797899899662,
    73.4031569475836, 81.64428043231364, 81.04978956024718, 80.12318556065827, 80.88699877897723, 72.2262620707324,
    80.69618639934552, 87.97197399007466, 84.028375177014, 73.20682602868268, 81.48325019169879, 74.05741225195779,
    76.30397650172485, 68.93687362945383, 74.96032860593448, 69.3357974366855, 67.05099963580092, 78.61774357018692,
    77.10193032049685, 75.58422990193748, 74.67353612474201, 75.39038252994678, 77.47161526459823, 76.21690939252935,
    59.96809132394128, 66.27002625207119, 68.71274169037065, 71.00095845303784, 84.75377102906586, 80.50950781818935,
    80.33651733850697, 62.47356533909647, 76.252034425228, 76.42354212937025, 61.70340437094452, 73.93285910658648,
    81.73552627257679, 68.6117127993675, 72.56429404607209, 79.3716307704273, 70.72327503370353, 75.71954644551334,
    69.67378856421416, 92.67638370734406, 77.93708424076746, 85.67193995567851, 75.9239570614346, 84.46435611840572,
    94.10751944478349, 89.08592647492051, 74.03148614630152, 75.18655603772311, 80.80980458570598, 73.32871998155639,
    59.37508731219605, 69.1180255017786, 100.0, 84.94182209667241, 92.09878340513563, 74.9536213953645,
    88.48820862578323, 50.0, 81.26776164942808, 81.87067534012746,
  ],
  ra: [
    9.79608579, 9.36540856, 10.16696824, 9.9057254, 10.30178068, 10.63208198, 10.03347302, 11.21924085, 11.0012924,
    10.67340649, 9.61606882, 10.42615567, 9.56955878, 10.52543659, 10.9183718, 9.95238885, 10.41426215, 10.58603738,
    10.57582836, 9.40377058, 11.16557992, 9.65624162, 10.58719318, 10.47710701, 11.54602162, 10.27956757, 11.51678626,
    10.99861731, 10.43787126, 10.70721105, 9.51302424, 10.71645282, 10.73076949, 10.71210939, 11.21443629, 11.18674947,
    10.64325794, 10.70207818, 9.97992291, 9.90470018, 10.3167873, 10.18938855, 10.4500327, 10.14191228, 10.48518847,
    10.8215197, 10.36159026, 11.86807098, 11.21612254, 9.85969869, 10.24047336, 10.63367086, 10.9040317, 11.27208193,
    10.80194613, 10.53139944, 10.45603724, 9.73810963, 10.80293254, 10.29462852, 10.19252936, 10.8227663, 10.5917254,
    10.89390098, 10.47557492, 9.54493151, 10.32362196, 10.22143274, 10.6119588, 10.83832271,
  ],
  rr_spont: [
    13.20903241, 13.97389823, 13.69447556, 11.86599988, 10.46076492, 13.00154602, 11.89231393, 12.27407289, 14.10629766,
    13.44314662, 14.07474424, 13.70455893, 15.19160814, 15.40559001, 13.7942235, 13.655849, 14.72296898, 15.66222565,
    14.21708745, 14.42855089, 14.98307117, 14.05347072, 15.10734966, 15.05906562, 15.29135522, 14.86419722, 15.44291631,
    16.27178665, 15.20071575, 15.63243516, 16.16364637, 15.39185314, 14.06056609, 15.26106321, 14.91656919, 15.87915629,
    14.09514104, 16.87392539, 16.06231403, 17.28611433, 15.3509904, 15.95938434, 16.33261368, 14.24384358, 15.10271019,
    13.90245176, 14.31223843, 15.75003628, 13.54025515, 15.29461695, 13.49074172, 15.91105717, 13.82419746, 12.58124307,
    13.27692534, 13.49335448, 16.55267815, 14.59043101, 12.9961154, 13.17269743, 14.4887583, 12.49081378, 12.83495556,
    14.54896113, 13.48444232, 14.25963706, 12.56211056, 12.68128144, 15.24798138, 14.89283469,
  ],
  etco2_rr: [
    15.432874062148704, 15.12780811577826, 16.481957280189178, 12.41212058493159, 13.96797407109986, 12.0,
    14.565797249996681, 14.286769626396506, 12.632092768539454, 16.238738844035517, 14.928377269766283,
    16.493728088252023, 15.706548432876012, 14.970874249482867, 18.697857553378075, 15.964100265080333,
    16.74768753365735, 15.865059283850048, 15.979525900626765, 17.36716171100875, 14.466419738433256,
    14.943814850697866, 17.790897208246584, 16.785400229129692, 17.153087349302254, 20.0, 15.998794606450236,
    16.497835289651633, 17.030139581764807, 15.638481108418837, 16.427596303867652, 18.981970106940214,
    18.364366121378207, 18.094337370738224, 19.205438484286184, 17.52338811005187, 19.233553874501734,
    18.18758748124364, 16.745181425776575, 19.566834747887235, 17.181778226013748, 19.819306181137726,
    16.661998536956848, 17.61398666004566, 17.827230563069904, 18.055491365936803, 16.66489990866688,
    15.136708081918671, 17.564480331859762, 18.619526938154948, 17.016094328756978, 16.40851882112641,
    15.668805886055818, 16.807323233453655, 17.30036837520344, 17.954396117681988, 14.398319945789266, 16.0531222907653,
    15.308294453234916, 13.841134506775207, 15.498825963086226, 13.0208936840551, 14.813329593595892,
    14.586637367601046, 13.020300291596417, 13.970915785743482, 14.881590564134322, 15.06144646147738,
    13.526067971191965, 15.42126200898129,
  ],
  vti: [
    465, 461.5, 464, 463.1, 462.4, 461.95, 461.8, 462.05, 462.65, 463.65, 460.15, 459.85, 458.3, 457.75, 456.15, 455.55,
    456.2, 456.6, 456.25, 453.85, 451.25, 451.15, 448.95, 446.6, 445.6, 442.8, 441.05, 442.75, 440.55, 442.15, 440.6,
    439.45, 436.65, 436.05, 436.7, 436.6, 440.6, 440.6, 436.6, 436.7, 436.05, 436.65, 439.45, 440.6, 442.15, 440.55,
    442.75, 441.05, 446.6, 448.95, 451.15, 451.25, 453.85, 456.6, 456.2, 455.55, 456.15, 457.75, 458.3, 459.85, 460.15,
    463.65, 462.65, 462.05, 461.8, 461.95, 462.4, 463.1, 464, 465,
  ],
  vte: [
    465, 461.5, 464, 463.1, 462.4, 461.95, 461.8, 462.05, 462.65, 463.65, 460.15, 459.85, 458.3, 457.75, 456.15, 455.55,
    456.2, 456.6, 456.25, 453.85, 451.25, 451.15, 448.95, 446.6, 445.6, 442.8, 441.05, 442.75, 440.55, 442.15, 440.6,
    439.45, 436.65, 436.05, 436.7, 436.6, 440.6, 440.6, 436.6, 436.7, 436.05, 436.65, 439.45, 440.6, 442.15, 440.55,
    442.75, 441.05, 446.6, 448.95, 451.15, 451.25, 453.85, 456.6, 456.2, 455.55, 456.15, 457.75, 458.3, 459.85, 460.15,
    463.65, 462.65, 462.05, 461.8, 461.95, 462.4, 463.1, 464, 465,
  ],
  vme: [
    6.3, 6.23, 6.28, 6.262, 6.248, 6.239, 6.236, 6.241, 6.253, 6.273, 6.203, 6.197, 6.166, 6.155, 6.123, 6.111, 6.124,
    6.132, 6.125, 6.077, 6.025, 6.023, 5.979, 5.932, 5.912, 5.856, 5.821, 5.855, 5.811, 5.843, 5.812, 5.789, 5.733,
    5.721, 5.734, 5.732, 5.812, 5.812, 5.732, 5.734, 5.721, 5.733, 5.789, 5.812, 5.843, 5.811, 5.855, 5.821, 5.932,
    5.979, 6.023, 6.025, 6.077, 6.132, 6.124, 6.111, 6.123, 6.155, 6.166, 6.197, 6.203, 6.273, 6.253, 6.241, 6.236,
    6.239, 6.248, 6.262, 6.28, 6.3,
  ],
  ti: [
    1.06, 1.046, 1.056, 1.0524, 1.0496, 1.0478, 1.0472, 1.0482, 1.0506, 1.0546, 1.0406, 1.0394, 1.0332, 1.031, 1.0246,
    1.0222, 1.0248, 1.0264, 1.025, 1.0154, 1.005, 1.0046, 0.9958, 0.9864, 0.9824, 0.9712, 0.9642, 0.971, 0.9622, 0.9686,
    0.9624, 0.9578, 0.9466, 0.9442, 0.9468, 0.9464, 0.9624, 0.9624, 0.9464, 0.9468, 0.9442, 0.9466, 0.9578, 0.9624,
    0.9686, 0.9622, 0.971, 0.9642, 0.9864, 0.9958, 1.0046, 1.005, 1.0154, 1.0264, 1.0248, 1.0222, 1.0246, 1.031, 1.0332,
    1.0394, 1.0406, 1.0546, 1.0506, 1.0482, 1.0472, 1.0478, 1.0496, 1.0524, 1.056, 1.06,
  ],
  te: [
    1.91, 1.931, 1.916, 1.9214, 1.9256, 1.9283, 1.9292, 1.9277, 1.9241, 1.9181, 1.9391, 1.9409, 1.9502, 1.9535, 1.9631,
    1.9667, 1.9628, 1.9604, 1.9625, 1.9769, 1.9925, 1.9931, 2.0063, 2.0204, 2.0264, 2.0432, 2.0537, 2.0435, 2.0567,
    2.0471, 2.0564, 2.0633, 2.0801, 2.0837, 2.0798, 2.0804, 2.0564, 2.0564, 2.0804, 2.0798, 2.0837, 2.0801, 2.0633,
    2.0564, 2.0471, 2.0567, 2.0435, 2.0537, 2.0204, 2.0063, 1.9931, 1.9925, 1.9769, 1.9604, 1.9628, 1.9667, 1.9631,
    1.9535, 1.9502, 1.9409, 1.9391, 1.9181, 1.9241, 1.9277, 1.9292, 1.9283, 1.9256, 1.9214, 1.916, 1.91,
  ],
  tc: [
    0.69, 0.669, 0.684, 0.6786, 0.6744, 0.6717, 0.6708, 0.6723, 0.6759, 0.6819, 0.6609, 0.6591, 0.6498, 0.6465, 0.6369,
    0.6333, 0.6372, 0.6396, 0.6375, 0.6231, 0.6075, 0.6069, 0.5937, 0.5796, 0.5736, 0.5568, 0.5463, 0.5565, 0.5433,
    0.5529, 0.5436, 0.5367, 0.5199, 0.5163, 0.5202, 0.5196, 0.5436, 0.5436, 0.5196, 0.5202, 0.5163, 0.5199, 0.5367,
    0.5436, 0.5529, 0.5433, 0.5565, 0.5463, 0.5796, 0.5937, 0.6069, 0.6075, 0.6231, 0.6396, 0.6372, 0.6333, 0.6369,
    0.6465, 0.6498, 0.6591, 0.6609, 0.6819, 0.6759, 0.6723, 0.6708, 0.6717, 0.6744, 0.6786, 0.684, 0.69,
  ],
  'p_0.1': [
    2.94, 2.954, 2.944, 2.9476, 2.9504, 2.9522, 2.9528, 2.9518, 2.9494, 2.9454, 2.9594, 2.9606, 2.9668, 2.969, 2.9754,
    2.9778, 2.9752, 2.9736, 2.975, 2.9846, 2.995, 2.9954, 3.0042, 3.0136, 3.0176, 3.0288, 3.0358, 3.029, 3.0378, 3.0314,
    3.0376, 3.0422, 3.0534, 3.0558, 3.0532, 3.0536, 3.0376, 3.0376, 3.0536, 3.0532, 3.0558, 3.0534, 3.0422, 3.0376,
    3.0314, 3.0378, 3.029, 3.0358, 3.0136, 3.0042, 2.9954, 2.995, 2.9846, 2.9736, 2.9752, 2.9778, 2.9754, 2.969, 2.9668,
    2.9606, 2.9594, 2.9454, 2.9494, 2.9518, 2.9528, 2.9522, 2.9504, 2.9476, 2.944, 2.94,
  ],
  auto_peep: [
    1.97, 1.977, 1.972, 1.9738, 1.9752, 1.9761, 1.9764, 1.9759, 1.9747, 1.9727, 1.9797, 1.9803, 1.9834, 1.9845, 1.9877,
    1.9889, 1.9876, 1.9868, 1.9875, 1.9923, 1.9975, 1.9977, 2.0021, 2.0068, 2.0088, 2.0144, 2.0179, 2.0145, 2.0189,
    2.0157, 2.0188, 2.0211, 2.0267, 2.0279, 2.0266, 2.0268, 2.0188, 2.0188, 2.0268, 2.0266, 2.0279, 2.0267, 2.0211,
    2.0188, 2.0157, 2.0189, 2.0145, 2.0179, 2.0068, 2.0021, 1.9977, 1.9975, 1.9923, 1.9868, 1.9876, 1.9889, 1.9877,
    1.9845, 1.9834, 1.9803, 1.9797, 1.9727, 1.9747, 1.9759, 1.9764, 1.9761, 1.9752, 1.9738, 1.972, 1.97,
  ],
  vme_s: [
    5.24, 5.184, 5.224, 5.2096, 5.1984, 5.1912, 5.1888, 5.1928, 5.2024, 5.2184, 5.1624, 5.1576, 5.1328, 5.124, 5.0984,
    5.0888, 5.0992, 5.1056, 5.1, 5.0616, 5.02, 5.0184, 4.9832, 4.9456, 4.9296, 4.8848, 4.8568, 4.884, 4.8488, 4.8744,
    4.8496, 4.8312, 4.7864, 4.7768, 4.7872, 4.7856, 4.8496, 4.8496, 4.7856, 4.7872, 4.7768, 4.7864, 4.8312, 4.8496,
    4.8744, 4.8488, 4.884, 4.8568, 4.9456, 4.9832, 5.0184, 5.02, 5.0616, 5.1056, 5.0992, 5.0888, 5.0984, 5.124, 5.1328,
    5.1576, 5.1624, 5.2184, 5.2024, 5.1928, 5.1888, 5.1912, 5.1984, 5.2096, 5.224, 5.24,
  ],
  e: [
    20.0, 18.791018998272882, 19.654576856649395, 19.343696027633854, 19.101899827288428, 18.94645941278065,
    18.894645941278064, 18.981001727115714, 19.18825561312608, 19.533678756476682, 18.324697754749565,
    18.221070811744386, 17.685664939550946, 17.495682210708114, 16.943005181347147, 16.735751295336787,
    16.96027633851468, 17.09844559585492, 16.97754749568221, 16.148531951640756, 15.25043177892919, 15.215889464594127,
    14.455958549222796, 13.644214162348877, 13.298791018998273, 12.331606217616578, 11.727115716753024,
    12.31433506044905, 11.554404145077722, 12.107081174438685, 11.57167530224525, 11.174438687392055,
    10.207253886010363, 10.0, 10.224525043177891, 10.18998272884283, 11.57167530224525, 11.57167530224525,
    10.18998272884283, 10.224525043177891, 10.0, 10.207253886010363, 11.174438687392055, 11.57167530224525,
    12.107081174438685, 11.554404145077722, 12.31433506044905, 11.727115716753024, 13.644214162348877,
    14.455958549222796, 15.215889464594127, 15.25043177892919, 16.148531951640756, 17.09844559585492, 16.96027633851468,
    16.735751295336787, 16.943005181347147, 17.495682210708114, 17.685664939550946, 18.221070811744386,
    18.324697754749565, 19.533678756476682, 19.18825561312608, 18.981001727115714, 18.894645941278064,
    18.94645941278065, 19.101899827288428, 19.343696027633854, 19.654576856649395, 20.0,
  ],
  ico2: [
    39.85, 39.885, 39.86, 39.869, 39.876, 39.8805, 39.882, 39.8795, 39.8735, 39.8635, 39.8985, 39.9015, 39.917, 39.9225,
    39.9385, 39.9445, 39.938, 39.934, 39.9375, 39.9615, 39.9875, 39.9885, 40.0105, 40.034, 40.044, 40.072, 40.0895,
    40.0725, 40.0945, 40.0785, 40.094, 40.1055, 40.1335, 40.1395, 40.133, 40.134, 40.094, 40.094, 40.134, 40.133,
    40.1395, 40.1335, 40.1055, 40.094, 40.0785, 40.0945, 40.0725, 40.0895, 40.034, 40.0105, 39.9885, 39.9875, 39.9615,
    39.934, 39.938, 39.9445, 39.9385, 39.9225, 39.917, 39.9015, 39.8985, 39.8635, 39.8735, 39.8795, 39.882, 39.8805,
    39.876, 39.869, 39.86, 39.85,
  ],
};
