/* eslint-disable camelcase */
export type CUSTOM_TREND_KEY =
  | 'ibp1'
  | 'ibp2'
  | 'nibp'
  | 'ppause/pmean/peep'
  | 'rr/rr_spont/etco2_rr'
  | 'vti/vte'
  | 'vme/vme_s'
  | 'fio2/spo2'
  | 'ti/te/tc'
  | 'flow/fi_peak/fe_peak'
  | 'p_0.1/auto_peep'
  | 'vico2/veco2'
  | 'pi/etco2/ico2'
  | 'sf_ratio/rox';
export type TrendKey = keyof Data['params'] | CUSTOM_TREND_KEY;

export type TTrendSetting = {
  mode: 'duo' | 'single';
  duo: TrendKey[][];
  single: TrendKey[];
};
export type TWaveSetting = {
  keys: WaveKeys[];
};

export const VIEW_ROLES = {
  main: 'main',
  viewOnly: 'viewOnly',
} as const;

export type ViewRole = keyof typeof VIEW_ROLES;
export type ViewData = { [key in ViewRole]: string[] };

export const DISPLAY_MODE = {
  simple: 'simple',
  all: 'all',
  wave: 'wave',
} as const;

export type DisplayMode = (typeof DISPLAY_MODE)[keyof typeof DISPLAY_MODE];

export const EAlarmLevel = {
  HIGH: { code: '3', string: 'HIGH' },
  MEDIUM: { code: '2', string: 'MEDIUM' },
  LOW: { code: '1', string: 'LOW' },
  INFO: { code: '0', string: 'INFO' },
  /* `WAVE_KEYS` is an array containing keys that represent different types of waves. It is used in the
  `makeWaves` function to iterate over each key and generate wave simulations for each type of wave. */
  unknown: { code: '0', string: 'unknown' },
  MECHANICAL: { code: '0', string: 'MECHANICAL' },
  PHYSICAL: { code: '0', string: 'PHYSICAL' },
} as const;

export type AlarmLevel = keyof typeof EAlarmLevel;

export const ALARM_PRIORITY: { [key in AlarmLevel]: number } = {
  HIGH: 4,
  MEDIUM: 3,
  LOW: 2,
  INFO: 1,
  unknown: 0,
  MECHANICAL: 0,
  PHYSICAL: 0,
};

export type InvalidParameterData = '--' | null | 'null' | 'Off';

export const PARAM_KEYS = [
  'hr',
  'st_i',
  'st_ii',
  'st_iii',
  'arrhythmia',
  'pvc',
  'rr',
  'spo2',
  'pi',
  'pr',
  'nibp_s',
  'nibp_m',
  'nibp_d',
  'nibp_pr',
  'nibp_time',
  'ibp1_s',
  'ibp1_m',
  'ibp1_d',
  'ibp1_pr',
  'ibp2_s',
  'ibp2_m',
  'ibp2_d',
  'ibp2_pr',
  'ibp3_s',
  'ibp3_m',
  'ibp3_d',
  'ibp3_pr',
  'ibp4_s',
  'ibp4_m',
  'ibp4_d',
  'ibp4_pr',
  'tmp1',
  'tmp2',
  'ico_bt',
  'ico_co',
  'ico_paw',
  'ico_pr',
  'etco2',
  'fico2',
  'etn2o',
  'fin2o',
  'ethal',
  'fihal',
  'etenf',
  'fienf',
  'etiso',
  'fiiso',
  'etsev',
  'fisev',
  'etdes',
  'fides',
  'flow',
  'peep',
  'fio2',
  'tsf_status',
  'pip',
  'estimated_tidal_volume',
  'v_delivery',
  'rr',
  'spo2',
  'pr',
  'airway_temp',
  'ptif',
  'pi',
  'sf_ratio',
  'rox',
  'alarm_status',
  'nibp_status',
  'fpeak',
  'map',
  'spont_resp',
  'leak_high',
  'total_leak_high',
  'mv',
  'ppeak',
  'ppause',
  'paw',
  'pmean',
  'vti',
  'vte',
  'vme',
  'fi_peak',
  'fe_peak',
  'peep_h',
  'peep_l',
  'peep',
  'flow',
  'ti',
  'te',
  'ie_ratio',
  'rr',
  'rr_spont',
  'vme_s',
  'p_0.1',
  'rsbi',
  'wobv',
  'wobp',
  'cl',
  'ra',
  'auto_peep',
  'exp_flow',
  'e',
  'tc',
  'fio2',
  'sf_ratio',
  'rox',
  'pr',
  'spo2',
  'pi',
  'etco2',
  'ico2',
  'etco2_rr',
  'vico2',
  'veco2',
  'vmeco2',
  'alarm_status',
  've_stroke',
  'h_freq',
  'amplitude',
  'vent_run',
] as const;
export type ParamKeys = (typeof PARAM_KEYS)[number];

export const SETTING_PARAM_KEYS = [
  'setting_demo_mode',
  'setting_bed_id',
  'setting_patient_id',
  'setting_patient_type',
  'setting_patient_height',
  'setting_therapy_mode',
  'setting_vti',
  'setting_flow',
  'setting_rr',
  'setting_peep',
  'setting_fio2',
  'setting_tinsp',
  'setting_trigger',
  'setting_f_trig',
  'setting_p_trig',
  'setting_trise',
  'setting_tpause',
  'setting_fend',
  'setting_sigh',
  'setting_adaptive_flow',
  'setting_pinsp',
  'setting_ps',
  'setting_flow_limit',
  'setting_exsens',
  'setting_exsens_psv',
  'setting_th_peep',
  'setting_tl_peep',
  'setting_peep_h',
  'setting_peep_l',
  'setting_minvol',
  'setting_rr_sens',
  'setting_st_type',
  'setting_volume_guarantee',
  'setting_ipap',
  'setting_epap',
  'setting_backup_apnea_mode',
  'setting_backup_vti',
  'setting_backup_pinsp',
  'setting_backup_rr',
  'setting_backup_tinsp',
  'setting_backup_trise',
  'setting_backup_tpause',
  'setting_backup_fend',
  'setting_backup_exsens',
  'setting_backup_plimit',
  'setting_ppause',
  'setting_apnea',
  'setting_trise_psv',
  'setting_flimit',
  'setting_power',
  'setting_map',
  'setting_h_freq',
  'setting_imv_etime',
  'setting_trigger_mode',
  'setting_plimit',
  'setting_b_guide',
  'setting_b_interval',
  'setting_ps_h',
  'setting_ps_l',
  'setting_patient_weight',
  'setting_fpeak',
  'setting_hfv_peep',
  'setting_base_flow',
  'setting_assist_flow',
  'setting_biflow_mode',
  'setting_rr_detection',
  'setting_backup_fio2',
  'setting_tsf_spo2',
  'setting_tsf_mode',
  'setting_tsf_fio2_low',
  'setting_tsf_fio2_high',
  'setting_tsf_time_const_inc',
  'setting_tsf_time_const_dec',
  'setting_pa+',
  'setting_cpap',
  'setting_pa-',
  'setting_trigger_level',
  'setting_rise_time',
  'setting_auto_start',
  'setting_inspiratory_time_ti',
  'setting_trigger_type',
  'setting_inspiratory_volume_vi',
  'setting_p_limit',
  'setting_backup_ti',
  'setting_backup_rr',
  'setting_dew_point',
  'setting_humidification_level',
  'alarm_apnea',
  'alarm_check_refill_water',
  'alarm_leakage_high',
  'setting_backup_ipap',
  'setting_max_pressure',
  'setting_ecg_cable',
  'setting_resp_lead',
  'setting_ibp1_mode',
  'setting_ibp2_mode',
  'setting_ibp3_mode',
  'setting_ibp4_mode',
  'setting_nibp_run',
] as const;
export type SettingParamKeys = (typeof SETTING_PARAM_KEYS)[number];

export const WAVE_KEYS = [
  'flow_wave',
  'pressure_wave',
  'volume_wave',
  'patient_flow_wave',
  'ecg_wave_i',
  'ecg_wave_ii',
  'ecg_wave_iii',
  'ecg_wave_avr',
  'ecg_wave_avl',
  'ecg_wave_avf',
  'ecg_wave_v',
  'resp_wave',
  'spo2_wave',
  'ibp1_wave',
  'ibp2_wave',
  'ibp3_wave',
  'ibp4_wave',
  'co2_wave',
  // 'etco2_wave',
] as const;
export type WaveKeys = (typeof WAVE_KEYS)[number];
export const WAVE_TYPES = ['FLOW', 'SPO2', 'PRESSURE', 'VOLUME', 'ECG', 'IBP'];
export type WaveTypes = (typeof WAVE_TYPES)[number] | null;

export type CustomWaveKeys = 'ptif';
// export type TrendKeys =
//   | 'rox'
//   | 'estimated_tidal_volume'
//   | 'v_delivery'
//   | 'spo2'
//   | 'sf_ratio'
//   | 'rr'
//   | 'pr'
//   | 'pip'
//   | 'pi'
//   | 'peep'
//   | 'flow'
//   | 'fio2';

export const DEVICE_MODEL = {
  HFT700: 'HFT700',
  MV50: 'MV50',
  MV2000: 'MV2000',
  HFT750: 'HFT750',
  MP800: 'MP800',
  MP1000NTP: 'MP1000NTP',
  MP1300: 'MP1300',
  HFT751: 'HFT751',
  BLTM10: 'BLTM10',
} as const;
export type DeviceModel = keyof typeof DEVICE_MODEL;
// Amy: setting에 쓰이는 device 이름
export const SETTING_DEVICE: Pick<typeof DEVICE_MODEL, 'HFT700' | 'MV50' | 'MP1300' | 'MV2000'> = {
  HFT700: 'HFT700',
  MV50: 'MV50',
  MP1300: 'MP1300',
  MV2000: 'MV2000',
};

export const IBP_MODE = {
  '0': 'ART',
  '1': 'FEM',
  '2': 'PA',
  '3': 'CVP',
  '4': 'LA',
  '5': 'RA',
  '6': 'ICP',
  '7': 'SP',
} as const;

export const RESP_MODE = {
  '0': 'Ⅰ',
  '1': 'Ⅱ',
};

// Todo AMy: 확인 후 마무리하기
export const NIBP_STATUS = {
  Normal: '0',
  'Cuff&Hose': '65',
  'No Pulse': '66',
  'Patient Motion': '67',
  Envelope: '68',
  'Time Over': '81',
  'Over Press': '82',
  Emotion: '97',
  'Many Pulse': '98',
  WeakPulse: '99',
  'Zero Press': '100',
  'Measure Error': '101',
  'Exceed Press': '102',
  'MANUAL STOP': '112',
  Searching: '113',
} as const;

export const TSF_MODE = { ON: '1', OFF: '0' } as const;
export type TsfMode = (typeof TSF_MODE)[keyof typeof TSF_MODE];

export const VOLUME_GUARANTEE = { ON: '1', OFF: '0' } as const;
export type VolumeGuarantee = (typeof VOLUME_GUARANTEE)[keyof typeof VOLUME_GUARANTEE];

export const BIFLOW_MODE = { ON: '1', OFF: '0' } as const;
export type BiflowMode = (typeof BIFLOW_MODE)[keyof typeof BIFLOW_MODE];

export const TRIGGER_TYPE = { S: '1', 'S/T': '2', T: '3' } as const;
export type TriggerType = (typeof TRIGGER_TYPE)[keyof typeof TRIGGER_TYPE];

export const VENT_RUN_TYPE = { ON: '1', OFF: '0' } as const;
export type VentRunType = (typeof VENT_RUN_TYPE)[keyof typeof VENT_RUN_TYPE];

export const HFT_THERAPY_MODE = { HF: '1', CPAP: '2', 'Bi-level': '3' } as const;
// Todo amy: 확인 필요
export const MV_THERAPY_MODE = {
  'V-ACV': '0',
  'V-SIMV': '1',
  'TCPL-AC': '2',
  'TCPL-SIMV': '3',
  'P-ACV': '4',
  'P-SIMV': '5',
  SPONT: '6',
  AwPRV: '7',
  tBiLevel: '8',
  PRVC: '9',
  O2Stream: '10',
  PRVC_SIMV: '11',
  'D-HFV': '12',
  'S-HFV': '13',
  AUTO: '14',
  CPR: '15',
  BiPhasic: '16',
  CPAP: '17',
  HFNC: '18',
} as const;

// Amy : front 에서 사용하는 테라피모드
export const MV2000TherapyMode = [
  'PRVC',
  'V-ACV',
  'P-ACV',
  'PRVC-SIMV',
  'V-SIMV',
  'P-SIMV',
  'AUTO',
  'SPONT',
  'CPR',
  'tBiLevel',
  'AwPRV',
  'TCPL-AC',
  'TCPL-SIMV',
  'O2Stream',
  'S-HFV',
  'D-HFV',
] as const;
export const MV50TherapyMode = [
  'PRVC',
  'V-ACV',
  'P-ACV',
  'PRVC-SIMV',
  'V-SIMV',
  'P-SIMV',
  'BiPhasic',
  'CPAP',
  'AUTO',
  'HFNC',
  'tBiLevel',
] as const;
export const HFT700TherapyMode = [
  'HF',
  'HF_tsf',
  'HF_bi_tsf',
  'HF_bi',
  'CPAP',
  'BI-LEVEL_s',
  'BI-LEVEL_s_v',
  'BI-LEVEL_t',
  'BI-LEVEL_t_v',
  'BI-LEVEL_s_t',
  'BI-LEVEL_s_t_v',
] as const;
export const MPTherapyMode = ['default'] as const;
export type TMv2000TherapyMode = (typeof MV2000TherapyMode)[number];
export type TMv50TherapyMode = (typeof MV50TherapyMode)[number];
export type THft700TherapyMode = (typeof HFT700TherapyMode)[number];
export type TherapyMode<D extends DeviceModel = DeviceModel> = D extends 'HFT700'
  ? THft700TherapyMode
  : D extends 'HFT750'
  ? THft700TherapyMode
  : D extends 'HFT751'
  ? THft700TherapyMode
  : D extends 'MV50'
  ? TMv50TherapyMode
  : D extends 'MV2000'
  ? TMv2000TherapyMode
  : 'default';

// Amy: 기기에서 오는 테라피모드
export type THft700DeviceTherapyMode = (typeof HFT_THERAPY_MODE)[keyof typeof HFT_THERAPY_MODE];
export type TMVDeviceTherapyMode = (typeof MV_THERAPY_MODE)[keyof typeof MV_THERAPY_MODE];
export type DeviceTherapyMode<D extends DeviceModel = DeviceModel> = D extends 'HFT700'
  ? THft700DeviceTherapyMode
  : D extends 'HFT750'
  ? THft700DeviceTherapyMode
  : D extends 'MV50'
  ? TMVDeviceTherapyMode
  : D extends 'MV2000'
  ? TMVDeviceTherapyMode
  : never;

// Amy : therapy mode 가 없는 경우 default 로 사용
export type TTherapyMode = TMv50TherapyMode | TMv2000TherapyMode | THft700TherapyMode | 'default';

type WaveData = { [key in WaveKeys]?: number[] };
type SettingParamData = { [key in SettingParamKeys]?: string | InvalidParameterData };
type ParamData = {
  [key in ParamKeys]?: {
    current: string | InvalidParameterData;
    max: string | InvalidParameterData;
    min: string | InvalidParameterData;
  };
};

export type Alarm = {
  alarm_msg: string;
  alarm_level: AlarmLevel;
};

export type WaveProperty = {
  signal_name: string;
  hz: string;
  digitize_value: string | null;
  min: string;
  max: string;
  baseline: string;
  auto_range: string;
};

export type Data<M extends DeviceModel = DeviceModel> = {
  acq_time: string;
  device_model: M;
  bed_id: string;
  patient_id: string;
  device_serial: string;
  params: ParamData; // (hft700Params & mv50Params) & mpParams;
  settings: SettingParamData; // hft700Settings<M> & mv50Settings<M> & mpSettings;
  alarms: Alarm[];
  rox_notification: unknown[];
  wave_signal_property: WaveProperty[];
  protocol_ver: string;
  man_code: string; // Amy : 나중에 vandorID로 변경
  ambulance_id: null | string;
  group_id: null | string;
} & WaveData;

// type mpParams = {
//   hr: {
//     current: number | InvalidParameterData;
//     max: number | InvalidParameterData;
//     min: number | InvalidParameterData;
//   };
//   st_i: {
//     current: number | InvalidParameterData;
//     max: number | InvalidParameterData;
//     min: number | InvalidParameterData;
//   };
//   st_ii: {
//     current: number | InvalidParameterData;
//     max: number | InvalidParameterData;
//     min: number | InvalidParameterData;
//   };
//   st_iii: {
//     current: number | InvalidParameterData;
//     max: number | InvalidParameterData;
//     min: number | InvalidParameterData;
//   };
//   arrhythmia: {
//     current: number | InvalidParameterData;
//     max: number | InvalidParameterData;
//     min: number | InvalidParameterData;
//   };
//   pvc: {
//     current: number | InvalidParameterData;
//     max: number | InvalidParameterData;
//     min: number | InvalidParameterData;
//   };
//   rr: {
//     current: number | InvalidParameterData;
//     max: number | InvalidParameterData;
//     min: number | InvalidParameterData;
//   };
//   spo2: {
//     current: number | InvalidParameterData;
//     max: number | InvalidParameterData;
//     min: number | InvalidParameterData;
//   };
//   pi: {
//     current: number | InvalidParameterData;
//     max: number | InvalidParameterData;
//     min: number | InvalidParameterData;
//   };
//   pr: {
//     current: number | InvalidParameterData;
//     max: number | InvalidParameterData;
//     min: number | InvalidParameterData;
//   };
//   nibp_s: {
//     current: number | InvalidParameterData;
//     max: number | InvalidParameterData;
//     min: number | InvalidParameterData;
//   };
//   nibp_m: {
//     current: number | InvalidParameterData;
//     max: number | InvalidParameterData;
//     min: number | InvalidParameterData;
//   };
//   nibp_d: {
//     current: number | InvalidParameterData;
//     max: number | InvalidParameterData;
//     min: number | InvalidParameterData;
//   };
//   nibp_pr: {
//     current: number | InvalidParameterData;
//     max: number | InvalidParameterData;
//     min: number | InvalidParameterData;
//   };
//   nibp_time: {
//     current: string | InvalidParameterData;
//     max: string | InvalidParameterData;
//     min: string | InvalidParameterData;
//   };
//   ibp1_s: {
//     current: number | InvalidParameterData;
//     max: number | InvalidParameterData;
//     min: number | InvalidParameterData;
//   };
//   ibp1_m: {
//     current: number | InvalidParameterData;
//     max: number | InvalidParameterData;
//     min: number | InvalidParameterData;
//   };
//   ibp1_d: {
//     current: number | InvalidParameterData;
//     max: number | InvalidParameterData;
//     min: number | InvalidParameterData;
//   };
//   ibp1_pr: {
//     current: number | InvalidParameterData;
//     max: number | InvalidParameterData;
//     min: number | InvalidParameterData;
//   };
//   ibp2_s: {
//     current: number | InvalidParameterData;
//     max: number | InvalidParameterData;
//     min: number | InvalidParameterData;
//   };
//   ibp2_m: {
//     current: number | InvalidParameterData;
//     max: number | InvalidParameterData;
//     min: number | InvalidParameterData;
//   };
//   ibp2_d: {
//     current: number | InvalidParameterData;
//     max: number | InvalidParameterData;
//     min: number | InvalidParameterData;
//   };
//   ibp2_pr: {
//     current: number | InvalidParameterData;
//     max: number | InvalidParameterData;
//     min: number | InvalidParameterData;
//   };
//   ibp3_s: {
//     current: number | InvalidParameterData;
//     max: number | InvalidParameterData;
//     min: number | InvalidParameterData;
//   };
//   ibp3_m: {
//     current: number | InvalidParameterData;
//     max: number | InvalidParameterData;
//     min: number | InvalidParameterData;
//   };
//   ibp3_d: {
//     current: number | InvalidParameterData;
//     max: number | InvalidParameterData;
//     min: number | InvalidParameterData;
//   };
//   ibp3_pr: {
//     current: number | InvalidParameterData;
//     max: number | InvalidParameterData;
//     min: number | InvalidParameterData;
//   };
//   ibp4_s: {
//     current: number | InvalidParameterData;
//     max: number | InvalidParameterData;
//     min: number | InvalidParameterData;
//   };
//   ibp4_m: {
//     current: number | InvalidParameterData;
//     max: number | InvalidParameterData;
//     min: number | InvalidParameterData;
//   };
//   ibp4_d: {
//     current: number | InvalidParameterData;
//     max: number | InvalidParameterData;
//     min: number | InvalidParameterData;
//   };
//   ibp4_pr: {
//     current: number | InvalidParameterData;
//     max: number | InvalidParameterData;
//     min: number | InvalidParameterData;
//   };
//   tmp1: {
//     current: number | InvalidParameterData;
//     max: number | InvalidParameterData;
//     min: number | InvalidParameterData;
//   };
//   tmp2: {
//     current: number | InvalidParameterData;
//     max: number | InvalidParameterData;
//     min: number | InvalidParameterData;
//   };
//   ico_bt: {
//     current: number | InvalidParameterData;
//     max: number | InvalidParameterData;
//     min: number | InvalidParameterData;
//   };
//   ico_co: {
//     current: number | InvalidParameterData;
//     max: number | InvalidParameterData;
//     min: number | InvalidParameterData;
//   };
//   ico_paw: {
//     current: number | InvalidParameterData;
//     max: number | InvalidParameterData;
//     min: number | InvalidParameterData;
//   };
//   ico_pr: {
//     current: number | InvalidParameterData;
//     max: number | InvalidParameterData;
//     min: number | InvalidParameterData;
//   };
//   etco2: {
//     current: number | InvalidParameterData;
//     max: number | InvalidParameterData;
//     min: number | InvalidParameterData;
//   };
//   fico2: {
//     current: number | InvalidParameterData;
//     max: number | InvalidParameterData;
//     min: number | InvalidParameterData;
//   };
//   etn2o: {
//     current: number | InvalidParameterData;
//     max: number | InvalidParameterData;
//     min: number | InvalidParameterData;
//   };
//   fin2o: {
//     current: number | InvalidParameterData;
//     max: number | InvalidParameterData;
//     min: number | InvalidParameterData;
//   };
//   ethal: {
//     current: number | InvalidParameterData;
//     max: number | InvalidParameterData;
//     min: number | InvalidParameterData;
//   };
//   fihal: {
//     current: number | InvalidParameterData;
//     max: number | InvalidParameterData;
//     min: number | InvalidParameterData;
//   };
//   etenf: {
//     current: number | InvalidParameterData;
//     max: number | InvalidParameterData;
//     min: number | InvalidParameterData;
//   };
//   fienf: {
//     current: number | InvalidParameterData;
//     max: number | InvalidParameterData;
//     min: number | InvalidParameterData;
//   };
//   etiso: {
//     current: number | InvalidParameterData;
//     max: number | InvalidParameterData;
//     min: number | InvalidParameterData;
//   };
//   fiiso: {
//     current: number | InvalidParameterData;
//     max: number | InvalidParameterData;
//     min: number | InvalidParameterData;
//   };
//   etsev: {
//     current: number | InvalidParameterData;
//     max: number | InvalidParameterData;
//     min: number | InvalidParameterData;
//   };
//   fisev: {
//     current: number | InvalidParameterData;
//     max: number | InvalidParameterData;
//     min: number | InvalidParameterData;
//   };
//   etdes: {
//     current: number | InvalidParameterData;
//     max: number | InvalidParameterData;
//     min: number | InvalidParameterData;
//   };
//   fides: {
//     current: number | InvalidParameterData;
//     max: number | InvalidParameterData;
//     min: number | InvalidParameterData;
//   };
// };
// type hft700Params = {
//   flow: {
//     current: number | InvalidParameterData;
//     max: number | InvalidParameterData;
//     min: number | InvalidParameterData;
//   };
//   peep: {
//     current: number | InvalidParameterData;
//     max: number | InvalidParameterData;
//     min: number | InvalidParameterData;
//   };
//   fio2: {
//     current: number | InvalidParameterData;
//     max: number | InvalidParameterData;
//     min: number | InvalidParameterData;
//   };
//   tsf_status: {
//     current: number | InvalidParameterData;
//     max: number | InvalidParameterData;
//     min: number | InvalidParameterData;
//   };
//   pip: {
//     current: number | InvalidParameterData;
//     max: number | InvalidParameterData;
//     min: number | InvalidParameterData;
//   };
//   estimated_tidal_volume: {
//     // vt_est
//     current: number | InvalidParameterData;
//     max: number | InvalidParameterData;
//     min: number | InvalidParameterData;
//   };
//   v_delivery: {
//     current: number | InvalidParameterData;
//     max: number | InvalidParameterData;
//     min: number | InvalidParameterData;
//   };
//   rr: {
//     current: number | InvalidParameterData;
//     max: number | InvalidParameterData;
//     min: number | InvalidParameterData;
//   };
//   spo2: {
//     current: number | InvalidParameterData;
//     max: number | InvalidParameterData;
//     min: number | InvalidParameterData;
//   };
//   pr: {
//     current: number | InvalidParameterData;
//     max: number | InvalidParameterData;
//     min: number | InvalidParameterData;
//   };
//   airway_temp: {
//     current: number | InvalidParameterData;
//     max: number | InvalidParameterData;
//     min: number | InvalidParameterData;
//   };
//   ptif: {
//     current: number | InvalidParameterData;
//     max: number | InvalidParameterData;
//     min: number | InvalidParameterData;
//   };
//   pi: {
//     current: number | InvalidParameterData;
//     max: number | InvalidParameterData;
//     min: number | InvalidParameterData;
//   };
//   sf_ratio: {
//     current: number | InvalidParameterData;
//     max: number | InvalidParameterData;
//     min: number | InvalidParameterData;
//   };
//   rox: {
//     current: number | InvalidParameterData;
//     max: number | InvalidParameterData;
//     min: number | InvalidParameterData;
//   };
//   alarm_status: {
//     current: number | InvalidParameterData;
//     max: number | InvalidParameterData;
//     min: number | InvalidParameterData;
//   };

//   nibp_status: {
//     current: NibpStatus | InvalidParameterData;
//     max: number | InvalidParameterData;
//     min: number | InvalidParameterData;
//   };

//   fpeak: {
//     current: number | InvalidParameterData;
//     max: number | InvalidParameterData;
//     min: number | InvalidParameterData;
//   };
//   map: {
//     current: number | InvalidParameterData;
//     max: number | InvalidParameterData;
//     min: number | InvalidParameterData;
//   };
//   spont_resp: {
//     current: number | InvalidParameterData;
//     max: number | InvalidParameterData;
//     min: number | InvalidParameterData;
//   };
//   leak_high: {
//     current: number | InvalidParameterData;
//     max: number | InvalidParameterData;
//     min: number | InvalidParameterData;
//   };
//   total_leak_high: {
//     current: number | InvalidParameterData;
//     max: number | InvalidParameterData;
//     min: number | InvalidParameterData;
//   };
//   mv: {
//     current: number | InvalidParameterData;
//     max: number | InvalidParameterData;
//     min: number | InvalidParameterData;
//   };
// };

// type mv50Params = {
//   ppeak: {
//     current: number | InvalidParameterData;
//     max: number | InvalidParameterData;
//     min: number | InvalidParameterData;
//   };
//   ppause: {
//     current: number | InvalidParameterData;
//     max: number | InvalidParameterData;
//     min: number | InvalidParameterData;
//   };
//   paw: {
//     current: number | InvalidParameterData;
//     max: number | InvalidParameterData;
//     min: number | InvalidParameterData;
//   };
//   pmean: {
//     current: number | InvalidParameterData;
//     max: number | InvalidParameterData;
//     min: number | InvalidParameterData;
//   };
//   vti: {
//     current: number | InvalidParameterData;
//     max: number | InvalidParameterData;
//     min: number | InvalidParameterData;
//   };
//   vte: {
//     current: number | InvalidParameterData;
//     max: number | InvalidParameterData;
//     min: number | InvalidParameterData;
//   };
//   vme: {
//     current: number | InvalidParameterData;
//     max: number | InvalidParameterData;
//     min: number | InvalidParameterData;
//   };
//   fi_peak: {
//     current: number | InvalidParameterData;
//     max: number | InvalidParameterData;
//     min: number | InvalidParameterData;
//   };
//   fe_peak: {
//     current: number | InvalidParameterData;
//     max: number | InvalidParameterData;
//     min: number | InvalidParameterData;
//   };
//   peep_h: {
//     current: number | InvalidParameterData;
//     max: number | InvalidParameterData;
//     min: number | InvalidParameterData;
//   };
//   peep_l: {
//     current: number | InvalidParameterData;
//     max: number | InvalidParameterData;
//     min: number | InvalidParameterData;
//   };
//   peep: {
//     current: number | InvalidParameterData;
//     max: number | InvalidParameterData;
//     min: number | InvalidParameterData;
//   };
//   flow: {
//     current: number | InvalidParameterData;
//     max: number | InvalidParameterData;
//     min: number | InvalidParameterData;
//   };
//   ti: {
//     current: number | InvalidParameterData;
//     max: number | InvalidParameterData;
//     min: number | InvalidParameterData;
//   };
//   te: {
//     current: number | InvalidParameterData;
//     max: number | InvalidParameterData;
//     min: number | InvalidParameterData;
//   };
//   ie_ratio: {
//     current: number | InvalidParameterData;
//     max: number | InvalidParameterData;
//     min: number | InvalidParameterData;
//   };
//   rr: {
//     current: number | InvalidParameterData;
//     max: number | InvalidParameterData;
//     min: number | InvalidParameterData;
//   };
//   rr_spont: {
//     current: number | InvalidParameterData;
//     max: number | InvalidParameterData;
//     min: number | InvalidParameterData;
//   };
//   vme_s: {
//     current: number | InvalidParameterData;
//     max: number | InvalidParameterData;
//     min: number | InvalidParameterData;
//   };
//   'p_0.1': {
//     current: number | InvalidParameterData;
//     max: number | InvalidParameterData;
//     min: number | InvalidParameterData;
//   };
//   rsbi: {
//     current: number | InvalidParameterData;
//     max: number | InvalidParameterData;
//     min: number | InvalidParameterData;
//   };
//   wobv: {
//     current: number | InvalidParameterData;
//     max: number | InvalidParameterData;
//     min: number | InvalidParameterData;
//   };
//   wobp: {
//     current: number | InvalidParameterData;
//     max: number | InvalidParameterData;
//     min: number | InvalidParameterData;
//   };
//   cl: {
//     current: number | InvalidParameterData;
//     max: number | InvalidParameterData;
//     min: number | InvalidParameterData;
//   };
//   ra: {
//     current: number | InvalidParameterData;
//     max: number | InvalidParameterData;
//     min: number | InvalidParameterData;
//   };
//   auto_peep: {
//     current: number | InvalidParameterData;
//     max: number | InvalidParameterData;
//     min: number | InvalidParameterData;
//   };
//   exp_flow: {
//     current: number | InvalidParameterData;
//     max: number | InvalidParameterData;
//     min: number | InvalidParameterData;
//   };
//   e: {
//     current: number | InvalidParameterData;
//     max: number | InvalidParameterData;
//     min: number | InvalidParameterData;
//   };
//   tc: {
//     current: number | InvalidParameterData;
//     max: number | InvalidParameterData;
//     min: number | InvalidParameterData;
//   };
//   fio2: {
//     current: number | InvalidParameterData;
//     max: number | InvalidParameterData;
//     min: number | InvalidParameterData;
//   };
//   sf_ratio: {
//     current: number | InvalidParameterData;
//     max: number | InvalidParameterData;
//     min: number | InvalidParameterData;
//   };
//   rox: {
//     current: number | InvalidParameterData;
//     max: number | InvalidParameterData;
//     min: number | InvalidParameterData;
//   };
//   pr: {
//     current: number | InvalidParameterData;
//     max: number | InvalidParameterData;
//     min: number | InvalidParameterData;
//   };
//   spo2: {
//     current: number | InvalidParameterData;
//     max: number | InvalidParameterData;
//     min: number | InvalidParameterData;
//   };
//   pi: {
//     current: number | InvalidParameterData;
//     max: number | InvalidParameterData;
//     min: number | InvalidParameterData;
//   };
//   etco2: {
//     current: number | InvalidParameterData;
//     max: number | InvalidParameterData;
//     min: number | InvalidParameterData;
//   };
//   ico2: {
//     current: number | InvalidParameterData;
//     max: number | InvalidParameterData;
//     min: number | InvalidParameterData;
//   };
//   etco2_rr: {
//     current: number | InvalidParameterData;
//     max: number | InvalidParameterData;
//     min: number | InvalidParameterData;
//   };
//   vico2: {
//     current: number | InvalidParameterData;
//     max: number | InvalidParameterData;
//     min: number | InvalidParameterData;
//   };
//   veco2: {
//     current: number | InvalidParameterData;
//     max: number | InvalidParameterData;
//     min: number | InvalidParameterData;
//   };
//   vmeco2: {
//     current: number | InvalidParameterData;
//     max: number | InvalidParameterData;
//     min: number | InvalidParameterData;
//   };
//   alarm_status: {
//     current: number | InvalidParameterData;
//     max: number | InvalidParameterData;
//     min: number | InvalidParameterData;
//   };
//   ve_stroke: {
//     current: number | InvalidParameterData;
//     max: number | InvalidParameterData;
//     min: number | InvalidParameterData;
//   };
//   h_freq: {
//     current: number | InvalidParameterData;
//     max: number | InvalidParameterData;
//     min: number | InvalidParameterData;
//   };
//   amplitude: {
//     current: number | InvalidParameterData;
//     max: number | InvalidParameterData;
//     min: number | InvalidParameterData;
//   };
//   // !: vent_run 없는 장비도 있음
//   vent_run: {
//     current: VentRunType;
//     max: number | InvalidParameterData;
//     min: number | InvalidParameterData;
//   };
// };

// type mpSettings = {
//   // setting_demo_mode: number | InvalidParameterData;
//   // setting_unit_bp: number | InvalidParameterData;
//   // setting_unit_gas: number | InvalidParameterData;
//   // setting_unit_temp: number | InvalidParameterData;
//   // setting_bed_id: number | InvalidParameterData;
//   // setting_patient_id: number | InvalidParameterData;
//   // setting_patient_firstname: number | InvalidParameterData;
//   // setting_patient_lastname: number | InvalidParameterData;
//   // setting_patient_birthday: number | InvalidParameterData;
//   // setting_patient_mode: number | InvalidParameterData;
//   // setting_patient_height: number | InvalidParameterData;
//   // setting_patient_weight: number | InvalidParameterData;
//   setting_ecg_cable: string | InvalidParameterData;
//   // setting_arrhythmia: number | InvalidParameterData;
//   // setting_pacemaker: number | InvalidParameterData;
//   // setting_esu_protection: number | InvalidParameterData;
//   // setting_alarm_volume: number | InvalidParameterData;
//   // setting_qrs_volume: number | InvalidParameterData;
//   // setting_hr_source: number | InvalidParameterData;
//   // setting_qrs_detection: number | InvalidParameterData;
//   // setting_ecg_filter: number | InvalidParameterData;
//   // setting_st_level: number | InvalidParameterData;
//   // setting_spo2_sensitivity: number | InvalidParameterData;
//   // setting_rr_source: number | InvalidParameterData;
//   setting_resp_lead: string | InvalidParameterData;
//   // setting_resp_filter: number | InvalidParameterData;
//   // setting_resp_sensitivity: number | InvalidParameterData;
//   // setting_resp_pr_average: number | InvalidParameterData;
//   // setting_nibp_run: number | InvalidParameterData;
//   // setting_nibp_auto: number | InvalidParameterData;
//   // setting_nibp_interval: number | InvalidParameterData;
//   // setting_nibp_stat: number | InvalidParameterData;
//   // setting_nibp_duration: number | InvalidParameterData;
//   // setting_nibp_init_pressure: number | InvalidParameterData;
//   // setting_nibp_sensitivity: number | InvalidParameterData;
//   setting_ibp1_mode: string | InvalidParameterData;
//   // setting_ibp1_center: number | InvalidParameterData;
//   setting_ibp2_mode: string | InvalidParameterData;
//   // setting_ibp2_center: number | InvalidParameterData;
//   setting_ibp3_mode: string | InvalidParameterData;
//   // setting_ibp3_center: number | InvalidParameterData;
//   setting_ibp4_mode: string | InvalidParameterData;
//   // setting_ibp4_center: number | InvalidParameterData;
//   // setting_ico_auto: number | InvalidParameterData;
//   // setting_ico_type: number | InvalidParameterData;
//   // setting_ico_inject_tmp: number | InvalidParameterData;
//   // setting_ico_inject_vol: number | InvalidParameterData;
//   // setting_ico_constant: number | InvalidParameterData;
//   // setting_multigas_o2: number | InvalidParameterData;
//   // setting_multigas_n2o: number | InvalidParameterData;
//   setting_nibp_run: string | InvalidParameterData;
// };

// type hft700Settings<M extends DeviceModel = DeviceModel> = {
//   setting_demo_mode: number | InvalidParameterData;
//   setting_bed_id: number | InvalidParameterData;
//   setting_patient_id: number | InvalidParameterData;
//   setting_therapy_mode: DeviceTherapyMode<M>;
//   setting_flow: number | InvalidParameterData;
//   setting_base_flow: number | InvalidParameterData;
//   setting_assist_flow: number | InvalidParameterData;
//   setting_biflow_mode: BiflowMode | InvalidParameterData;
//   setting_rr_detection: number | InvalidParameterData;
//   setting_backup_fio2: number | InvalidParameterData;
//   setting_tsf_spo2: number | InvalidParameterData;
//   setting_tsf_mode: TsfMode | InvalidParameterData;
//   setting_tsf_fio2_low: number | InvalidParameterData;
//   setting_tsf_fio2_high: number | InvalidParameterData;
//   setting_tsf_time_const_inc: number | InvalidParameterData;
//   setting_tsf_time_const_dec: number | InvalidParameterData;
//   'setting_pa+': number | InvalidParameterData;
//   setting_cpap: number | InvalidParameterData;
//   'setting_pa-': number | InvalidParameterData;
//   setting_trigger_level: number | InvalidParameterData;
//   setting_rise_time: number | InvalidParameterData;
//   setting_auto_start: number | InvalidParameterData;
//   setting_ipap: number | InvalidParameterData;
//   setting_epap: number | InvalidParameterData;
//   setting_rr: number | InvalidParameterData;
//   setting_inspiratory_time_ti: number | InvalidParameterData;
//   setting_trigger_type: TriggerType | InvalidParameterData;
//   setting_volume_guarantee: VolumeGuarantee | InvalidParameterData;
//   setting_inspiratory_volume_vi: number | InvalidParameterData;
//   setting_p_limit: number | InvalidParameterData;
//   setting_backup_ti: number | InvalidParameterData;
//   setting_backup_rr: number | InvalidParameterData;
//   setting_fio2: number | InvalidParameterData;
//   setting_dew_point: number | InvalidParameterData;
//   setting_humidification_level: number | InvalidParameterData;
//   alarm_apnea: number | InvalidParameterData;
//   alarm_check_refill_water: number | InvalidParameterData;
//   alarm_leakage_high: number | InvalidParameterData;
//   setting_backup_ipap: number | InvalidParameterData;
//   setting_max_pressure: number | InvalidParameterData;
// };

// type mv50Settings<M extends DeviceModel = DeviceModel> = {
//   setting_demo_mode: number | InvalidParameterData;
//   setting_bed_id: number | InvalidParameterData;
//   setting_patient_id: number | InvalidParameterData;
//   setting_patient_type: number | InvalidParameterData;
//   setting_patient_height: number | InvalidParameterData;
//   setting_therapy_mode: DeviceTherapyMode<M>;
//   setting_vti: number | InvalidParameterData;
//   setting_flow: number | InvalidParameterData;
//   setting_rr: number | InvalidParameterData;
//   setting_peep: number | InvalidParameterData;
//   setting_fio2: number | InvalidParameterData;
//   setting_tinsp: number | InvalidParameterData;
//   setting_trigger: number | InvalidParameterData;
//   setting_f_trig: number | InvalidParameterData;
//   setting_p_trig: number | InvalidParameterData;
//   setting_trise: number | InvalidParameterData;
//   setting_tpause: number | InvalidParameterData;
//   setting_fend: number | InvalidParameterData;
//   setting_sigh: number | InvalidParameterData;
//   setting_adaptive_flow: number | InvalidParameterData;
//   setting_pinsp: number | InvalidParameterData;
//   setting_ps: number | InvalidParameterData;
//   setting_flow_limit: number | InvalidParameterData;
//   setting_exsens: number | InvalidParameterData;
//   setting_exsens_psv: number | InvalidParameterData;
//   setting_th_peep: number | InvalidParameterData;
//   setting_tl_peep: number | InvalidParameterData;
//   setting_peep_h: number | InvalidParameterData;
//   setting_peep_l: number | InvalidParameterData;
//   setting_minvol: number | InvalidParameterData;
//   setting_rr_sens: number | InvalidParameterData;
//   setting_st_type: number | InvalidParameterData;
//   setting_volume_guarantee: VolumeGuarantee | InvalidParameterData;
//   setting_ipap: number | InvalidParameterData;
//   setting_epap: number | InvalidParameterData;
//   setting_backup_apnea_mode: number | InvalidParameterData;
//   setting_backup_vti: number | InvalidParameterData;
//   setting_backup_pinsp: number | InvalidParameterData;
//   setting_backup_rr: number | InvalidParameterData;
//   setting_backup_tinsp: number | InvalidParameterData;
//   setting_backup_trise: number | InvalidParameterData;
//   setting_backup_tpause: number | InvalidParameterData;
//   setting_backup_fend: number | InvalidParameterData;
//   setting_backup_exsens: number | InvalidParameterData;
//   setting_backup_plimit: number | InvalidParameterData;
//   setting_ppause: number | InvalidParameterData;
//   setting_apnea: string | InvalidParameterData;
//   setting_trise_psv: string | InvalidParameterData;
//   setting_flimit: string | InvalidParameterData;
//   setting_power: string | InvalidParameterData;
//   setting_map: string | InvalidParameterData;
//   setting_h_freq: string | InvalidParameterData;
//   setting_imv_etime: string | InvalidParameterData;
//   setting_trigger_mode: string | InvalidParameterData;
//   setting_plimit: string | InvalidParameterData;
//   setting_b_guide: string | InvalidParameterData;
//   setting_b_interval: string | InvalidParameterData;
//   setting_ps_h: string | InvalidParameterData;
//   setting_ps_l: string | InvalidParameterData;
//   setting_patient_weight: string | InvalidParameterData;
//   setting_fpeak: string | InvalidParameterData;
//   setting_hfv_peep: string | InvalidParameterData;
// };

export type TParamKey = keyof Data['params'];
export type TSettingKey = keyof Data['settings'];

export type ScreenInfo = {
  targetScreen: {
    availLeft: number;
    availTop: number;
  };
  windowPosition: {
    screenLeft: number;
    screenTop: number;
  };
  windowSize: {
    width: number;
    height: number;
  };
};
