import { TrendKey, WaveKeys } from '@/pages/CentralMain/types';

export const getParamTrendRange = (param: TrendKey | WaveKeys) => {
  switch (param) {
    case 'hr':
    case 'nibp':
    case 'nibp_d':
    case 'nibp_s':
      return undefined;

    case 'spo2':
      return undefined;

    case 'rr':
      return undefined;

    case 'tmp1':
    case 'tmp2':
      return undefined;

    case 'ibp1':
    case 'ibp2':
    case 'ibp1_s':
    case 'ibp2_s':
      return undefined;

    case 'etco2':
      return undefined;

    case 'ecg_wave_avf':
    case 'ecg_wave_avl':
    case 'ecg_wave_avr':
    case 'ecg_wave_i':
    case 'ecg_wave_ii':
    case 'ecg_wave_iii':
    case 'ecg_wave_v':
      return { min: -50, max: 200 };

    case 'ibp1_wave':
    case 'ibp2_wave':
      return undefined;

    case 'resp_wave':
      return undefined;

    case 'spo2_wave':
      return undefined;

    case 'rox':
      return { min: 0, max: 40 };

    case 'sf_ratio':
      return { min: 0, max: 500 };

    case 'fio2':
      return { min: 0, max: 100 };

    case 'flow':
      // return { min: 0, max: 60 };
      return undefined;

    case 'pi':
      return { min: 0, max: 20 };

    case 'estimated_tidal_volume':
      return { min: 0, max: 2000 };

    case 'peep':
      return { min: 0, max: 100 };

    case 'v_delivery':
      return { min: 0, max: 2500 };

    case 'pip':
      return { min: 0, max: 80 };

    case 'vme':
      return { min: 0, max: 5 };

    case 'pmean':
      return { min: 0, max: 40 };

    case 'cl':
      return { min: 0, max: 20 };

    case 'vte':
      return { min: 0, max: 250 };

    case 'ppeak':
      return { min: 0, max: 40 };

    case 'pr':
      return { min: 0, max: 200 };

    case 'ra':
      return { min: 0, max: 500 };

    default:
      return undefined;
  }
};

export const getParamRangeAdjust = (param: TrendKey | WaveKeys) => {
  switch (param) {
    case 'tmp1':
    case 'tmp2':
      return 0.5;
    case 'rr':
      return 1;
    default:
      return 10;
  }
};

export const clipData = (data: number[], param: TrendKey | WaveKeys) => {
  const range = getParamTrendRange(param);
  if (!range) return data;
  return data.map((item) => {
    if (Number.isNaN(item)) return item;
    if (item < range.min) return item;
    if (item > range.max) return range.max;
    return item;
  });
};
