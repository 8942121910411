/* eslint-disable no-unused-expressions */
/* eslint-disable camelcase */
import { HlcDataSeries, TSciChart, XyDataSeries } from 'scichart';

import { ResTotalTrendDataV2, TTrendData } from '@/pages/CentralMain/organisms/TrendModal/constants';
import { Data } from '@/pages/CentralMain/types';

import { clipData } from './paramConfig';

export const getFormattedTimeValue = (value: string) => {
  const utcTime = value.substring(0, 19).concat('+00:00');
  const time: Date = new Date(utcTime);
  const timestamp = Math.floor(time.getTime());
  return timestamp;
};

// Amy:  ibp 의 경우에는 s,m,d 를 모두 저장해야하기 때문
export function appendXYDataSeries(
  dataSeries: XyDataSeries[],
  data: (TTrendData & { date_time: string })[] | null,
  paramKeyArr: (keyof Data['params'])[],
  from: string,
  to: string,
) {
  const count = paramKeyArr.length;
  const timestamps: number[] = [];
  const paramValues: number[][] = new Array(count).fill(0).map(() => []);

  const gap = 60 * 1000; // 1분

  let prevTime = getFormattedTimeValue(from);
  const toTime = getFormattedTimeValue(to);
  data?.forEach((cur, index) => {
    // Amy: 초는 무조건 0초로 만들고 계산해야한다.(gap 계산 때문에)
    let curDateTime = new Date(getFormattedTimeValue(cur.date_time)).setSeconds(0.0);
    // Amy: 첫번째 index 의 초는 0으로 만들면 안된다.
    if (index === 0) curDateTime = new Date(getFormattedTimeValue(from)).setMilliseconds(0);
    timestamps.push(curDateTime);
    // Amy : 1분이상 비었으면 NaN 을 넣는다.
    while (curDateTime - prevTime > gap) {
      timestamps.push(prevTime + gap);
      paramKeyArr.forEach((_, i) => {
        paramValues[i].push(NaN);
      });
      prevTime += gap;
    }
    //  Todo  Amy : 수정 필요 (type)
    paramKeyArr.forEach((param, i) => {
      const paramValue = cur[param as keyof typeof cur];
      const yValue = paramValue === null ? NaN : Number(paramValue);
      paramValues[i].push(yValue);
    });
    prevTime = curDateTime;
  });

  // Amy: from 부터 to 까지가 1분이 안되어서 딱 하나의 데이터만 있을 경우 Sci chart 버그로 두개를 넣어줘야 한다.
  // Amy: 데이터가 없을 수도 있다. 그럴 경우에도 두 개 넣어줘야함.
  if (getFormattedTimeValue(to) - getFormattedTimeValue(from) <= 60000 && data) {
    if (data.length === 0) {
      timestamps.push(prevTime);
      paramKeyArr.forEach((_, i) => {
        paramValues[i].push(NaN);
      });
    }
    timestamps.push(getFormattedTimeValue(to));
    const onlyOneData = data.at(0);
    paramKeyArr.forEach((param, i) => {
      const paramValue = onlyOneData?.[param as keyof typeof onlyOneData];
      const yValue = !paramValue ? NaN : Number(paramValue);
      paramValues[i].push(yValue);
    });
  }

  // Amy : prevTime 도 안들어갔을 경우 넣어주기
  if (prevTime === getFormattedTimeValue(from)) {
    timestamps.push(prevTime);
    paramKeyArr.forEach((_, i) => {
      paramValues[i].push(NaN);
    });
  }
  // Amy: to 보다 작으면 gap 간격으로 NaN 넣는 부분
  while (prevTime + gap < toTime) {
    const tempTimeStamp = prevTime + gap;
    prevTime = tempTimeStamp;
    timestamps.push(tempTimeStamp);
    paramKeyArr.forEach((_, i) => {
      paramValues[i].push(NaN);
    });
  }
  // Amy: 마지막 timestamp 값은 to 값으로 (wave history 때문)
  timestamps[timestamps.length - 1] = getFormattedTimeValue(to);
  dataSeries.forEach((_, i) => {
    dataSeries[i].appendRange(timestamps, clipData(paramValues[i], paramKeyArr[i]));
  });
}

export function appendXYDataSeriesGroupedVersion(
  dataSeries: XyDataSeries[],
  data: Record<string, ResTotalTrendDataV2['data'] | undefined>,
  paramKeyArr: { deviceId: string; paramKey: keyof Data['params'] }[],
  from: string,
  to: string,
) {
  const timestampsMap: Record<string, number[]> = {}; // 각 device의 timestamps 저장
  const valuesMap: Record<string, Record<string, number[]>> = {}; // {device: { param: 값 배열 }}

  Object.entries(data).forEach(([device, records]) => {
    const timestamps: number[] = [];
    valuesMap[device] = {};

    const gap = 60 * 1000; // 1분

    let prevTime = getFormattedTimeValue(from);
    const toTime = getFormattedTimeValue(to);
    // Amy : 해당 key 의 map 생성
    paramKeyArr.forEach((paramKey) => {
      if (paramKey.deviceId === device) valuesMap[device][paramKey.paramKey] = [];
    });
    records?.forEach((cur, index) => {
      // Amy: 초는 무조건 0초로 만들고 계산해야한다.(gap 계산 때문에)
      let curDateTime = new Date(getFormattedTimeValue(cur.date_time)).setSeconds(0.0);
      // Amy: 첫번째 index 의 초는 0으로 만들면 안된다.
      if (index === 0) curDateTime = new Date(getFormattedTimeValue(from)).setMilliseconds(0);
      timestamps.push(curDateTime);

      // Amy : 1분이상 비었으면 NaN 을 넣는다.
      while (curDateTime - prevTime > gap) {
        timestamps.push(prevTime + gap);
        paramKeyArr.forEach((paramKey) => {
          if (paramKey.deviceId === device) valuesMap[device][paramKey.paramKey].push(NaN);
        });
        prevTime += gap;
      }
      //  Todo  Amy : 수정 필요 (type)
      paramKeyArr.forEach((param) => {
        if (param.deviceId === device) {
          const paramValue = cur[param.paramKey as keyof typeof cur];
          const yValue = paramValue === null ? NaN : Number(paramValue);
          valuesMap[device][param.paramKey].push(yValue);
        }
      });
      prevTime = curDateTime;
    });

    // Amy: from 부터 to 까지가 1분이 안되어서 딱 하나의 데이터만 있을 경우 Sci chart 버그로 두개를 넣어줘야 한다.
    // Amy: 데이터가 없을 수도 있다. 그럴 경우에도 두 개 넣어줘야함.
    if (getFormattedTimeValue(to) - getFormattedTimeValue(from) <= 60000 && records) {
      if (records.length === 0) {
        timestamps.push(prevTime);
        paramKeyArr.forEach((param) => {
          if (param.deviceId === device) valuesMap[device][param.paramKey].push(NaN);
        });
      }
      timestamps.push(getFormattedTimeValue(to));
      const onlyOneData = records.at(0);
      paramKeyArr.forEach((param) => {
        if (param.deviceId === device) {
          const paramValue = onlyOneData?.[param.paramKey as keyof typeof onlyOneData];
          const yValue = !paramValue ? NaN : Number(paramValue);
          valuesMap[device][param.paramKey].push(yValue);
        }
      });
    }

    // Amy : prevTime 도 안들어갔을 경우 넣어주기
    if (prevTime === getFormattedTimeValue(from)) {
      timestamps.push(prevTime);
      paramKeyArr.forEach((param) => {
        if (param.deviceId === device) {
          valuesMap[device][param.paramKey].push(NaN);
        }
      });
    }
    // Amy: to 보다 작으면 gap 간격으로 NaN 넣는 부분
    while (prevTime + gap < toTime) {
      const tempTimeStamp = prevTime + gap;
      prevTime = tempTimeStamp;
      timestamps.push(tempTimeStamp);
      paramKeyArr.forEach((param) => {
        if (param.deviceId === device) {
          valuesMap[device][param.paramKey].push(NaN);
        }
      });
    }
    // Amy: 마지막 timestamp 값은 to 값으로 (wave history 때문)
    timestamps[timestamps.length - 1] = getFormattedTimeValue(to);

    timestampsMap[device] = timestamps;
  });

  paramKeyArr.forEach((param, i) => {
    Object.entries(valuesMap).forEach(([device, paramValues]) => {
      if (param.deviceId === device) {
        // 해당 기기에 param이 있는 경우에만 실행
        dataSeries[i].appendRange(timestampsMap[device], paramValues[param.paramKey]);
      }
    });
  });
}

// Amy: nibp data
export function makeHlcDataSeries(
  data: (TTrendData & { date_time: string })[] | null | undefined,
  wasmContext: TSciChart,
  from: string,
  to: string,
) {
  const paramKeyArr = ['nibp_m', 'nibp_s', 'nibp_d'];
  const count = paramKeyArr.length;
  const timestamps: number[] = [];
  const paramValues: number[][] = new Array(count).fill(0).map(() => []);

  const gap = 60 * 1000; // 1분
  // Todo Amy: 시작시간 수정 필요
  let prevTime = getFormattedTimeValue(data?.at(0)?.date_time || '');
  data?.forEach((cur, index) => {
    // Amy: 초는 무조건 0초로 만들고 계산해야한다.
    let curDateTime = new Date(getFormattedTimeValue(cur.date_time)).setSeconds(0.0);
    // Amy: 첫번째 index 의 초는 0으로 만들면 안된다.
    if (index === 0) curDateTime = new Date(getFormattedTimeValue(from)).setMilliseconds(0);
    // Amy : 1분이상 비었으면 NaN 을 넣는다.
    while (curDateTime - prevTime > gap) {
      timestamps.push(prevTime + gap);
      paramKeyArr.forEach((_, i) => {
        paramValues[i].push(NaN);
      });
      prevTime += gap;
    }
    timestamps.push(curDateTime);
    //  Todo  Amy : 수정 필요 (type)
    paramKeyArr.forEach((param, i) => {
      const paramValue = cur[param as keyof typeof cur];
      const yValue = paramValue === null ? NaN : Number(paramValue);
      paramValues[i].push(yValue);
    });
    prevTime = curDateTime;
  });

  // Amy: data 가 하나일 때 NaN 더 넣어줘야 에러 안남
  if (timestamps.length === 1) {
    paramKeyArr.forEach((param, i) => {
      const yValue = NaN;
      paramValues[i].push(yValue);
    });
    timestamps.push(prevTime + 1);
  }

  // Amy : 마지막 timestamp 는 to 값으로
  timestamps[timestamps.length - 1] = getFormattedTimeValue(to);

  const hlcDataSeries = new HlcDataSeries(wasmContext, {
    xValues: timestamps,
    yValues: paramValues[0],
    highValues: paramValues[1],
    lowValues: paramValues[2],
  });

  const nibpRange = {
    min: Math.min(
      ...paramValues[0].filter((item) => !Number.isNaN(item)),
      ...paramValues[1].filter((item) => !Number.isNaN(item)),
      ...paramValues[2].filter((item) => !Number.isNaN(item)),
    ),
    max: Math.max(
      ...paramValues[0].filter((item) => !Number.isNaN(item)),
      ...paramValues[1].filter((item) => !Number.isNaN(item)),
      ...paramValues[2].filter((item) => !Number.isNaN(item)),
    ),
  };

  return { hlcDataSeries, nibpRange };
}
