import { NumericTickProvider } from 'scichart/Charting/Numerics/TickProviders/NumericTickProvider';

class CustomTickProvider extends NumericTickProvider {
  majorTicks: number[];

  minorTicks: number[];

  constructor(wasmContext: any, majorTicks: number[], minorTicks: number[]) {
    super(wasmContext);
    this.majorTicks = majorTicks;
    this.minorTicks = minorTicks;
  }

  // returns an array of minor gridline positions in data space
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  getMinorTicks(minorDelta: any, majorDelta: any, visibleRange: any) {
    return this.minorTicks;
  }

  // returns an array of major gridline positions in data space
  getMajorTicks(minorDelta: any, majorDelta: any, visibleRange: any) {
    return this.majorTicks;
  }
}

export default CustomTickProvider;
