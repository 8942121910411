import type { ComponentStyleConfig } from '@chakra-ui/theme';

const Button: ComponentStyleConfig = {
  baseStyle: {
    fontWeight: '400',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    cursor: 'pointer',
    borderRadius: 'none',
    bg: '#1E1E1E',
    width: '35px',
    height: '35px',
    boxSizing: 'border-box',
  },
  variants: {
    solid: ({ colorMode }) => ({
      border: '0',
      bg: 'none',
      padding: '0',
      borderRadius: '8px',
      _hover: {
        bg: colorMode === 'dark' ? '#454C53' : 'blackAlpha.300',
        borderRadius: '8px',
      },
      _active: {
        bg: 'none',
        borderRadius: '4px',
        color: '#000000',
      },
      _disabled: {
        color: 'gray.700',
        pointerEvents: 'none',
        cursor: 'not-allowed',
        _hover: {
          bg: '#454C53',
          borderRadius: '8px',
        },
      },
    }),
    invisible: {
      _disabled: {
        visibility: 'hidden',
      },
    },
    backup: {
      bg: 'yellow',
      borderRadius: '3',
      fontWeight: '600',
    },
    color: {
      padding: '0',
      _hover: {},
    },
    selectDate: {
      border: '0',
      bg: 'none',
      padding: '0',
      _hover: {
        bg: 'gray.500',
      },
      _active: {
        bg: '#ffffff',
        color: '#000000',
      },
      _disabled: {
        color: 'gray.700',
        pointerEvents: 'none',
        cursor: 'not-allowed',
        _hover: {
          bg: '#454C53',
        },
      },
    },
    basic: {
      border: '0',
      borderRadius: '0',
      bg: '#73828C',
      color: '#ffffff',
      width: 'max-content',
      height: 'max-content',
      _hover: {
        bg: '#454C53',
      },
      _active: {
        bg: '#ffffff',
        borderRadius: '0',
      },
    },
    header: ({ colorMode }) => ({
      w: 'fit-content',
      color: colorMode === 'dark' ? '#ffffff' : 'black',
      fontFamily: 'SUIT',
      fontSize: '15px',
      fontWeight: '600',
      borderRadius: '5',
      bgColor: colorMode === 'dark' ? '#5c6870' : 'black',
      h: '30px',
      _hover: { backgroundColor: colorMode === 'dark' ? '#5c6870' : 'black' },
      _active: { borderRadius: 'none' },
    }),
    cancel: ({ colorMode }) => ({
      w: '178px',
      color: colorMode === 'dark' ? '#ffffff' : 'black',
      fontFamily: 'SUIT',
      fontSize: '20px',
      fontWeight: '600',
      border: colorMode === 'dark' ? '1px solid #ffffff' : '1px solid black',
      borderRadius: 'none',
      bg: 'none',
      _hover: { borderRadius: 'none' },
      _active: { borderRadius: 'none' },
    }),
  },
};

export default Button;
