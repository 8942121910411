import { TWaves } from '@/constants/types';

import { AlarmLevel, Data } from '../types';

const alarmColors: { [key in AlarmLevel]: { bgColor: string; fontColor: string } } = {
  HIGH: { bgColor: '#FF0000', fontColor: '#FFF' },
  MEDIUM: { bgColor: '#FFFF00', fontColor: '#000' },
  LOW: { bgColor: '#00FFFF', fontColor: '#000' },
  INFO: { bgColor: '#00FFFF', fontColor: '#000' },
  unknown: { bgColor: 'grey', fontColor: 'white' },
  MECHANICAL: { bgColor: 'grey', fontColor: 'white' },
  PHYSICAL: { bgColor: 'grey', fontColor: 'white' },
};

const dataGridBgColor = {
  normal: '#1e1e1e',
  alarm: '#324893',
};

const dataGridBgColorBright = {
  normal: '#3d57cc',
  alarm: '#324893',
};

// Amy: type 은 color setting 에서 어떤 tab 에 표시할지를 결정. 안에 들어있는 param 과는 상관 X
const COLOR_GROUP: {
  [key: string]: {
    label: string;
    params: readonly (keyof Data['params'] | TWaves)[];
    type: 'params' | 'waves' | 'trend' | 'setting';
  };
} = {
  nibp: {
    label: 'NIBP',
    params: ['nibp_s', 'nibp_d', 'nibp_m'],
    type: 'params',
  },
  ibp1: {
    label: 'IBP①',
    params: ['ibp1_d', 'ibp1_m', 'ibp1_s'],
    type: 'params',
  },
  ibp2: {
    label: 'IBP②',
    params: ['ibp2_d', 'ibp2_m', 'ibp2_s'],
    type: 'params',
  },
  ibp3: {
    label: 'IBP③',
    params: ['ibp3_d', 'ibp3_m', 'ibp3_s'],
    type: 'params',
  },
  ibp4: {
    label: 'IBP④',
    params: ['ibp4_d', 'ibp4_m', 'ibp4_s'],
    type: 'params',
  },
  ecg: {
    label: 'ECG',
    params: ['ecg_wave_i', 'ecg_wave_ii', 'ecg_wave_iii', 'ecg_wave_avf', 'ecg_wave_avl', 'ecg_wave_avr', 'ecg_wave_v'],
    type: 'waves',
  },
  temp: {
    label: 'TEMP',
    params: ['tmp1', 'tmp2'],
    type: 'params',
  },
  st: {
    label: 'ST',
    params: ['st_i', 'st_ii', 'st_iii'],
    type: 'params',
  },
} as const;

const colorGroupParams = new Set<string>(Object.values(COLOR_GROUP).flatMap((group) => group.params));

type TColorSetting = {
  [key: keyof Data['params'] | keyof Data['settings'] | TWaves | keyof typeof COLOR_GROUP]: string;
};

export { alarmColors, dataGridBgColor, dataGridBgColorBright, COLOR_GROUP, colorGroupParams };
export type { TColorSetting };
