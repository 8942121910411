import { Bar, BarChart, CartesianGrid, Cell, XAxis, YAxis } from 'recharts';

import { useA4Page } from '@/hooks/usePdf';

import { BpSingleData } from './type';

const CandleStick = ({ x, y, height, width, smd }: any) => {
  const [sys, mean, dias] = smd;
  const targetX = x + width / 2;
  const ratio = height / Math.abs(sys - mean);

  return (
    <g stroke="black" fill="none" strokeWidth="2">
      {/* sys */}
      <path
        d={`
              M ${targetX - 7}, ${y + height}
              H ${targetX + 7}
            `}
      />
      {/* mean */}
      <path
        d={`
              M ${targetX - 5}, ${y}
              H ${targetX + 5}
            `}
      />
      {/* dias */}
      <path
        d={`
              M ${targetX - 7}, ${y - Math.abs(dias - mean) * ratio}
              H ${targetX + 7}
            `}
      />
      <path
        d={`
              M ${targetX},${y + height}
              L ${targetX},${y - Math.abs(dias - mean) * ratio}
            `}
      />
    </g>
  );
};
const NibpChart = ({
  data,
  xDomain,
  yDomain,
  width,
  height,
}: {
  data: BpSingleData[];
  xDomain: number[];
  yDomain: number[];
  width?: number;
  height?: number;
}) => {
  const { convertMmToPx } = useA4Page({});
  return (
    <>
      <BarChart width={width} height={height} margin={{ top: 20, right: 5, left: 0, bottom: 20 }} data={[...data]}>
        <XAxis dataKey="dt" type="number" domain={xDomain} hide />
        <YAxis
          domain={yDomain}
          ticks={yDomain}
          strokeWidth={0}
          fontSize={`${convertMmToPx(3)}px`}
          fontWeight={500}
          // allowDataOverflow
        />
        {/* Min, Max Grid */}
        <CartesianGrid
          strokeWidth="1px"
          stroke="#718096"
          strokeDasharray={`${convertMmToPx(0.5)} ${convertMmToPx(0.5)}`}
          vertical={false}
        />
        <Bar dataKey="smd" fill="#8884d8" shape={<CandleStick dataKey="smd" />} isAnimationActive={false}>
          {data.map((_, index) => (
            <Cell key={`cell-${index.toString()}`} />
          ))}
        </Bar>
      </BarChart>
    </>
  );
};

export default NibpChart;
