import {
  MV2000TherapyMode,
  MV50TherapyMode,
  TherapyMode,
  TMv2000TherapyMode,
  TMv50TherapyMode,
} from '@/pages/CentralMain/types';

import { CUSTOM_PARAMS, PARAMS, SETTING_PARAMS, WAVE_PARAMS } from '../data';
import { TDevice } from '../types';

export const PARAM_LIST: {
  [k in TDevice]: { [key: string]: { label: string; params: (keyof typeof PARAMS | keyof typeof CUSTOM_PARAMS)[] } };
} = {
  MV50: {
    'Spirometry/Timing': {
      label: 'Spirometry/Timing',
      params: [
        'ppeak',
        'vte',
        'peep_h',
        'ti',
        'ppause',
        'vme',
        'peep_l',
        'te',
        'pmean',
        'fi_peak',
        'peep',
        'ie_ratio',
        'vti',
        'fe_peak',
        'flow',
      ],
    },
    'Mech&Spont/Pulmonary': {
      label: 'Mech&Spont/Pulmonary',
      params: ['rr', 'rr_spont', 'vme_s', 'p_0.1', 'rsbi', 'wobv', 'cl', 'ra', 'auto_peep', 'e', 'tc'],
    },
    'FiO2/SpO2/EtCO2': {
      label: 'FiO2/SpO2/EtCO2',
      params: ['fio2', 'sf_ratio', 'rox', 'pr', 'spo2', 'pi', 'etco2', 'ico2', 'etco2_rr', 'vico2', 'veco2', 'vmeco2'],
    },
  },
  MV2000: {
    Spirometry: {
      label: 'Spirometry',
      params: ['ppeak', 'paw', 'pmean', 'vti', 'vte', 'vme', 'fi_peak', 'fe_peak', 'peep_h', 'peep_l', 'peep'],
    },
    'Mech&Spont': {
      label: 'Mech/Spont',
      params: ['rr', 'rr_spont', 'vme_s', 'p_0.1', 'rsbi', 'wobv', 'wobp'],
    },
    timing: {
      label: 'Timing',
      params: ['ti', 'te', 'ie_ratio'],
    },
    pulmonary: {
      label: 'Pulmonary',
      params: ['cl', 'ra', 'auto_peep', 'exp_flow', 'e', 'tc'],
    },
    etco2: {
      label: 'EtCO₂',
      params: ['etco2', 'ico2', 'rr', 'vico2', 'veco2', 'vmeco2'],
    },
    hfov: {
      label: 'HFOV',
      params: ['ve_stroke', 'h_freq', 'amplitude'],
    },
    fio2: {
      label: 'FiO₂',
      params: ['fio2'],
    },
    spo2: {
      label: 'SpO₂',
      params: ['pr', 'spo2'],
    },
  },
  HFT700: {
    params: {
      label: 'Param',
      params: [
        'fio2',
        'spo2',
        'pr',
        'rr',
        'airway_temp',
        'pi',
        'flow',
        'sf_ratio',
        'peep',
        'pip',
        'v_delivery',
        'pa',
        'ptif',
        'rox',
        'estimated_tidal_volume',
        'fpeak',
        'map',
        'spont_resp',
        'leak_high',
        'total_leak_high',
        'mv',
      ],
    },
    tsf_params: {
      label: 'Param',
      params: ['fio2_tsf', 'spo2_tsf', 'fpeak', 'map', 'spont_resp', 'leak_high', 'total_leak_high', 'mv'],
    },
    hfParams: {
      label: 'Param',
      params: [
        'flow',
        'fio2',
        'airway_temp',
        'rr',
        'spo2',
        'pr',
        'pi',
        'rox',
        'sf_ratio',
        'fpeak',
        'map',
        'spont_resp',
        'leak_high',
        'total_leak_high',
        'mv',
      ],
    },
    cpapParams: {
      label: 'Param',
      params: [
        'fio2',
        'peep',
        'pa',
        'rr',
        'spo2',
        'pr',
        'pi',
        'estimated_tidal_volume',
        'fpeak',
        'map',
        'spont_resp',
        'leak_high',
        'total_leak_high',
        'mv',
      ],
    },
    bilevelParams: {
      label: 'Param',
      params: [
        'fio2',
        'pip',
        'peep',
        'rr',
        'spo2',
        'pr',
        'pi',
        'estimated_tidal_volume',
        'fpeak',
        'map',
        'spont_resp',
        'leak_high',
        'total_leak_high',
        'mv',
      ],
    },
    bilevelVParams: {
      label: 'Param',
      params: [
        'fio2',
        'v_delivery',
        'peep',
        'rr',
        'spo2',
        'pr',
        'pi',
        'pip',
        'fpeak',
        'map',
        'spont_resp',
        'leak_high',
        'total_leak_high',
        'mv',
      ],
    },
    hfBiTsfParams: {
      label: 'Param',
      params: [
        'flow_biflow',
        'fio2',
        'fio2_tsf',
        'rr',
        'spo2_tsf',
        'pr',
        'pi',
        'rox',
        'sf_ratio',
        'fpeak',
        'map',
        'spont_resp',
        'leak_high',
        'total_leak_high',
        'mv',
      ],
    },
    hfBiParams: {
      label: 'Param',
      params: [
        'flow_biflow',
        'fio2',
        'airway_temp',
        'rr',
        'spo2',
        'pr',
        'pi',
        'rox',
        'sf_ratio',
        'fpeak',
        'map',
        'spont_resp',
        'leak_high',
        'total_leak_high',
        'mv',
      ],
    },
    hfTsfParams: {
      label: 'Param',
      params: [
        'flow',
        'fio2',
        'fio2_tsf',
        'rr',
        'spo2_tsf',
        'pr',
        'pi',
        'rox',
        'sf_ratio',
        'fpeak',
        'map',
        'spont_resp',
        'leak_high',
        'total_leak_high',
        'mv',
      ],
    },
  },
  MP1300: {
    vitalSigns: { label: 'Vital Signs', params: ['hr', 'temp', 'tmp1', 'tmp2', 'rr', 'spo2', 'pi', 'pr'] },
    nibps: { label: 'NIBP', params: ['nibp', 'nibp_pr', 'nibp_time'] },
    ibps: {
      label: 'IBP',
      params: ['ibp1', 'ibp2', 'ibp3', 'ibp4', 'ibp1_pr', 'ibp2_pr', 'ibp3_pr', 'ibp4_pr'],
    },
    st: { label: 'ST', params: ['st', 'st_i', 'st_ii', 'st_iii'] },
    ico: { label: 'ICO', params: ['ico_bt', 'ico_co', 'ico_paw', 'ico_pr'] },
    anesthesiaAgents: {
      label: 'Anesthesia',
      params: ['ethal', 'fihal', 'etenf', 'fienf', 'etiso', 'fiiso', 'etsev', 'fisev', 'etdes', 'fides'],
    },
    bloodGas: { label: 'Blood Gas', params: ['co2', 'etco2', 'fico2', 'etn2o', 'fin2o'] },
    arriythmiaCardiac: { label: 'Arrhythmia/Cardiac', params: ['pvc'] },
  },
};

export const SETTING_PARAM_LIST: { [k in TDevice]: (keyof typeof SETTING_PARAMS)[] } = {
  MV50: [
    'setting_vti',
    'setting_rr',
    'setting_peep',
    'setting_fio2',
    'setting_pinsp',
    'setting_th_peep',
    'setting_tl_peep',
    'setting_ps',
    'setting_minvol',
    'setting_patient_height',
    'setting_ipap',
    'setting_epap',
  ],
  MV2000: [
    'setting_vti',
    'setting_rr',
    'setting_peep',
    'setting_fio2',
    'setting_pinsp',
    'setting_th_peep',
    'setting_tl_peep',
    'setting_ps',
    'setting_minvol',
    'setting_patient_height',
    'setting_ipap',
    'setting_epap',
  ],
  HFT700: [],
  MP1300: [],
};

export const WAVE_PARAM_LIST: { [k in TDevice]: (keyof typeof WAVE_PARAMS)[] } = {
  MV50: ['pressure_wave', 'flow_wave', 'volume_wave'],
  MV2000: ['pressure_wave', 'flow_wave', 'volume_wave'],
  HFT700: ['ptif'], // 'flow_wave', 'pressure_wave', 'patient_flow_wave',
  MP1300: [
    'ecg_wave_i',
    'ecg_wave_ii',
    'ecg_wave_iii',
    'ecg_wave_avr',
    'ecg_wave_avl',
    'ecg_wave_avf',
    'ecg_wave_v',
    'resp_wave',
    'spo2_wave',
    'ibp1_wave',
    'ibp2_wave',
    'ibp3_wave',
    'ibp4_wave',
    'co2_wave',
  ],
};

export const TREND_PARAM_LIST: { [k in TDevice]: (keyof typeof PARAMS)[] } = {
  MV50: [],
  MV2000: [],
  HFT700: [
    'rox',
    // 'estimated_tidal_volume',
    // 'v_delivery',
    // 'spo2',
    // 'sf_ratio',
    // 'rr',
    // 'pr',
    // 'pip',
    // 'pi',
    // 'peep',
    // 'flow',
    // 'fio2',
  ],
  MP1300: [],
};

// todo amy: custom param 목록 작성

// Todo amy: type 수정
// export type TSelectableParamList = {
//   [model in DeviceModel]: {
//     [therapyMode in TherapyMode<model>]: {
//       simple: model extends 'HFT700'
//         ? (keyof typeof PARAM_LIST.HFT700 | 'settings')[]
//         : (keyof typeof PARAM_LIST.MV50 | 'settings')[];
//       all: model extends 'HFT700'
//         ? (keyof typeof PARAM_LIST.HFT700 | 'settings')[]
//         : (keyof typeof PARAM_LIST.MV50 | 'settings')[];
//       wave: model extends 'HFT700'
//         ? (keyof typeof PARAM_LIST.HFT700 | 'settings' | 'waves')[]
//         : (keyof typeof PARAM_LIST.MV50 | 'settings' | 'waves')[];
//     };
//   };
// };

type TherapyModeObject = {
  simple: string[];
  all: string[];
  wave: string[];
};
export type TSelectableParamList = {
  [model in TDevice]: {
    [therapyMode in TherapyMode<model>]: TherapyModeObject;
  };
};

// Amy: 나중에 모드별로 보여줄 param list가 다를 경우 대비
// Todo : 기기별로 key 값만 가질 수 있도록 type 변경
export const SELECTABLE_PARAM_LIST: TSelectableParamList = {
  HFT700: {
    HF: {
      simple: ['hfParams', 'settings'],
      all: ['hfParams', 'settings'],
      wave: ['hfParams', 'settings', 'waves', 'trend'],
    },
    HF_tsf: {
      simple: ['hfTsfParams', 'settings'],
      all: ['hfTsfParams', 'settings'],
      wave: ['hfTsfParams', 'settings', 'waves', 'trend'],
    },
    CPAP: {
      simple: ['cpapParams', 'settings'],
      all: ['cpapParams', 'settings'],
      wave: ['cpapParams', 'settings', 'waves', 'trend'],
    },
    HF_bi: {
      simple: ['hfBiParams', 'settings'],
      all: ['hfBiParams', 'settings'],
      wave: ['hfBiParams', 'settings', 'waves', 'trend'],
    },
    HF_bi_tsf: {
      simple: ['hfBiTsfParams', 'settings'],
      all: ['hfBiTsfParams', 'settings'],
      wave: ['hfBiTsfParams', 'settings', 'waves', 'trend'],
    },
    'BI-LEVEL_s': {
      simple: ['bilevelVParams', 'settings'],
      all: ['bilevelVParams', 'settings'],
      wave: ['bilevelVParams', 'settings', 'waves', 'trend'],
    },
    'BI-LEVEL_t': {
      simple: ['bilevelVParams', 'settings'],
      all: ['bilevelVParams', 'settings'],
      wave: ['bilevelVParams', 'settings', 'waves', 'trend'],
    },
    'BI-LEVEL_s_t': {
      simple: ['bilevelVParams', 'settings'],
      all: ['bilevelVParams', 'settings'],
      wave: ['bilevelVParams', 'settings', 'waves', 'trend'],
    },
    'BI-LEVEL_s_v': {
      simple: ['bilevelVParams', 'settings'],
      all: ['bilevelVParams', 'settings'],
      wave: ['bilevelVParams', 'settings', 'waves', 'trend'],
    },
    'BI-LEVEL_t_v': {
      simple: ['bilevelVParams', 'settings'],
      all: ['bilevelVParams', 'settings'],
      wave: ['bilevelVParams', 'settings', 'waves', 'trend'],
    },
    'BI-LEVEL_s_t_v': {
      simple: ['bilevelVParams', 'settings'],
      all: ['bilevelVParams', 'settings'],
      wave: ['bilevelVParams', 'settings', 'waves', 'trend'],
    },
  },
  MV50: MV50TherapyMode.reduce((acc, curr) => {
    acc[curr] = {
      simple: ['Spirometry/Timing', 'Mech&Spont/Pulmonary', 'FiO2/SpO2/EtCO2', 'settings'],
      all: ['Spirometry/Timing', 'Mech&Spont/Pulmonary', 'FiO2/SpO2/EtCO2', 'settings'],
      wave: ['Spirometry/Timing', 'Mech&Spont/Pulmonary', 'FiO2/SpO2/EtCO2', 'settings', 'waves'],
    };
    return acc;
  }, {} as Record<TMv50TherapyMode, TherapyModeObject>),
  MV2000: MV2000TherapyMode.reduce((acc, curr) => {
    acc[curr] = {
      simple: ['Spirometry', 'Mech&Spont', 'timing', 'pulmonary', 'etco2', 'hfov', 'fio2', 'spo2', 'settings'],
      all: ['Spirometry', 'Mech&Spont', 'timing', 'pulmonary', 'etco2', 'hfov', 'fio2', 'spo2', 'settings'],
      wave: ['Spirometry', 'Mech&Spont', 'timing', 'pulmonary', 'etco2', 'hfov', 'fio2', 'spo2', 'settings', 'waves'],
    };
    return acc;
  }, {} as Record<TMv2000TherapyMode, TherapyModeObject>),
  MP1300: {
    default: {
      simple: [
        'vitalSigns',
        'st',
        'ibps',
        'nibps',
        'ico',
        'anesthesiaAgents',
        'bloodGas',
        'arriythmiaCardiac',
        'settings',
      ],
      all: [
        'vitalSigns',
        'st',
        'ibps',
        'nibps',
        'ico',
        'anesthesiaAgents',
        'bloodGas',
        'arriythmiaCardiac',
        'settings',
      ],
      wave: [
        'vitalSigns',
        'st',
        'ibps',
        'nibps',
        'ico',
        'anesthesiaAgents',
        'bloodGas',
        'arriythmiaCardiac',
        'settings',
        'waves',
      ],
    },
  },
};
