// Amy: 색에 투명도를 주는 util
export function makeTransparent(color: string, alpha: number): string {
  let thisAlpha = alpha;
  // HEX 형식 (#RRGGBB, #RRGGBBAA)인지 확인
  if (color.startsWith('#')) {
    let r: number;
    let g: number;
    let b: number;

    if (color.length === 7) {
      // #RRGGBB
      r = parseInt(color.slice(1, 3), 16);
      g = parseInt(color.slice(3, 5), 16);
      b = parseInt(color.slice(5, 7), 16);
    } else if (color.length === 9) {
      // #RRGGBBAA
      r = parseInt(color.slice(1, 3), 16);
      g = parseInt(color.slice(3, 5), 16);
      b = parseInt(color.slice(5, 7), 16);
      thisAlpha = parseInt(color.slice(7, 9), 16) / 255; // 기존 알파 값 유지 (0~1 범위)
    } else {
      throw new Error('Invalid HEX color format');
    }

    return `rgba(${r}, ${g}, ${b}, ${thisAlpha})`;
  }

  // RGB 형식 (rgb(R, G, B))인지 확인
  const rgbMatch = color.match(/^rgb\((\d+),\s*(\d+),\s*(\d+)\)$/);
  if (rgbMatch) {
    const [, r, g, b] = rgbMatch.map(Number);
    return `rgba(${r}, ${g}, ${b}, ${thisAlpha})`;
  }

  throw new Error('Invalid color format');
}
