import {
  Data,
  DeviceModel,
  WaveKeys,
  TherapyMode,
  SETTING_DEVICE,
  CustomWaveKeys,
  TTherapyMode,
  ParamKeys,
} from '@/pages/CentralMain/types';

export type TDevice = keyof typeof SETTING_DEVICE;
export type TDisplayMode = 'simple' | 'all' | 'wave';
export type TLayoutKey = `${TDevice}${TDisplayMode}`;

export type TParamType = 'params' | 'settingParams' | 'waves';

export type TWaves = WaveKeys | CustomWaveKeys;
export type TTrend = ParamKeys;

export type TLayout = TSimpleLayout | TMv50AllLayout | TMv50WaveLayout | THft700AllLayout | THft700WaveLayout;

export type TParamProperty = keyof Data['params'];
export type TSettingProperty = keyof Data['settings'];
export const CUSTOM_PARAM_PROPERTIES = {
  spo2_tsf: 'spo2_tsf',
  pa: 'pa',
  fio2_tsf: 'fio2_tsf',
  flow_biflow: 'flow_biflow',
  co2: 'co2',
  nibp: 'nibp',
  ibp1: 'ibp1',
  ibp2: 'ibp2',
  ibp3: 'ibp3',
  ibp4: 'ibp4',
  temp: 'temp',
  st: 'st',
} as const;
export type TCustomParamProperty = keyof typeof CUSTOM_PARAM_PROPERTIES;

export type TLayoutPropertyKey = 'params' | 'settings' | 'waves' | 'custom' | 'trend';
export type TLayoutProperty =
  | { type: 'params'; property: TParamProperty | null }
  | { type: 'settings'; property: TSettingProperty | null }
  | { type: 'waves'; property: TWaves | null }
  | { type: 'custom'; property: TCustomParamProperty | null }
  | { type: 'trend'; property: TParamProperty | null };

// Amy: param X 3
export type TSimpleLayout = [TLayoutProperty, TLayoutProperty, TLayoutProperty];

// Amy: param X 4
export type TMv50SimpleLayout = [TLayoutProperty, TLayoutProperty, TLayoutProperty, TLayoutProperty];

// Amy : param X 8
export type TMv50AllLayout = [
  TLayoutProperty,
  TLayoutProperty,
  TLayoutProperty,
  TLayoutProperty,
  TLayoutProperty,
  TLayoutProperty,
  TLayoutProperty,
  TLayoutProperty,
];

// Amy: param X 8 , wave X 3
export type TMv50WaveLayout = [
  TLayoutProperty,
  TLayoutProperty,
  TLayoutProperty,
  TLayoutProperty,
  TLayoutProperty,
  TLayoutProperty,
  TLayoutProperty,
  TLayoutProperty,
  TLayoutProperty,
  TLayoutProperty,
  TLayoutProperty,
];

// Amy: param X 8
export type THft700AllLayout = [
  TLayoutProperty,
  TLayoutProperty,
  TLayoutProperty,
  TLayoutProperty,
  TLayoutProperty,
  TLayoutProperty,
  TLayoutProperty,
  TLayoutProperty,
];

// Amy : param X 8 , wave X 1
export type THft700WaveLayout = [
  TLayoutProperty,
  TLayoutProperty,
  TLayoutProperty,
  TLayoutProperty,
  TLayoutProperty,
  TLayoutProperty,
  TLayoutProperty,
  TLayoutProperty,
  TLayoutProperty,
];

// Amy : param X 3
export type TMPSimpleLayout = [TLayoutProperty, TLayoutProperty, TLayoutProperty];

// Amy :param X 9
export type TMPAllLayout = [
  TLayoutProperty,
  TLayoutProperty,
  TLayoutProperty,
  TLayoutProperty,
  TLayoutProperty,
  TLayoutProperty,
  TLayoutProperty,
  TLayoutProperty,
  TLayoutProperty,
];

// Amy : wave X 3, param X 9
export type TMPWaveLayout = [
  TLayoutProperty,
  TLayoutProperty,
  TLayoutProperty,
  TLayoutProperty,
  TLayoutProperty,
  TLayoutProperty,
  TLayoutProperty,
  TLayoutProperty,
  TLayoutProperty,
  TLayoutProperty,
  TLayoutProperty,
  TLayoutProperty,
];

// Amy: param X 10
export type TMv2000ZoomLayout = [
  TLayoutProperty,
  TLayoutProperty,
  TLayoutProperty,
  TLayoutProperty,
  TLayoutProperty,
  TLayoutProperty,
  TLayoutProperty,
  TLayoutProperty,
  TLayoutProperty,
  TLayoutProperty,
];

type THft700Layout = {
  simple: TSimpleLayout;
  all: THft700AllLayout;
  wave: THft700WaveLayout;
};
type Mv50Layout = {
  simple: TMv50SimpleLayout;
  all: TMv50AllLayout;
  wave: TMv50WaveLayout;
};
type Mv2000Layout = Mv50Layout & { zoom: TMv2000ZoomLayout };

type DefaultLayout = {
  simple: TSimpleLayout;
  all: unknown;
  wave: unknown;
};
type MP1300Layout = {
  simple: TMPSimpleLayout;
  all: TMPAllLayout;
  wave: TMPWaveLayout;
};

export type Layout<D extends DeviceModel> = D extends 'HFT700'
  ? THft700Layout
  : D extends 'HFT750'
  ? THft700Layout
  : D extends 'MV50'
  ? Mv50Layout
  : D extends 'MV2000'
  ? Mv2000Layout
  : D extends 'MP1300'
  ? MP1300Layout
  : DefaultLayout;

export type TParamSetting = {
  [model in TDevice]: {
    [therapyMode in TherapyMode<model>]: Layout<model>;
  };
};

export type TDisplayParam = {
  label: string;
  unit: string;
  min?: {
    min: string;
    max: string;
    off?: { [model in DeviceModel]?: string } & { default: string };
    minCustomString?: string;
    maxCustomString?: string;
    // Amy: Pip이 특정 모드에서 min 이 disable 이기 때문
    disabled?: readonly TTherapyMode[];
  };
  max?: {
    min: string;
    max: string;
    off?: { [model in DeviceModel]?: string } & { default: string };
    minCustomString?: string;
    maxCustomString?: string;
  };
  //  Amy: 조절하는 값
  customDelta?: number;
  settingParam?: keyof Data['settings'];
  // Amy: therapy mode 에 따라서 setting param 달라짐
  therapySettingParam?: {
    [model in TDevice]?: {
      [therapyMode in TherapyMode<model>]?: {
        setting: keyof Data['settings'];
        conditionSetting?: keyof Data['settings'];
      };
    };
  };
  // Amy: therapy mode 에 따라서 min, max 달라짐
  therapyMinMax?: {
    [therapyMode in TTherapyMode]?: {
      conditionSetting?: keyof Data['settings'];
      min?: {
        min: string;
        max: string;
        minCustomString?: string;
        maxCustomString?: string;
      };
      max?: {
        min: string;
        max: string;
        minCustomString?: string;
        maxCustomString?: string;
      };
    };
  };
  maxTextCount?: number; // 최대 표시 텍스트의 갯수
  decimalPlaces?: number; // 소숫점자리 formatting
  valueDomain: { min: number; max: number }; // trend report의 y domain을 표시하는데에 사용
};
export type TRealtimeWaveDisplayParam = {
  samplingRate: number;
  baseline: number;
  yMin: number;
  yMax: number;
  setParam: keyof Data['params'] | keyof Data['settings'];
};
// Amy : customValue 의 경우 min 부터 max 까지의 value 를 하나씩 mapping, onOff의 경우도 마찬가지
export type TSettingParam = {
  label: string;
  unit: string;
  min: string;
  max: string;
  offValue?: string;
  customValue?: string[];
  onOffValue?: string[];
  minCustomValue?: string;
  maxCustomValue?: string;
  // Amy: PA(-) 는 EPAP 과의 합이 4보다 작거나 같아야 하기 때문
  minCondition?: { [therapyMode in TTherapyMode]?: { param: keyof Data['settings']; sumValue: number } };
  targetProperty?: { [key in DeviceModel]?: TSettingProperty };
  customLabel?: (model?: DeviceModel, toggle?: boolean) => void;
  maxTextCount?: number; // 최대 표시 텍스트의 갯수
  decimalPlaces?: number; // 소숫점자리 formatting
};

export type TModeSettingParams = {
  [model in TDevice]: {
    [therapyMode in TherapyMode<model>]: (keyof Data['settings'])[];
  };
};

export type SettingParamDuo = {
  label: string;
  left: keyof Data['settings'];
  right: keyof Data['settings'];
};
export type TModeSettingParamsDuo = {
  [model in TDevice]: {
    [therapyMode in TherapyMode<model>]: SettingParamDuo[];
  };
};

export type TModeParamAlarmSetting = {
  [model in TDevice]: {
    [therapyMode in TherapyMode<model>]: (keyof Data['params'])[];
  };
};

export type TModeAlarmSetting = {
  [model in TDevice]: {
    [therapyMode in TherapyMode<model>]: (keyof Data['settings'])[];
  };
};
