import { addHours, format } from 'date-fns';
import {
  DateTimeNumericAxis,
  EAutoRange,
  EAxisAlignment,
  ELabelPlacement,
  ENumericFormat,
  HorizontalLineAnnotation,
  NumberRange,
  NumericAxis,
  SmartDateLabelProvider,
  TSciChart,
  VerticalLineAnnotation,
} from 'scichart';
import { appTheme } from 'scichart-example-dependencies';

import { TTrend } from '@/constants/types';

export const AXIS_BORDER_COLOR = '#676767';
// setting
export const COLORS = {
  VividRed: '#C52E60',
  MutedRed: '#DB7969',
  MutedBlue: '#537ABD',
  ForegroundColor: '#FFFFFF',
  GripHandleColor: 'blue',
  OverViewBorderColor: '#FFFFFF',
  Yellow: '#e3eb07',
  Black: '#000000',
};
export const SIZE = {
  title: 12,
};
export const LINE_COLOR = { normal: appTheme.ForegroundColor, underThreshold: COLORS.MutedRed };
const THRESHOLD = 4.88;
const THRESHOLD2 = 6.0;

const visibleRange: { [trendParam in TTrend]?: { min: number; max: number } } = {
  rox: {
    min: 0,
    max: 20,
  },
  estimated_tidal_volume: {
    min: 0,
    max: 2000,
  },
  v_delivery: {
    min: 0,
    max: 2500,
  },
  spo2: {
    min: 60,
    max: 100,
  },
  sf_ratio: {
    min: 0,
    max: 500,
  },
  rr: {
    min: 0,
    max: 40,
  },
  pr: {
    min: 0,
    max: 240,
  },
  pip: {
    min: 0,
    max: 45,
  },
  pi: {
    min: 0,
    max: 20,
  },
  peep: {
    min: 0,
    max: 24,
  },
  flow: {
    min: 0,
    max: 60,
  },
  fio2: {
    min: 0,
    max: 100,
  },
};

export const XAxis = (wasmContext: TSciChart, xVisibleRange: NumberRange) =>
  new DateTimeNumericAxis(wasmContext, {
    id: 'x',
    axisAlignment: EAxisAlignment.Bottom,
    visibleRange: xVisibleRange,
    drawMajorGridLines: true,
    drawMajorBands: false,
    drawMinorGridLines: false,
    labelProvider: new SmartDateLabelProvider({ cursorLabelFormat: ENumericFormat.Date_DDMMHHMM }),
    majorDelta: 1.0 / 24.0,
    autoRange: EAutoRange.Never,
  });

export const yAxis = (wasmContext: TSciChart, param: TTrend) =>
  new NumericAxis(wasmContext, {
    id: param,
    axisAlignment: EAxisAlignment.Left,
    labelFormat: ENumericFormat.Decimal,
    labelPrecision: 2,
    autoTicks: true,
    maxAutoTicks: 5,
    // majorDelta: 10,
    drawMajorGridLines: true,
    drawMajorBands: false,
    drawMinorGridLines: false,
    axisBorder: { color: AXIS_BORDER_COLOR, borderRight: 1 },
    visibleRange: new NumberRange(visibleRange[param]?.min || 0, visibleRange[param]?.max || 100),
    autoRange: EAutoRange.Never,
  });

export const roxThreshold = ({ xAxisId, xValues }: { xAxisId: string; xValues: number[] }) => {
  // Amy : 4.88 line
  const horizontalLine = new HorizontalLineAnnotation({
    xAxisId,
    yAxisId: 'rox',
    y1: THRESHOLD,
    stroke: COLORS.VividRed,
    showLabel: true,
    strokeThickness: 2,
    axisLabelFill: COLORS.VividRed,
    axisLabelStroke: COLORS.ForegroundColor,
    labelPlacement: ELabelPlacement.Axis,
    strokeDashArray: [5, 5],
  });
  // Amy : 6.0 line
  const horizontalLine2 = new HorizontalLineAnnotation({
    xAxisId,
    yAxisId: 'rox',
    y1: THRESHOLD2,
    stroke: COLORS.Yellow,
    showLabel: true,
    strokeThickness: 2,
    axisLabelFill: COLORS.Yellow,
    axisLabelStroke: COLORS.Black,
    labelPlacement: ELabelPlacement.Axis,
    strokeDashArray: [5, 5],
  });

  // Amy: 2, 6, 12 hours line
  const verticalLine = (hour: number) =>
    new VerticalLineAnnotation({
      x1: addHours(new Date((xValues.at(0) || 0) * 1000), hour).getTime() / 1000,
      labelValue: `${hour}h`,
      // Amy: 전체 값에 고정하도록 수정
      xAxisId,
      yAxisId: 'rox',
      showLabel: true,
      stroke: COLORS.MutedBlue,
      strokeThickness: 2,
      axisLabelFill: COLORS.MutedBlue,
      axisLabelStroke: COLORS.ForegroundColor,
      labelPlacement: ELabelPlacement.Axis,
      strokeDashArray: [3, 3],
    });
  return { horizontalLine, horizontalLine2, verticalLine };
};

export const FormatLabel = (dataValue: number) => {
  const date = new Date(dataValue * 1000);
  if (date.getHours() === 0 && date.getMinutes() === 0) {
    return format(date, 'MM-dd');
  }
  return format(date, 'HH:mm');
};
