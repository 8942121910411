import React from 'react';

import { ChakraProvider, ColorModeScript } from '@chakra-ui/react';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import ko from 'date-fns/locale/ko';
import { registerLocale } from 'react-datepicker';
import { useRoutes } from 'react-router-dom';

import { AxiosInterceptor } from '@/apis/AxiosInterceptor';
import { LCHost } from '@/contexts/lightning-chart';
import MqttConnectionProvider from '@/contexts/MqttConnectionProvider';
import { OverlayProvider } from '@/contexts/overlay';
import QueryProvider from '@/contexts/react-query/QueryProvider';
import routeInfo from '@/router/router';
import theme from '@/theme';

import { ColorProvider } from './contexts/ColorProvider';
import { WindowProvider } from './contexts/dualMonitorProvider';

import 'react-datepicker/dist/react-datepicker.css';

const ReactQueryDevtoolsProduction = React.lazy(() =>
  import('@tanstack/react-query-devtools/build/modern/production.js').then((d) => ({
    default: d.ReactQueryDevtools,
  })),
);

registerLocale('ko', ko);
function App() {
  const RouteElement = useRoutes(routeInfo);
  const [showDevtools, setShowDevtools] = React.useState(false);

  React.useEffect(() => {
    window.toggleDevtools = () => setShowDevtools((old) => !old);
  }, []);

  return (
    <ChakraProvider theme={theme}>
      <ColorModeScript initialColorMode={theme.config.initialColorMode} />
      <ColorProvider>
        <QueryProvider>
          <OverlayProvider>
            <MqttConnectionProvider>
              <WindowProvider>
                <AxiosInterceptor>
                  <LCHost>{RouteElement}</LCHost>
                </AxiosInterceptor>
              </WindowProvider>
            </MqttConnectionProvider>
            {process.env.REACT_APP_TANSTACK_QUERY_DEV_TOOL === 'true' && <ReactQueryDevtools initialIsOpen={false} />}
            {showDevtools && (
              <React.Suspense fallback={null}>
                <ReactQueryDevtoolsProduction />
              </React.Suspense>
            )}
          </OverlayProvider>
        </QueryProvider>
      </ColorProvider>
    </ChakraProvider>
  );
}

export default App;
