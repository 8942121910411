/* eslint-disable camelcase */
import { memo } from 'react';

import { TableContainer, Table, Th, Tr, Tbody, Td, Thead } from '@chakra-ui/react';

import { useA4Page } from '@/hooks/usePdf';
import { TTrendData } from '@/pages/CentralMain/organisms/TrendModal/constants';
import { TrendFormatRule } from '@/pages/CentralMain/organisms/TrendModal/utils/trend-table-formatter';
import { timeFormat } from '@/pages/CentralMain/utils/convertAdmissionTime';
import { parseUTC0String } from '@/utils/dateTimeUtils';

interface Props {
  data: (TTrendData & { date_time: string })[];
  tableFormatRules: TrendFormatRule[];
}

const TrendTable = ({ data, tableFormatRules }: Props) => {
  const { convertMmToPx } = useA4Page({});

  return (
    <TableContainer
      w={`${convertMmToPx(175)}px`}
      h={`${convertMmToPx(40)}px`}
      mt={`${convertMmToPx(10)}px`}
      fontSize={`${convertMmToPx(3)}px`}
    >
      <Table variant="report">
        <Thead>
          <Tr>
            <Th>{/* Time */}</Th>
            {tableFormatRules.map((column, index) => {
              return (
                <Th key={`header_${index.toString()}`} textAlign="center">
                  {column.label}
                </Th>
              );
            })}
          </Tr>
        </Thead>
        <Tbody>
          {data.map((row) => {
            return (
              <Tr key={`${row.date_time}`}>
                <Td>{timeFormat(parseUTC0String(row.date_time))}</Td>
                {tableFormatRules.map((column, index) => {
                  return (
                    <Td key={`data_${index.toString()}`} isNumeric>
                      {/* Todo : 수정 필요 */}
                      {column.formatter(row, 'temp').value}
                    </Td>
                  );
                })}
              </Tr>
            );
          })}
        </Tbody>
      </Table>
    </TableContainer>
  );
};

export default memo(TrendTable);
