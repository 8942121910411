import { LineChart, XAxis, YAxis, Line, CartesianGrid } from 'recharts';

import { useA4Page } from '@/hooks/usePdf';

import { MultiChartSingleData } from './type';

const IbpChart = ({
  data,
  xDomain,
  yDomain,
  width,
  height,
}: {
  data: MultiChartSingleData[];
  xDomain: number[];
  yDomain: number[];
  width?: number;
  height?: number;
}) => {
  const { convertMmToPx } = useA4Page({});
  return (
    <>
      <LineChart width={width} height={height} margin={{ top: 20, right: 5, left: 0, bottom: 20 }} data={data || []}>
        {/* Axis */}
        <XAxis dataKey="x" type="number" hide domain={xDomain} />
        <YAxis
          domain={yDomain}
          ticks={yDomain}
          strokeWidth={0}
          fontSize={`${convertMmToPx(2.5)}px`}
          fontWeight={400}
          // allowDataOverflow
        />
        {/* Min, Max Grid */}
        <CartesianGrid
          strokeWidth="1px"
          stroke="#718096"
          strokeDasharray={`${convertMmToPx(0.5)} ${convertMmToPx(0.5)}`}
          vertical={false}
        />
        {/* Line */}
        <Line type="linear" dataKey="y1" stroke="#000" dot={false} isAnimationActive={false} strokeWidth={1} />
        <Line
          type="linear"
          dataKey="y2"
          stroke="#000"
          dot={false}
          isAnimationActive={false}
          // strokeDasharray={`${convertMmToPx(2)}`}
          // strokeWidth={2}
        />
        <Line
          type="linear"
          dataKey="y3"
          stroke="#000"
          dot={false}
          isAnimationActive={false}
          // strokeDasharray={`${convertMmToPx(0.5)} ${convertMmToPx(0.5)}`}
          // strokeWidth={2}
        />
      </LineChart>
    </>
  );
};
export default IbpChart;
