import { useMemo } from 'react';

import { useNibpTrendByPeriod, useTrendAllByPeriod } from '@/apis/patient/queries';
import { MP_DEVICES } from '@/pages/CentralMain/organisms/UnifiedZoomSettingModal/constants';
import { parseDataId } from '@/pages/CentralMain/utils/dataKeyUtils';
import { sliceUtc0StringToMinute } from '@/utils/dateTimeUtils';

export const useTrendData = (dataId: string, from: Date, to: Date) => {
  const { model } = parseDataId(dataId);
  const needNibp = (MP_DEVICES as string[]).includes(model);

  const { data: allParamData, isLoading } = useTrendAllByPeriod(dataId, from, to, dataId.length !== 0);
  const { data: nibpData, isLoading: isNibpLoading } = useNibpTrendByPeriod(
    dataId,
    from,
    to,
    dataId.length !== 0 && needNibp,
  );

  const deviceData = useMemo(() => {
    return (
      allParamData?.data?.map((data) => {
        const nibp = nibpData?.data
          .filter((nibp) => sliceUtc0StringToMinute(nibp.date_time) === sliceUtc0StringToMinute(data.date_time))
          .at(-1);
        if (nibp) {
          return { ...data, nibp_d: nibp.nibp_d, nibp_m: nibp.nibp_m, nibp_s: nibp.nibp_s };
        }
        return { ...data, nibp_d: null, nibp_m: null, nibp_s: null };
      }) || []
    );
  }, [allParamData?.data, nibpData?.data]);

  return { deviceData, isLoading: isLoading || isNibpLoading };
};
