import { useEffect, useId, useMemo, useRef } from 'react';

import { Box, Text, CircularProgress } from '@chakra-ui/react';
import { addMinutes, differenceInMinutes } from 'date-fns';

import useCustomColorMode from '@/hooks/useCustomColorMode';
import useLanguage from '@/hooks/useLanguage';
import { usePdf } from '@/hooks/usePdf';
import { DEVICE_MODEL } from '@/pages/CentralMain/types';
import { parseDataId } from '@/pages/CentralMain/utils/dataKeyUtils';
import { HeaderData } from '@/reports/common/ReportHeader';
import TrendChartWithTablePage, { DATA_PER_PAGE } from '@/reports/pages/TrendChartWithTablePage';
import { filenameGenerator } from '@/reports/utils/filename';

import { TrendReportStoreContext, createTrendReportStore, useTrendReportStoreContext } from './useTrendReportStore';
import Indicator from '../common/Indicator';

interface Props {
  dataId: string;
  from: Date;
  to: Date;
  headerData: HeaderData;
  pageCount: number;
  targetParamKeys: string[];
}

const TrendReport = ({ dataId, from, to, headerData, pageCount, targetParamKeys }: Props) => {
  const { translate } = useLanguage();
  const { color } = useCustomColorMode();
  const pageId = useId();

  const { openFileInNewWindow, isProcessing, isComplete } = usePdf(
    Array.from({ length: pageCount }, (_, index) => `${pageId}-${index.toString()}`),
    { filename: filenameGenerator({ prefix: 'trend_report' }) },
  );

  const isDataProcessed = useTrendReportStoreContext((state) => state.isDataProcessed);
  useEffect(() => {
    let timer: NodeJS.Timeout;
    if (isDataProcessed.every((value) => value === true) && !isProcessing && !isComplete) {
      timer = setTimeout(openFileInNewWindow, 0);
    }
    if (isComplete) {
      window.close();
    }
    return () => {
      clearTimeout(timer);
    };
  }, [isComplete, isDataProcessed, isProcessing, openFileInNewWindow]);

  const { chartParamKeys, tableParamKeys } = useMemo(() => {
    const { model } = parseDataId(dataId);
    let chartParamKeys;
    let tableParamKeys;
    switch (model) {
      case DEVICE_MODEL.MP1000NTP:
      case DEVICE_MODEL.MP1300:
      case DEVICE_MODEL.MP800:
      case DEVICE_MODEL.BLTM10:
        chartParamKeys = ['hr', 'spo2', 'rr', 'nibp', 'tmp1'];
        tableParamKeys = ['hr', 'spo2', 'nibp', 'rr', 'tmp', 'ibp1', 'ibp2', 'st', 'etco2', 'fico2'];
        break;
      default:
        chartParamKeys = targetParamKeys;
        tableParamKeys = targetParamKeys;
        break;
    }

    return { chartParamKeys, tableParamKeys };
  }, [dataId, targetParamKeys]);

  return (
    <>
      <Indicator>
        <Text color={color} fontWeight={600}>
          {translate('processPdf')}
        </Text>
        <CircularProgress isIndeterminate color="blue.300" size="100px" />
      </Indicator>
      <Box>
        {Array.from({ length: pageCount }, (_, index) => {
          const startDate = addMinutes(from, index * DATA_PER_PAGE);
          const endDate = addMinutes(from, (index + 1) * DATA_PER_PAGE);
          return (
            <TrendChartWithTablePage
              key={`${pageId}-${index.toString()}`}
              pageId={`${pageId}-${index.toString()}`}
              id={dataId}
              from={startDate}
              page={index + 1}
              to={endDate.getTime() > to.getTime() ? to : endDate}
              headerData={headerData}
              chartParamKeys={chartParamKeys}
              tableParamKeys={tableParamKeys}
            />
          );
        })}
      </Box>
    </>
  );
};

const Wrapper = ({ from, to, ...props }: Omit<Props, 'pageCount'>) => {
  const pageCount = useMemo(() => {
    const result = differenceInMinutes(to, from);
    return Math.ceil(result / DATA_PER_PAGE);
  }, [from, to]);

  const store = useRef(
    createTrendReportStore({ pageCount, isDataProcessed: Array.from({ length: pageCount }, () => false) }),
  ).current;

  return (
    <TrendReportStoreContext.Provider value={store}>
      <TrendReport from={from} to={to} pageCount={pageCount} {...props} />
    </TrendReportStoreContext.Provider>
  );
};

export default Wrapper;
