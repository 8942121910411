import { useMemo } from 'react';

import { Box } from '@chakra-ui/react';
import { addMinutes } from 'date-fns';

import { useA4Page } from '@/hooks/usePdf';
import { useTrendData } from '@/hooks/useTrendData';
import usePeriodFilledData from '@/pages/CentralMain/hooks/usePeriodFilledData';
import {
  TrendFormatRule,
  defaultTrendFormatRule,
  getTrendFormatRuleListByDeviceModel,
} from '@/pages/CentralMain/organisms/TrendModal/utils/trend-table-formatter';
import { DeviceModel, ParamKeys } from '@/pages/CentralMain/types';
import { parseDataId } from '@/pages/CentralMain/utils/dataKeyUtils';
import A4Container from '@/reports/container/A4Container';

import ReportHeader, { HeaderData } from '../common/ReportHeader';
import DynamicTrend from '../trend/DynamicTrend';
import TableInTrendPage from '../trend-table/TableInTrendPage';

// trend report: 15min data per page
export const DATA_PER_PAGE = 15;

const TrendTable = ({
  id,
  from,
  to,
  targetParamKeys,
}: {
  id: string;
  from: Date;
  to: Date;
  targetParamKeys: string[];
}) => {
  const { model } = parseDataId(id);
  const tableFormatRules: TrendFormatRule[] = useMemo(() => {
    const deviceModel = model as DeviceModel;
    return targetParamKeys.map(
      (key) =>
        getTrendFormatRuleListByDeviceModel(deviceModel).find((rule) => rule.property === key) ||
        defaultTrendFormatRule(key as ParamKeys),
    );
  }, [model, targetParamKeys]);

  const { deviceData } = useTrendData(id, from, addMinutes(to, 1));
  const { filledData } = usePeriodFilledData(deviceData, 'date_time', { from, to });

  return <TableInTrendPage data={filledData.slice(0, DATA_PER_PAGE + 1)} tableFormatRules={tableFormatRules} />;
};

interface Props {
  id: string;
  pageId: string;
  from: Date;
  to: Date;
  page?: number;
  headerData: HeaderData;
  chartParamKeys: string[];
  tableParamKeys: string[];
}
const TrendChartWithTablePage = ({ id, pageId, from, to, page, headerData, chartParamKeys, tableParamKeys }: Props) => {
  const { convertMmToPx } = useA4Page({});

  return (
    <A4Container page={page} pageId={pageId}>
      {/* Header */}
      <Box w="full" px={`${convertMmToPx(10)}px`}>
        <ReportHeader headerData={headerData} />
      </Box>
      {/* Trend chart */}
      <DynamicTrend id={id} from={from} to={to} targetParamKeys={chartParamKeys} page={page} />
      {/* Table */}
      <Box h={`${convertMmToPx(120)}px`}>
        <TrendTable id={id} from={from} to={to} targetParamKeys={tableParamKeys} />
      </Box>
    </A4Container>
  );
};

export default TrendChartWithTablePage;
