import type { ComponentStyleConfig } from '@chakra-ui/theme';

const Tooltip: ComponentStyleConfig = {
  baseStyle: ({ colorMode }) => ({
    bgColor: colorMode === 'dark' ? 'blackAlpha.900' : 'white',
    fontSize: 'sm',
    color: colorMode === 'dark' ? 'white' : 'black',
  }),
  variants: {
    alert: {
      bgColor: 'orange.500',
      maxW: '300px',
    },
    alarmSetting: {
      bgColor: 'gray.300',
      fontSize: 'sm',
      color: 'black',
    },
  },
};

export default Tooltip;
