import { LineChart, XAxis, YAxis, Line, CartesianGrid } from 'recharts';

import { useA4Page } from '@/hooks/usePdf';

import { SingleChartSingleData } from './type';

const SingleChart = ({
  data,
  xDomain,
  yDomain,
  width,
  height,
}: {
  data: SingleChartSingleData[];
  xDomain: number[];
  yDomain: number[];
  width?: number;
  height?: number;
}) => {
  const { convertMmToPx } = useA4Page({});
  return (
    <>
      <LineChart width={width} height={height} margin={{ top: 20, right: 5, left: 0, bottom: 20 }} data={data || []}>
        {/* Axis */}
        <XAxis dataKey="x" type="number" hide domain={xDomain} />
        <YAxis
          dataKey="y"
          domain={yDomain}
          ticks={yDomain}
          strokeWidth={0}
          fontSize={`${convertMmToPx(2.5)}px`}
          fontWeight={400}
          // allowDataOverflow
        />
        {/* Min, Max Grid */}
        <CartesianGrid
          strokeWidth="1px"
          stroke="#718096"
          strokeDasharray={`${convertMmToPx(0.5)} ${convertMmToPx(0.5)}`}
          vertical={false}
        />
        {/* Line */}
        <Line type="linear" dataKey="y" stroke="#000" dot={false} isAnimationActive={false} />
      </LineChart>
    </>
  );
};
export default SingleChart;
