import { useEffect, useMemo, useRef, useState } from 'react';

import { useQuery, useQueries, UseQueryOptions, useMutation, useQueryClient } from '@tanstack/react-query';
import { addSeconds } from 'date-fns';
import { shallow } from 'zustand/shallow';

import { TTrend } from '@/constants/types';
import { useMockDataStore } from '@/contexts/mockDataStore';
import { mqttKeys, useMqttConnection } from '@/contexts/MqttConnectionProvider';
import env from '@/env';
import { postPatCurrListHandler } from '@/mock/apis';
import { useMockPatientList } from '@/mock/apis/patient';
import { devices } from '@/mock/data';
import { ResTotalTrendDataV2, ResWaveOverview } from '@/pages/CentralMain/organisms/TrendModal/constants';
import { Alarm, Data, DeviceModel } from '@/pages/CentralMain/types';
import { makeLocalTimeToUTC0 } from '@/pages/CentralMain/utils/convertAdmissionTime';
import { parseDataId } from '@/pages/CentralMain/utils/dataKeyUtils';
import { ceilSeconds, floorSeconds, parseUTC0String } from '@/utils/dateTimeUtils';

import {
  ReqAdmitPatient,
  ReqAllParamsTrend,
  ReqConnectionInfos,
  ReqDeviceConnectionList,
  ReqDeviceUpdate,
  ReqDischargePatient,
  ReqPatientInfo,
  ReqPatientList,
  ReqTrendData,
  ResDeviceConnectionList,
  ResDeviceData,
  ResLastDeviceInfo,
  ResNibpTrend,
  ResPatientDevicePairList,
  ResPatientInfo,
  ResPatientList,
  ResRoxTrendData,
  ResTrendData,
  ResWaveDetail,
} from './type';

import {
  getAllParamTrendV2,
  getLastDeviceInfo,
  getNibpTrend,
  getPatientDevicePairList,
  getRoxTrend,
  getTrend,
  getWaveDetail,
  getWaveOverview,
  patientApis,
} from './index';

// Amy: 분단위로 계산

const generateDeviceDataQuery = <TData = ResDeviceData>(
  deviceId: string,
  options?: Pick<DeviceDataQueryOption<TData>, 'select'>,
): DeviceDataQueryOption<TData> => {
  if (env.REACT_APP_MODE === 'demo') {
    return {
      queryKey: mqttKeys.detail(deviceId),
      queryFn: () => {
        const targetMockData = devices.find((device) => device.getKey() === deviceId);
        return targetMockData ? targetMockData.getPdpData() : undefined;
      },
      refetchInterval: 1000,
      ...options,
    };
  }
  const cacheTime = Number.isNaN(Number(env.REACT_APP_MQTT_CACHE_TIME))
    ? 5 * 1000 // default 5 seconds
    : Number(env.REACT_APP_MQTT_CACHE_TIME);

  return {
    queryKey: mqttKeys.detail(deviceId),
    queryFn: () => {
      return new Promise<ResDeviceData>((resolve) => {
        const data = JSON.parse('null');
        resolve(data);
      });
    },
    staleTime: cacheTime,
    cacheTime,
    ...options,
  };
};

type DeviceDataQueryOption<TData> = UseQueryOptions<ResDeviceData | undefined, unknown, TData>;
export const useDeviceDataDetail = <TData = ResDeviceData>(
  deviceId: string,
  options?: Pick<DeviceDataQueryOption<TData>, 'select'>,
) => {
  const queryOption = useMemo(() => generateDeviceDataQuery(deviceId, options), [deviceId, options]);
  const query = useQuery(queryOption);
  return query;
};

export const useNullableDeviceDataDetail = <TData = ResDeviceData>(
  deviceId?: string,
  options?: Pick<DeviceDataQueryOption<TData>, 'select'>,
) => {
  const queryOption = deviceId
    ? generateDeviceDataQuery(deviceId, options)
    : {
        queryKey: ['none'],
        queryFn: () => {
          return undefined;
        },
      };
  const query = useQuery(queryOption);
  return query;
};

const useDeviceDataList = <TData = ResDeviceData>(
  deviceIds: string[],
  options?: Pick<DeviceDataQueryOption<TData>, 'select'>,
) => {
  const queries = deviceIds.map((deviceId) => generateDeviceDataQuery(deviceId, options));
  return useQueries({
    queries,
  });
};

const convertAlarmLevelToNumber = (alarm: Alarm) => {
  switch (alarm?.alarm_level) {
    case 'HIGH':
      return 3;
    case 'MEDIUM':
      return 2;
    case 'LOW':
      return 1;
    default:
      return 0;
  }
};

export const useDeviceHighestAlarmData = (deviceIds: string[]) => {
  const queryResults = useDeviceDataList(deviceIds, {
    select: (data) => {
      const alarms = data?.alarms;
      if (!alarms || alarms.length === 0) {
        return 0;
      }
      const highestAlarm = Math.max(...alarms.map(convertAlarmLevelToNumber));
      return highestAlarm;
    },
  });

  const alarmDataList = queryResults.map((result) => result.data || 0).flat();
  const highestAlarm = Math.max(...alarmDataList);

  return highestAlarm;
};

// note: old version (use new function: useDeviceDataDetail)
type PdpCurrListQueryOption<TData> = UseQueryOptions<ResPatientDevicePairList, unknown, TData>;
export const usePatientDevicePairDataList = <TData = ResPatientDevicePairList>(
  select?: Pick<PdpCurrListQueryOption<TData>, 'select'>,
) => {
  const targetTopic = process.env.REACT_APP_MQTT_TOPIC;
  const mqtt = useMqttConnection();
  const queryClient = useQueryClient();

  const defaultOptions: PdpCurrListQueryOption<TData> = {
    queryKey: ['tempKey'],
    queryFn: () => {
      return new Promise<ResPatientDevicePairList>((resolve, reject) => {
        if (mqtt?.client) {
          mqtt.client.on('connect', () => {
            if (targetTopic) {
              mqtt.client.subscribe(targetTopic);
            }
          });
          mqtt.client.on('message', (topic, message) => {
            try {
              const data = JSON.parse(message.toString());

              // 데이터를 받아서 queryClient 캐시를 업데이트
              queryClient.setQueryData(['tempKey'], data);

              resolve(data);
            } catch (err) {
              reject(err);
            }
          });
        }
      });
    },
    staleTime: 0, // 캐시가 항상 최신 상태로 유지되도록 설정
    ...select,
  };

  // note: for offline/rest-api demo
  const optionsForOfflineAndRestAPI = {
    queryKey: ['pdpList'],
    queryFn: getPatientDevicePairList,
    refetchInterval: 1000,
    enabled: false,
    ...select,
  };

  const queryOption: PdpCurrListQueryOption<TData> =
    env.REACT_APP_MODE === 'demo' ? optionsForOfflineAndRestAPI : defaultOptions;

  return useQuery(queryOption);
};

export const useMqttDeviceList = <M extends DeviceModel>(): ResPatientDevicePairList<M> => {
  const deviceTimestamps = useRef<Record<string, number>>({}); // 변경 감지를 방지하기 위해 useRef 사용

  // 최신 데이터 가져오기
  const { data: newDeviceList } = usePatientDevicePairDataList<ResPatientDevicePairList<M>>();

  // 기존 데이터 가져오기
  const [cachedDevices, setCachedDevices] = useState<ResPatientDevicePairList<M>>({ data: [] });

  // 새로운 데이터가 들어오면 기존 데이터와 병합 + 타임스탬프 갱신
  useEffect(() => {
    if (!newDeviceList?.data) return;

    const now = Date.now();
    const newDevices = newDeviceList.data;

    // 기존 장치 리스트에 새로운 장치 추가 (중복 제거)

    // 타임스탬프 갱신 (useRef 사용)
    newDevices.forEach((device) => {
      deviceTimestamps.current[device.device_serial] = now;
    });

    // 최신 장치 목록을 queryClient 캐시에 저장
    setCachedDevices((prev) => {
      const mergedDevices = [...(prev?.data || []), ...newDevices].filter(
        (device, index, self) => index === self.findIndex((d) => d.device_serial === device.device_serial),
      );
      return { data: mergedDevices };
    });
  }, [cachedDevices?.data, newDeviceList]);

  // 5초 지난 데이터 삭제
  useEffect(() => {
    const interval = setInterval(() => {
      const now = Date.now();
      const currentDevices = cachedDevices?.data || [];

      // 5초 넘은 장치 제거
      const filteredDevices = currentDevices.filter(
        (device) => now - (deviceTimestamps.current[device.device_serial] || 0) < 5000,
      );

      // 캐시 업데이트
      setCachedDevices({ data: filteredDevices });
    }, 1000); // 1초마다 실행

    return () => clearInterval(interval);
  }, [cachedDevices?.data]); // 무한 루프 방지 (queryClient만 의존성으로 등록)

  return cachedDevices;
};

export const useRoxtrendData = (data: ReqTrendData, isHidden: boolean) => {
  return useQuery<ResRoxTrendData>({
    queryKey: ['rox', 'trend', data.deviceSerial, isHidden],
    queryFn: () => {
      return getRoxTrend(data);
    },
    refetchInterval: 1000 * 60 * 1, // 1min
  });
};

type TTrendData = ResTrendData['data'];
export const useFormattedRoxtrendData = (req: ReqTrendData) => {
  return useQuery<ResRoxTrendData, unknown, { data: TTrendData }>(
    ['rox', 'trend', 'formatted', req.deviceSerial],
    () => {
      return getRoxTrend(req);
    },
    {
      select: (rawData) => {
        const data: TTrendData = rawData.data
          .map((target) => {
            return {
              deviceSerial: rawData.deviceId,
              dateTime: target.dateTime,
              value: target.roxValue,
            };
          })
          .flat();
        return { data };
      },
      refetchInterval: 1000 * 60 * 1, // 1min
    },
  );
};

export const useTrendData = (data: ReqTrendData, param: TTrend) => {
  return useQuery<ResTrendData>({
    queryKey: [data, param],
    queryFn: () => {
      return getTrend(data, param);
    },
    refetchInterval: 1000 * 60 * 1, // 1min
  });
};

// Amy: Apply 버튼에서는 처음에 enable 하지 않기 위해
export const useDeviceConnectionList = (
  data: ReqDeviceConnectionList,
  refresh: boolean,
  isAutoRefresh: boolean,
  isEnable = true,
) => {
  const { connectionInfo, alias } = useMockDataStore(
    (state) => ({ connectionInfo: state.connectionInfo, alias: state.mockDeviceAlias }),
    shallow,
  );

  const demoResponse = postPatCurrListHandler(data, connectionInfo, alias);

  const mqttData = useMqttDeviceList();

  // const regexp = new RegExp(data.filter);
  const convertMqttData: ResDeviceConnectionList['data'] =
    mqttData?.data.map((item) => {
      return {
        acq_time: item.acq_time,
        bed_id: item.bed_id,
        patient_id: item.patient_id,
        device_serial: item.device_serial,
        device_model: item.device_model,
        manufacturer: item.man_code,
        patient_last_name: '',
        patient_first_name: '',
        patient_birthdate: '',
        patient_birthdate_format_code: '',
        patient_sex: '',
        patient_race: '',
        patient_other_info: '',
        admit_datetime: null,
        discharge_datetime: null,
        dat: null,
        device_alias: null,
        pat: null,
        eat: null,
        aat: null,
        device_active_yn: 'Y',
        ssn: null,
        rox_notifications: [],
        ambulance_id: item.ambulance_id,
        group_id: item.group_id,
      };
    }) || [];

  let result: ResDeviceConnectionList['data'] = convertMqttData;
  result = result.filter(
    (val) =>
      (val.bed_id?.search(new RegExp(`${data.bedId.slice(4)}`, 'i')) || 0) >= 0 &&
      (val.patient_id?.search(new RegExp(`${data.patId.slice(4)}`, 'i')) || 0) >= 0 &&
      val.device_serial?.search(new RegExp(`${data.deviceId.slice(4)}`, 'i')) >= 0,
  );
  const parts: string[] = data.fullName.slice(4).split(' ');
  const firstNameReg = new RegExp(`${parts[0]}`, 'i');
  const lastNameReg = new RegExp(`${parts[1]}`, 'i');
  result = result.filter(
    (val) =>
      firstNameReg.test(`${val.patient_first_name}`) ||
      firstNameReg.test(`${val.patient_last_name}`) ||
      lastNameReg.test(`${val.patient_last_name}`) ||
      lastNameReg.test(`${val.patient_last_name}`),
  );
  const mqttResponse = new Promise<ResDeviceConnectionList>((resolve) =>
    resolve({
      resCode: 'SUCCESS',
      resMsg: 'Successfully gathered current monitoring data',
      totalCount: '0',
      data: result,
    }),
  );

  // Amy : 3초이상 들어오지 않으면 없는 것으로 취급
  const [isTimeout, setIsTimeout] = useState(false);
  useEffect(() => {
    const timer = setTimeout(() => {
      setIsTimeout(true);
    }, 3000); // 3초 후 타임아웃 발생

    return () => clearTimeout(timer);
  }, []);

  return useQuery<ResDeviceConnectionList>({
    queryKey: [data, refresh],
    queryFn: () => {
      // eslint-disable-next-line no-nested-ternary
      return env.REACT_APP_MODE === 'demo'
        ? demoResponse
        : env.REACT_APP_119 === 'true'
        ? mqttResponse
        : patientApis.deviceConnectionList(data);
    },
    enabled: isEnable && data.filter !== '' && !!(mqttData || isTimeout || env.REACT_APP_119 !== 'true'),
    refetchInterval: isAutoRefresh ? 1000 * 3 : false,
  });
};

export const usePatientInfoDetail = (pat: string, isOpenEdit: boolean) => {
  const { mockPatient } = useMockDataStore((state) => ({ mockPatient: state.mockPatient }), shallow);
  const patient = mockPatient.find((item) => item.pat === pat);

  const demoResponse = new Promise<any>((resolve) =>
    resolve({
      resCode: 'SUCCESS',
      resMsg: '',
      ...patient,
    }),
  );

  return useQuery<ResPatientInfo>({
    queryKey: [pat, isOpenEdit],
    queryFn: () => {
      return env.REACT_APP_MODE === 'demo' ? demoResponse : patientApis.patientInfoDetail(pat);
    },
    retry: false,
    enabled: !!pat,
  });
};

export const usePatientList = (data: ReqPatientList, isOpenReg: boolean) => {
  const mockPatient = useMockPatientList(data);
  const demoResponse = new Promise<ResPatientList>((resolve) =>
    resolve({
      resCode: 'SUCCESS',
      resMsg: '',
      totalCount: '0',
      list: mockPatient,
    }),
  );
  return useQuery<ResPatientList>({
    queryKey: [data, isOpenReg],
    queryFn: () => {
      return env.REACT_APP_MODE === 'demo' ? demoResponse : patientApis.patientList(data);
    },
  });
};

export const usePatientInfoUpdate = (data: ReqConnectionInfos) => {
  return useQuery<ResDeviceConnectionList>({
    queryKey: [data, 'Update'],
    queryFn: () => {
      return patientApis.getConnectionInfos(data);
    },
    refetchInterval: 3 * 1000,
    enabled: env.REACT_APP_MODE !== 'demo',
  });
};

export const useAdmitPatient = () => {
  const { admit, mockPatient } = useMockDataStore(
    (state) => ({ admit: state.admitConnectionInfo, mockPatient: state.mockPatient }),
    shallow,
  );

  const demoResponse = new Promise<any>((resolve) =>
    resolve({
      resCode: 'SUCCESS',
      resMsg: '',
    }),
  );
  const mutation = useMutation<any, Error, ReqAdmitPatient>((data: ReqAdmitPatient) => {
    if (env.REACT_APP_MODE === 'demo') {
      const patient = mockPatient.find((item) => item.pat === data.pat);
      admit(data, patient);
    }
    return env.REACT_APP_MODE === 'demo' ? demoResponse : patientApis.admitPatient(data);
  });

  return mutation;
};

export const useDisChargePatient = () => {
  const { discharge } = useMockDataStore((state) => ({ discharge: state.dischargeConnectionInfo }), shallow);

  const demoResponse = new Promise<any>((resolve) =>
    resolve({
      resCode: 'SUCCESS',
      resMsg: '',
    }),
  );
  const mutation = useMutation<any, Error, ReqDischargePatient>((data: ReqDischargePatient) => {
    if (env.REACT_APP_MODE === 'demo') {
      discharge(data);
    }
    return env.REACT_APP_MODE === 'demo' ? demoResponse : patientApis.dischargePatient(data);
  });

  return mutation;
};

const demoResponse = new Promise<any>((resolve) =>
  resolve({
    resCode: 'SUCCESS',
    resMsg: '',
  }),
);

export const usePatientDetailUpdate = () => {
  const { update } = useMockDataStore((state) => ({ update: state.mockPatientUpdate }), shallow);

  const mutation = useMutation<any, Error, ReqPatientInfo>((data: ReqPatientInfo) => {
    if (env.REACT_APP_MODE === 'demo') {
      update(data);
    }

    const demoRegResponse = new Promise<any>((resolve) =>
      resolve({
        resCode: 'SUCCESS',
        resMsg: '',
        ...data,
        pat: data.pat,
      }),
    );

    return env.REACT_APP_MODE === 'demo' ? demoRegResponse : patientApis.patientInfoUpdate(data);
  });

  return mutation;
};

export const usePatientReg = () => {
  const { reg } = useMockDataStore((state) => ({ reg: state.mockPatientReg }), shallow);

  const mutation = useMutation<any, Error, ReqPatientInfo>((data: ReqPatientInfo) => {
    if (env.REACT_APP_MODE === 'demo') {
      reg(data);
    }
    const demoRegResponse = new Promise<any>((resolve) =>
      resolve({
        resCode: 'SUCCESS',
        resMsg: '',
        ...data,
        pat: data.patId,
      }),
    );
    return env.REACT_APP_MODE === 'demo' ? demoRegResponse : patientApis.patientInfoReg(data);
  });

  return mutation;
};

export const useDeviceUpdate = () => {
  const { deviceUpdate } = useMockDataStore((state) => ({ deviceUpdate: state.mockDeviceAliasUpdate }), shallow);

  const mutation = useMutation<any, Error, ReqDeviceUpdate>((data: ReqDeviceUpdate) => {
    if (env.REACT_APP_MODE === 'demo') {
      deviceUpdate(data);
    }
    return env.REACT_APP_MODE === 'demo' ? demoResponse : patientApis.deviceUpdate(data);
  });

  return mutation;
};

export const useAllParamsTrend = (from: string, to: string, ids: string[]) => {
  const startTime = makeLocalTimeToUTC0(floorSeconds(parseUTC0String(from)));
  const endTime = makeLocalTimeToUTC0(parseUTC0String(to));

  // useQueries를 통해 각 id별로 요청
  const queries = useQueries({
    queries: ids.map((id) => {
      const { deviceSerial, manufacturer, model } = parseDataId(id);

      const req: ReqAllParamsTrend = {
        manufacturerCode: manufacturer,
        model,
        deviceSerial,
        start: startTime,
        end: endTime,
        sortOrder: 1,
      };

      return {
        queryKey: ['trend', 'allParam', startTime, endTime, id],
        queryFn: () => getAllParamTrendV2(req),
      };
    }),
  });

  // 요청 상태 값
  const isLoading = queries.some((query) => query.isLoading);
  const isError = queries.some((query) => query.isError);
  const isFetching = queries.some((query) => query.isFetching);

  // 로컬 상태로 data 관리
  const [data, setData] = useState<Record<string, ResTotalTrendDataV2['data']> | undefined>(undefined);

  // `queries`가 변할 때마다 `setData` 실행
  useEffect(() => {
    if (queries.every((query) => query.isSuccess)) {
      const newData = queries.reduce((acc, query, index) => {
        if (query.data) {
          acc[ids[index]] = query.data.data;
        }
        return acc;
      }, {} as Record<string, ResTotalTrendDataV2['data']>);

      setData((prevData) => {
        // 기존 데이터와 비교하여 변경된 경우에만 업데이트
        return JSON.stringify(prevData) === JSON.stringify(newData) ? prevData : newData;
      });
    }
  }, [queries, ids]);

  return { data, isLoading, isError, isFetching };
};

export const useNibpTrend = (from: string, to: string, id: string) => {
  const { deviceSerial, manufacturer, model } = parseDataId(id);

  const startTime = makeLocalTimeToUTC0(floorSeconds(parseUTC0String(from)));
  const endTime = makeLocalTimeToUTC0(ceilSeconds(parseUTC0String(to)));

  const req: ReqAllParamsTrend = {
    manufacturerCode: manufacturer,
    model,
    deviceSerial,
    start: startTime,
    end: endTime,
    sortOrder: 1,
  };

  return useQuery<ResNibpTrend>({
    queryKey: ['trend', 'nibp', startTime, endTime, id],
    queryFn: () => {
      return getNibpTrend(req);
    },
    staleTime: 60000,
    enabled: id !== '',
  });
};

export const useNibpTrendByPeriod = (id: string, from: Date, to: Date, enabled?: boolean) => {
  const { deviceSerial, manufacturer, model } = parseDataId(id);

  const startTime = makeLocalTimeToUTC0(floorSeconds(from));
  const endTime = makeLocalTimeToUTC0(ceilSeconds(to));

  const req: ReqAllParamsTrend = {
    manufacturerCode: manufacturer,
    model,
    deviceSerial,
    start: startTime,
    end: endTime,
    sortOrder: 1,
  };

  return useQuery<ResNibpTrend>({
    queryKey: ['trend', 'nibp', `${startTime}-${endTime}`, id],
    queryFn: () => {
      return getNibpTrend(req);
    },
    suspense: env.REACT_APP_MODE !== 'demo',
    enabled,
  });
};

export const useWaveOverview = (startTime: string, endTime: string, isUseDetailAPI: boolean, id: string) => {
  const { deviceSerial, manufacturer, model } = parseDataId(id);

  const req: ReqAllParamsTrend = {
    manufacturerCode: manufacturer,
    model,
    deviceSerial,
    start: startTime,
    end: endTime,
    sortOrder: 1,
  };

  return useQuery<ResWaveOverview>({
    queryKey: ['wave', 'overview', startTime, endTime, isUseDetailAPI, id],
    queryFn: () => {
      return isUseDetailAPI ? getWaveDetail(req) : getWaveOverview(req);
    },
    staleTime: 60000,
  });
};

export const useWaveDetail = (sec: number, id: string, baseDatetime: Date) => {
  const { deviceSerial, manufacturer, model } = parseDataId(id);
  const from = baseDatetime;
  const to = addSeconds(new Date(from.getTime()), sec);

  const startTime = makeLocalTimeToUTC0(from);
  const endTime = makeLocalTimeToUTC0(to);
  const req: ReqAllParamsTrend = {
    manufacturerCode: manufacturer,
    model,
    deviceSerial,
    start: startTime,
    end: endTime,
    sortOrder: 1,
  };

  return useQuery<ResWaveDetail>({
    queryKey: ['wave', 'detail', id, `${startTime}-${endTime}`],
    queryFn: () => {
      return getWaveDetail(req);
    },
    staleTime: 60000,
  });
};

export const useTrendAllByPeriod = (id: string, from: Date, to: Date, enabled?: boolean) => {
  const { deviceSerial, manufacturer, model } = parseDataId(id);

  const startTime = makeLocalTimeToUTC0(floorSeconds(from));
  const endTime = makeLocalTimeToUTC0(ceilSeconds(to));

  const req: ReqAllParamsTrend = {
    manufacturerCode: manufacturer,
    model,
    deviceSerial,
    start: startTime,
    end: endTime,
    sortOrder: 1,
  };

  // note: new version of api
  return useQuery<ResTotalTrendDataV2>({
    queryKey: ['trend', 'allParam', `${startTime}-${endTime}`, id],
    queryFn: () => {
      return getAllParamTrendV2(req);
    },
    suspense: env.REACT_APP_MODE !== 'demo',
    enabled,
  });

  // return useQuery({
  //   queryKey: ['trend', 'allParam', `${startTime}-${endTime}`, id],
  //   queryFn: () => {
  //     return getAllParamTrendV1(req);
  //   },
  //   suspense: env.REACT_APP_MODE !== 'demo',
  //   select: (data) => {
  //     const { model } = parseDataId(id);
  //     const targetData = (model: string) => {
  //       switch (model) {
  //         case 'HFT700':
  //         case 'HFT750':
  //           return data?.hftData || [];
  //         case 'MV50':
  //           return data?.mv50Data || [];
  //         case 'MV2000':
  //           return data?.mv2000Data || [];
  //         case 'MP800':
  //         case 'MP1300':
  //         case 'MP1000NTP':
  //           return data?.mpData || [];
  //         default:
  //           return [];
  //       }
  //     };
  //     return {
  //       data: targetData(model),
  //     };
  //   },
  // });
};

export const useLastDeviceInfoByPeriod = (id: string, from: Date, to: Date) => {
  const { deviceSerial, manufacturer, model } = parseDataId(id);

  const startTime = makeLocalTimeToUTC0(from);
  const endTime = makeLocalTimeToUTC0(to);

  const req: Omit<ReqAllParamsTrend, 'sortOrder'> = {
    manufacturerCode: manufacturer,
    model,
    deviceSerial,
    start: startTime,
    end: endTime,
  };

  return useQuery<ResLastDeviceInfo>({
    queryKey: ['last-device-info', `${startTime}-${endTime}`, id],
    queryFn: () => {
      return getLastDeviceInfo(req);
    },
  });
};
