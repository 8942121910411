import { createContext, useContext } from 'react';

import { create, useStore } from 'zustand';
import { devtools } from 'zustand/middleware';
import { shallow } from 'zustand/shallow';

type State = {
  pageCount: number;
  isDataProcessed: boolean[];
};

interface Actions {
  updateIsDataProcessed: (pageIndex: number) => void;
}

const initialState: State = {
  pageCount: 1,
  isDataProcessed: [],
};

export const createTrendReportStore = (initialProps: State) => {
  return create<State & Actions>()(
    devtools((set, get) => ({
      ...initialState,
      ...initialProps,
      updateIsDataProcessed: (pageIndex: number) => {
        const { isDataProcessed } = get();
        const updated = [...isDataProcessed];
        updated[pageIndex] = true;
        set((state) => ({ ...state, isDataProcessed: updated }));
      },
    })),
  );
};

type TrendReportStore = ReturnType<typeof createTrendReportStore>;

export const TrendReportStoreContext = createContext<TrendReportStore | null>(null);

export const useTrendReportStoreContext = <T>(selector: (state: State & Actions) => T): T => {
  const store = useContext(TrendReportStoreContext);
  if (!store) throw new Error('Missing TrendReportStoreContext.Provider in the tree');
  return useStore(store, selector, shallow);
};
