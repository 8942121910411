import { numberInputAnatomy } from '@chakra-ui/anatomy';
import { createMultiStyleConfigHelpers } from '@chakra-ui/react';
import { ComponentStyleConfig } from '@chakra-ui/theme';

const { definePartsStyle } = createMultiStyleConfigHelpers(numberInputAnatomy.keys);

const primary = definePartsStyle({
  field: {
    bgColor: 'white',
    color: 'black',
    fontSize: '14px',
    h: '34px',
    rounded: '4px',

    zIndex: 1,
    _focus: {
      shadow: 'none',
      ring: '1px',
      ringColor: 'brand.500',
    },
    _disabled: {
      opacity: 0.5,
    },
    _placeholder: {
      color: 'gray.700',
      fontWeight: '300',
      fontSize: 'inherit',
    },
    _invalid: {
      shadow: 'none',
    },
  },
  stepperGroup: {
    w: 0,
  },
  stepper: {
    w: 0,
  },
});

const NumberInput: ComponentStyleConfig = { variants: { primary } };

export default NumberInput;
