import { PARAM_LIST, SETTING_PARAM_LIST, WAVE_PARAM_LIST } from '@/constants/deviceParams';
import {
  TParamProperty,
  TCustomParamProperty,
  TWaves,
  TDevice,
  CUSTOM_PARAM_PROPERTIES,
  TLayoutProperty,
} from '@/constants/types';
import { removeDuplicateObjects } from '@/utils/localStorage/removeDuplicateObjects';

import { DEVICE_MODEL } from '../../types';

export const MP_DEVICES: string[] = [
  DEVICE_MODEL.MP800,
  DEVICE_MODEL.MP1000NTP,
  DEVICE_MODEL.MP1300,
  DEVICE_MODEL.BLTM10,
];
export const HFT_DEVICES: string[] = [DEVICE_MODEL.HFT700, DEVICE_MODEL.HFT750, DEVICE_MODEL.HFT751];
export const MV_DEVICES: string[] = [DEVICE_MODEL.MV50, DEVICE_MODEL.MV2000];

export const ZOOM_LAYOUT_MODEL_BY_KEY = {
  'Patient Monitor + High Flow': [...HFT_DEVICES, ...MP_DEVICES],
  'Patient Monitor + Ventilator': [...MV_DEVICES, ...MP_DEVICES],
  'Patient Monitor': MP_DEVICES,
  Ventilator: MV_DEVICES,
  'High Flow': HFT_DEVICES,
};
export type SourceDevice = 'PM' | 'MV' | 'HFT';
export type ZoomLayoutSettingValue = TLayoutProperty & { sourceDevice: SourceDevice | null };
export type ZoomLayoutSetting = {
  [key in keyof typeof ZOOM_LAYOUT_MODEL_BY_KEY]: ZoomLayoutSettingValue[][];
};

// CONSTANTS
export type Position = { row: number; col: number };
export const paramItems: ({ placeholder: string; colspan?: number } & Position)[] = [
  { row: 0, col: 0, placeholder: 'wave', colspan: 2 },
  { row: 0, col: 1, placeholder: 'parameter' },
  { row: 1, col: 0, placeholder: 'wave', colspan: 2 },
  { row: 1, col: 1, placeholder: 'parameter' },
  { row: 2, col: 0, placeholder: 'wave', colspan: 2 },
  { row: 2, col: 1, placeholder: 'parameter' },
  { row: 3, col: 0, placeholder: 'wave', colspan: 2 },
  { row: 3, col: 1, placeholder: 'parameter' },
  { row: 4, col: 0, placeholder: 'wave', colspan: 2 },
  { row: 4, col: 1, placeholder: 'parameter' },
  { row: 5, col: 0, placeholder: 'parameter' },
  { row: 5, col: 1, placeholder: 'parameter' },
  { row: 5, col: 2, placeholder: 'parameter' },
  { row: 6, col: 0, placeholder: 'parameter' },
  { row: 6, col: 1, placeholder: 'parameter' },
  { row: 6, col: 2, placeholder: 'parameter' },
  { row: 7, col: 0, placeholder: 'parameter' },
  { row: 7, col: 1, placeholder: 'parameter' },
  { row: 7, col: 2, placeholder: 'parameter' },
  { row: 8, col: 0, placeholder: 'parameter' },
  { row: 8, col: 1, placeholder: 'parameter' },
  { row: 8, col: 2, placeholder: 'parameter' },
  { row: 9, col: 0, placeholder: 'parameter' },
  { row: 9, col: 1, placeholder: 'parameter' },
  { row: 9, col: 2, placeholder: 'parameter' },
];

export const WAVE_BY_PARAMETER: { [key in TParamProperty | TCustomParamProperty]?: TWaves } = {
  hr: 'ecg_wave_ii',
  ibp1: 'ibp1_wave',
  ibp2: 'ibp2_wave',
  ibp3: 'ibp3_wave',
  ibp4: 'ibp4_wave',
  co2: 'co2_wave',
  ptif: 'ptif',
  spo2: 'spo2_wave',
  flow: 'flow_wave',
  rr: 'resp_wave',
  ppeak: 'pressure_wave',
  vte: 'volume_wave',
};

export const PARAMETER_BY_WAVE: { [key in TWaves]?: TParamProperty & TCustomParamProperty } = Object.entries(
  WAVE_BY_PARAMETER,
).reduce((acc, [key, value]) => ({ ...acc, [value]: key }), {});

export const isCustomParam = (paramKey: string): boolean => {
  if (Object.keys(CUSTOM_PARAM_PROPERTIES).includes(paramKey)) {
    return true;
  }
  return false;
};

const getSourceDeviceByTDevice = (device: TDevice): SourceDevice => {
  switch (device) {
    case 'HFT700':
      return 'HFT';
    case 'MV50':
    case 'MV2000':
      return 'MV';
    case 'MP1300':
      return 'PM';
    default:
      throw new Error('Undefined Device');
  }
};
const getZoomLayoutSettingValueByTDevice = (device: TDevice): ZoomLayoutSettingValue[] => {
  const sourceDevice = getSourceDeviceByTDevice(device);

  const result: ZoomLayoutSettingValue[] = [];

  const deviceParams = PARAM_LIST[device];
  if (deviceParams) {
    const allParams: ZoomLayoutSettingValue[] = Object.values(deviceParams)
      .flatMap((item) => item.params)
      .map((item) => {
        if (isCustomParam(item)) {
          return { type: 'custom', property: item as TCustomParamProperty, sourceDevice };
        }
        return { type: 'params', property: item as TParamProperty, sourceDevice };
      });

    result.push(...allParams);
  }

  const deviceSettings = SETTING_PARAM_LIST[device];
  if (deviceSettings) {
    const allSettings: ZoomLayoutSettingValue[] = Object.values(deviceSettings).map((item) => {
      return { type: 'settings', property: item, sourceDevice };
    });
    result.push(...allSettings);
  }

  const deviceWaves = WAVE_PARAM_LIST[device];
  if (deviceWaves) {
    const allWaves: ZoomLayoutSettingValue[] = Object.values(deviceWaves).map((item) => {
      return { type: 'waves', property: item, sourceDevice };
    });
    result.push(...allWaves);
  }
  return result;
};

export const ZOOM_LAYOUT_VAULES_BY_KEY: { [key in keyof typeof ZOOM_LAYOUT_MODEL_BY_KEY]: ZoomLayoutSettingValue[] } = {
  'Patient Monitor + High Flow': removeDuplicateObjects([
    ...getZoomLayoutSettingValueByTDevice('MP1300'),
    ...getZoomLayoutSettingValueByTDevice('HFT700'),
  ]),
  'Patient Monitor + Ventilator': removeDuplicateObjects([
    ...getZoomLayoutSettingValueByTDevice('MP1300'),
    ...getZoomLayoutSettingValueByTDevice('MV2000'),
    ...getZoomLayoutSettingValueByTDevice('MV50'),
  ]),
  'Patient Monitor': removeDuplicateObjects([...getZoomLayoutSettingValueByTDevice('MP1300')]),
  Ventilator: removeDuplicateObjects([
    ...getZoomLayoutSettingValueByTDevice('MV2000'),
    ...getZoomLayoutSettingValueByTDevice('MV50'),
  ]),
  'High Flow': removeDuplicateObjects([...getZoomLayoutSettingValueByTDevice('HFT700')]),
};
