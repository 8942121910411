import { TTrend } from '@/constants/types';

import { TREND_DATA } from './constantTrendData';
import { calculateRox, calculateSFRatio } from './generateRoxSimulation';

export const trendData: { [K in TTrend]?: number[] } = {
  rox: calculateRox(TREND_DATA.spo2, TREND_DATA.rr, TREND_DATA.fio2),
  spo2: TREND_DATA.spo2,
  estimated_tidal_volume: TREND_DATA.estimated_tidal_volume,
  v_delivery: TREND_DATA.v_delivery,
  sf_ratio: calculateSFRatio(TREND_DATA.spo2, TREND_DATA.fio2),
  rr: TREND_DATA.rr,
  pr: TREND_DATA.pr,
  pip: TREND_DATA.pip,
  pi: TREND_DATA.pi,
  peep: TREND_DATA.peep,
  flow: TREND_DATA.flow,
  fio2: TREND_DATA.fio2,
  hr: TREND_DATA.hr,
  tmp1: TREND_DATA.tmp1,
  tmp2: TREND_DATA.tmp2,
  ibp1_s: TREND_DATA.ibp1_s,
  ibp1_d: TREND_DATA.ibp1_d,
  ibp1_m: TREND_DATA.ibp1_m,
  ibp2_s: TREND_DATA.ibp2_s,
  ibp2_d: TREND_DATA.ibp2_d,
  ibp2_m: TREND_DATA.ibp2_m,
  etco2: TREND_DATA.etco2,
  fico2: TREND_DATA.fico2,
  ppeak: TREND_DATA.ppeak,
  pmean: TREND_DATA.pmean,
  ppause: TREND_DATA.ppause,
  fi_peak: TREND_DATA.fi_peak,
  fe_peak: TREND_DATA.fe_peak,
  peep_h: TREND_DATA.peep_h,
  peep_l: TREND_DATA.peep_l,
  vico2: TREND_DATA.vico2,
  veco2: TREND_DATA.veco2,
  vmeco2: TREND_DATA.vmeco2,
  rsbi: TREND_DATA.rsbi,
  wobv: TREND_DATA.wobv,
  cl: TREND_DATA.cl,
  ra: TREND_DATA.ra,
  rr_spont: TREND_DATA.rr_spont,
  etco2_rr: TREND_DATA.etco2_rr,
  vti: TREND_DATA.vti,
  vte: TREND_DATA.vte,
  vme: TREND_DATA.vme,
  ti: TREND_DATA.ti,
  te: TREND_DATA.te,
  tc: TREND_DATA.tc,
  'p_0.1': TREND_DATA['p_0.1'],
  auto_peep: TREND_DATA.auto_peep,
  vme_s: TREND_DATA.vme_s,
  e: TREND_DATA.e,
  ico2: TREND_DATA.ico2,
};
