import { PropsWithChildren, Suspense, useMemo } from 'react';

import { Text } from '@chakra-ui/react';
import { useParams, useLocation } from 'react-router-dom';

import env from '@/env';
import useCustomColorMode from '@/hooks/useCustomColorMode';
import useLanguage from '@/hooks/useLanguage';
import { HeaderData } from '@/reports/common/ReportHeader';
import { routes } from '@/router/routes';
import { parseUTC0String } from '@/utils/dateTimeUtils';

import Indicator from './common/Indicator';
import TrendReport from './TrendReport/TrendReport';
import EcgWaveReport from './WaveReport/EcgWaveReport';
import { fullFormat } from '../CentralMain/utils/convertAdmissionTime';
import { parseDataId } from '../CentralMain/utils/dataKeyUtils';

const LoadingIndicator = () => {
  const { translate } = useLanguage();
  const { color } = useCustomColorMode();
  return (
    <Indicator>
      <Text color={color} fontWeight={600}>
        {translate('loadingPdf')}
      </Text>
      <Text color={color}>{translate('wait')}</Text>
    </Indicator>
  );
};
const LoadingSuspense = ({ children }: PropsWithChildren) => {
  if (env.REACT_APP_MODE === 'demo') {
    return <>{children}</>;
  }
  return <Suspense fallback={<LoadingIndicator />}>{children}</Suspense>;
};

const ReportProcessor = () => {
  const { translate } = useLanguage();

  const param = useParams();
  const location = useLocation();
  const {
    query: { reportType, dataId, from, to, age, bedId, deviceAlias, name, patientId, sex, targetParamKeys },
  } = useMemo(() => {
    return routes.REPORT_PROCESSOR.parse(param, location);
  }, [location, param]);

  const period = useMemo(() => {
    return { from: parseUTC0String(from || ''), to: parseUTC0String(to || '') };
  }, [from, to]);
  const headerData: HeaderData = useMemo(() => {
    const { deviceSerial } = dataId ? parseDataId(dataId) : { deviceSerial: '' };
    return {
      age: age || '',
      name: name || '',
      patientId: patientId || '',
      printDate: new Date(),
      printPeriod: `${fullFormat(period.from)} ~ ${fullFormat(period.to)}`,
      sex: sex || '',
      deviceAlias: deviceAlias || '',
      bedId: bedId || '',
      deviceSerial: deviceSerial || '',
    };
  }, [age, bedId, deviceAlias, name, patientId, period.from, period.to, sex, dataId]);

  if (!dataId) {
    return (
      <Indicator>
        <Text fontWeight={600}>{translate('errorInvalidAccess')}</Text>
      </Indicator>
    );
  }
  if (reportType === 'trend') {
    const MAX_TREND_REPORT_COUNT = 5;
    const paramKeys = targetParamKeys?.slice(0, MAX_TREND_REPORT_COUNT) || [];

    return (
      <LoadingSuspense>
        <TrendReport
          dataId={dataId}
          from={period.from}
          to={period.to}
          headerData={headerData}
          targetParamKeys={paramKeys}
        />
      </LoadingSuspense>
    );
  }
  if (reportType === 'ecg-wave') {
    return (
      <LoadingSuspense>
        <EcgWaveReport dataId={dataId} from={period.from} to={period.to} headerData={headerData} />
      </LoadingSuspense>
    );
  }

  return (
    <Indicator>
      <Text fontWeight={600}>{translate('errorInvalidAccess')}</Text>
    </Indicator>
  );
};

export default ReportProcessor;
