import { AlarmHistoryProperty } from '@/apis/alarm/type';
import { PARAMS } from '@/constants/data';
import { Data, DeviceModel, ParamKeys } from '@/pages/CentralMain/types';
import { fullFormat } from '@/pages/CentralMain/utils/convertAdmissionTime';
import { parseUTC0String } from '@/utils/dateTimeUtils';

import { valueFormatter } from '../../NumericDataTemplate/utils/formatters';

// type helper
type ArrayElement<ArrayType extends readonly unknown[]> = ArrayType extends readonly (infer ElementType)[]
  ? ElementType
  : never;

// // type
// type HFT = 'HFT700' | 'HFT750';
// type MP = 'MP800' | 'MP1000NTP' | 'MP1300';
// eslint-disable-next-line camelcase
type Formatter = (
  data: ArrayElement<Record<string, any>[]>,
  id?: string,
) => {
  value: string | undefined;
};

// table header keys
export const CUSTOM_TREND_KEYS = [
  // MP
  'tmp',
  'ibp1',
  'ibp2',
  'nibp',
  've_min',
  'st',
  // MV50
  'flow/fi_peak/fe_peak',
  'ppause/pmean/peep',
  'ti/te/tc',
  'rr/rr_spont/etco2_rr',
  'vme/vme_s',
  'p_0.1/auto_peep',
  'fio2/spo2',
  'pi/etco2/ico2',
  'vico2/veco2',
  'sf_ratio/rox',
  'vti/vte',
] as const;
export type CustomTrendKeys = (typeof CUSTOM_TREND_KEYS)[number];
export type TrendFormatRule = {
  property: ParamKeys | CustomTrendKeys;
  label: string;
  formatter: Formatter;
};

// util
const defaultLabel = (property: keyof Data['params']): string => {
  return PARAMS[property]?.label || '';
};

const getDecimalPlace = (property: keyof Data['params']) => {
  return PARAMS[property]?.decimalPlaces || 0;
};

const defaultDataFormatter = (property: keyof Data['params']) => {
  return (data: Record<string, any>, id?: string) => {
    const idParamKey = id ? `${id}-${property}` : property;
    const rawValue = data[idParamKey] ?? data[property]; // 안전한 값 확인
    const value = valueFormatter(rawValue, getDecimalPlace(property));
    return {
      value: value || '--',
    };
  };
};

export const defaultTrendFormatRule = (property: ParamKeys): TrendFormatRule => ({
  property,
  label: defaultLabel(property),
  formatter: defaultDataFormatter(property),
});

// table label list
const HFTTrendFormatRules: TrendFormatRule[] = [
  {
    property: 'rox',
    label: defaultLabel('rox'),
    formatter: defaultDataFormatter('rox'),
  },
  {
    property: 'sf_ratio',
    label: defaultLabel('sf_ratio'),
    formatter: defaultDataFormatter('sf_ratio'),
  },
  {
    property: 'rr',
    label: defaultLabel('rr'),
    formatter: defaultDataFormatter('rr'),
  },
  {
    property: 'fio2',
    label: defaultLabel('fio2'),
    formatter: defaultDataFormatter('fio2'),
  },
  {
    property: 'flow',
    label: defaultLabel('flow'),
    formatter: defaultDataFormatter('flow'),
  },
  {
    property: 'pi',
    label: defaultLabel('pi'),
    formatter: defaultDataFormatter('pi'),
  },
  {
    property: 'peep',
    label: defaultLabel('peep'),
    formatter: defaultDataFormatter('peep'),
  },
  {
    property: 'spo2',
    label: defaultLabel('spo2'),
    formatter: defaultDataFormatter('spo2'),
  },
  {
    property: 'pr',
    label: defaultLabel('pr'),
    formatter: defaultDataFormatter('pr'),
  },
  {
    property: 'estimated_tidal_volume',
    label: defaultLabel('estimated_tidal_volume'),
    formatter: defaultDataFormatter('estimated_tidal_volume'),
  },
  {
    property: 'pip',
    label: defaultLabel('pip'),
    formatter: defaultDataFormatter('pip'),
  },
  {
    property: 'v_delivery',
    label: defaultLabel('v_delivery'),
    formatter: defaultDataFormatter('v_delivery'),
  },
];
const MPTrendFormatRules: TrendFormatRule[] = [
  {
    property: 'hr',
    label: defaultLabel('hr'),
    formatter: defaultDataFormatter('hr'),
  },
  {
    property: 'spo2',
    label: defaultLabel('spo2'),
    formatter: defaultDataFormatter('spo2'),
  },
  {
    property: 'nibp',
    label: 'NIBP',
    formatter: (data, id) => {
      return {
        value: `${defaultDataFormatter('nibp_s')(data, id).value} / ${
          defaultDataFormatter('nibp_d')(data, id).value
        } (${defaultDataFormatter('nibp_m')(data, id).value})`,
      };
    },
  },
  {
    property: 'rr',
    label: defaultLabel('rr'),
    formatter: defaultDataFormatter('rr'),
  },
  {
    property: 'tmp',
    label: 'TEMP 1/2',
    formatter: (data, id) => {
      return {
        value: `${defaultDataFormatter('tmp1')(data, id).value} / ${defaultDataFormatter('tmp2')(data, id).value}`,
      };
    },
  },
  {
    property: 'tmp1',
    label: 'TEMP 1',
    formatter: (data, id) => {
      return {
        value: `${defaultDataFormatter('tmp1')(data, id).value}`,
      };
    },
  },
  {
    property: 'tmp2',
    label: 'TEMP 2',
    formatter: (data, id) => {
      return {
        value: `${defaultDataFormatter('tmp2')(data, id).value}`,
      };
    },
  },
  {
    property: 'ibp1',
    label: 'IBP①',
    formatter: (data, id) => {
      return {
        value: `${defaultDataFormatter('ibp1_s')(data, id).value} / ${
          defaultDataFormatter('ibp1_d')(data, id).value
        } (${defaultDataFormatter('ibp1_m')(data, id).value})`,
      };
    },
  },
  {
    property: 'ibp2',
    label: 'IBP②',
    formatter: (data, id) => {
      return {
        value: `${defaultDataFormatter('ibp2_s')(data, id).value} / ${
          defaultDataFormatter('ibp2_d')(data, id).value
        } (${defaultDataFormatter('ibp2_m')(data, id).value})`,
      };
    },
  },
  {
    property: 'st',
    label: 'ST 1/2',
    formatter: (data, id) => {
      return {
        value: `${defaultDataFormatter('st_i')(data, id).value} / ${defaultDataFormatter('st_ii')(data, id).value}`,
      };
    },
  },
  {
    property: 'etco2',
    label: defaultLabel('etco2'),
    formatter: defaultDataFormatter('etco2'),
  },
  {
    property: 'fico2',
    label: defaultLabel('fico2'),
    formatter: defaultDataFormatter('fico2'),
  },
];

// const MV2000TrendFormatRules: TrendFormatRule[] = [
//   {
//     property: 'vme',
//     label: 'VE min',
//     formatter: defaultDataFormatter('vme'),
//   },
//   {
//     property: 'pmean',
//     label: defaultLabel('pmean'),
//     formatter: defaultDataFormatter('pmean'),
//   },
//   {
//     property: 'ppeak',
//     label: defaultLabel('ppeak'),
//     formatter: defaultDataFormatter('ppeak'),
//   },
//   {
//     property: 'peep',
//     label: defaultLabel('peep'),
//     formatter: defaultDataFormatter('peep'),
//   },
//   {
//     property: 'vte',
//     label: defaultLabel('vte'),
//     formatter: defaultDataFormatter('vte'),
//   },
//   {
//     property: 'rr',
//     label: defaultLabel('rr'),
//     formatter: defaultDataFormatter('rr'),
//   },
// ];
const MV50TrendFormatRules: TrendFormatRule[] = [
  {
    property: 'ppeak',
    label: defaultLabel('ppeak'),
    formatter: defaultDataFormatter('ppeak'),
  },
  {
    property: 'ppause/pmean/peep',
    label: `${defaultLabel('ppause')} / ${defaultLabel('pmean')} / ${defaultLabel('peep')}`,
    formatter: (data, id) => {
      return {
        value: `${defaultDataFormatter('ppause')(data, id).value} / ${
          defaultDataFormatter('pmean')(data, id).value
        } / ${defaultDataFormatter('peep')(data, id).value}`,
      };
    },
  },
  {
    property: 'rr/rr_spont/etco2_rr',
    label: `${defaultLabel('rr')} / ${defaultLabel('rr_spont')} / ${defaultLabel('etco2_rr')}`,
    formatter: (data, id) => {
      return {
        value: `${defaultDataFormatter('rr')(data, id).value} / ${defaultDataFormatter('rr_spont')(data, id).value} / ${
          defaultDataFormatter('etco2_rr')(data, id).value
        }`,
      };
    },
  },
  {
    property: 'vti/vte',
    label: `${defaultLabel('vti')} / ${defaultLabel('vte')}`,
    formatter: (data, id) => {
      return {
        value: `${defaultDataFormatter('vti')(data, id).value} / ${defaultDataFormatter('vte')(data, id).value}`,
      };
    },
  },
  {
    property: 'vme/vme_s',
    label: `${defaultLabel('vme')} / ${defaultLabel('vme_s')}`,
    formatter: (data, id) => {
      return {
        value: `${defaultDataFormatter('vme')(data, id).value} / ${defaultDataFormatter('vme_s')(data, id).value}`,
      };
    },
  },
  {
    property: 'fio2/spo2',
    label: `${defaultLabel('fio2')} / ${defaultLabel('spo2')}`,
    formatter: (data, id) => {
      return {
        value: `${defaultDataFormatter('fio2')(data, id).value} / ${defaultDataFormatter('spo2')(data, id).value}`,
      };
    },
  },
  {
    property: 'ti/te/tc',
    label: `${defaultLabel('ti')} / ${defaultLabel('te')} / ${defaultLabel('tc')}`,
    formatter: (data, id) => {
      return {
        value: `${defaultDataFormatter('ti')(data, id).value} / ${defaultDataFormatter('te')(data, id).value} / ${
          defaultDataFormatter('tc')(data, id).value
        }`,
      };
    },
  },
  {
    property: 'flow/fi_peak/fe_peak',
    label: `${defaultLabel('flow')} / ${defaultLabel('fi_peak')} / ${defaultLabel('fe_peak')}`,
    formatter: (data, id) => {
      return {
        value: `${defaultDataFormatter('flow')(data, id).value} / ${
          defaultDataFormatter('fi_peak')(data, id).value
        } / ${defaultDataFormatter('fe_peak')(data, id).value}`,
      };
    },
  },
  {
    property: 'p_0.1/auto_peep',
    label: `${defaultLabel('p_0.1')} / ${defaultLabel('auto_peep')}`,
    formatter: (data, id) => {
      return {
        value: `${defaultDataFormatter('p_0.1')(data, id).value} / ${
          defaultDataFormatter('auto_peep')(data, id).value
        }`,
      };
    },
  },
  {
    property: 'vico2/veco2',
    label: `${defaultLabel('vico2')} / ${defaultLabel('veco2')}`,
    formatter: (data, id) => {
      return {
        value: `${defaultDataFormatter('vico2')(data, id).value} / ${defaultDataFormatter('veco2')(data, id).value}`,
      };
    },
  },
  {
    property: 'vmeco2',
    label: defaultLabel('vmeco2'),
    formatter: defaultDataFormatter('vmeco2'),
  },
  {
    property: 'pi/etco2/ico2',
    label: `${defaultLabel('pi')} / ${defaultLabel('etco2')} / ${defaultLabel('ico2')}`,
    formatter: (data, id) => {
      return {
        value: `${defaultDataFormatter('pi')(data, id).value} / ${defaultDataFormatter('etco2')(data, id).value} / ${
          defaultDataFormatter('ico2')(data, id).value
        }`,
      };
    },
  },
  {
    property: 'pr',
    label: defaultLabel('pr'),
    formatter: defaultDataFormatter('pr'),
  },
  {
    property: 'rsbi',
    label: defaultLabel('rsbi'),
    formatter: defaultDataFormatter('rsbi'),
  },
  {
    property: 'wobv',
    label: defaultLabel('wobv'),
    formatter: defaultDataFormatter('wobv'),
  },
  {
    property: 'cl',
    label: defaultLabel('cl'),
    formatter: defaultDataFormatter('cl'),
  },
  {
    property: 'ra',
    label: defaultLabel('ra'),
    formatter: defaultDataFormatter('ra'),
  },
  {
    property: 'e',
    label: defaultLabel('e'),
    formatter: defaultDataFormatter('e'),
  },

  {
    property: 'sf_ratio/rox',
    label: `${defaultLabel('sf_ratio')} / ${defaultLabel('rox')}`,
    formatter: (data, id) => {
      return {
        value: `${defaultDataFormatter('sf_ratio')(data, id).value} / ${defaultDataFormatter('rox')(data, id).value}`,
      };
    },
  },
];

export const AlarmHistoryFormatRules: {
  property: keyof AlarmHistoryProperty;
  label: string;
  formatter: (s: string) => string;
}[] = [
  {
    property: 'cleared_date_time',
    label: 'clearedTime',
    formatter: (s) => fullFormat(parseUTC0String(s)),
  },
  {
    property: 'alarm_msg',
    label: 'message',
    formatter: (s) => s,
  },
  {
    property: 'alarm_level',
    label: 'level',
    formatter: (s) => s,
  },
  {
    property: 'curr_value',
    label: 'value',
    formatter: (s) => s,
  },
  {
    property: 'min_value',
    label: 'alarm',
    formatter: (s) => s,
  },
  {
    property: 'max_value',
    label: 'alarm',
    formatter: (s) => s,
  },
];

export const getTrendFormatRuleListByDeviceModel = (deviceModel: DeviceModel): TrendFormatRule[] => {
  if (deviceModel === 'HFT700' || deviceModel === 'HFT750') {
    return HFTTrendFormatRules;
  }
  if (deviceModel === 'MP800' || deviceModel === 'MP1000NTP' || deviceModel === 'MP1300' || deviceModel === 'BLTM10') {
    return MPTrendFormatRules;
  }
  if (deviceModel === 'MV50') {
    return MV50TrendFormatRules;
  }
  if (deviceModel === 'MV2000') {
    return MV50TrendFormatRules;
  }
  return [];
};

// export const getTrendFormatRuleList = (
//   ids: string[],
//   params: {
//     deviceId: string;
//     paramKey: TrendKey;
//   }[],
// ): TrendFormatRule[] => {};
