import { modalAnatomy as parts } from '@chakra-ui/anatomy';
import { createMultiStyleConfigHelpers } from '@chakra-ui/system';
import type { ComponentStyleConfig } from '@chakra-ui/theme';

const { definePartsStyle, defineMultiStyleConfig } = createMultiStyleConfigHelpers(parts.keys);
const simpleModal = definePartsStyle(({ colorMode }) => ({
  overlay: {
    width: '100%',
    height: '100%',
  },
  dialogContainer: {
    w: '100%',
    h: '100%',
  },
  dialog: {
    borderRadius: 'md',
    bg: colorMode === 'dark' ? 'gray.300' : 'white',
    color: 'black',
    maxW: 'unset',
    width: 'max-content',
  },
  header: {
    bg: '#5C6870',
    borderTopRadius: 'md',
    p: '10px 24px',
    fontFamily: 'SUIT',
    fontSize: 'lg',
    fontWeight: 700,
    color: 'white',
  },
  closeButton: {
    color: 'white',
  },
  footer: {},
}));

const Modal: ComponentStyleConfig = defineMultiStyleConfig({
  baseStyle: ({ colorMode }) => ({
    overlay: {
      width: '100%',
      height: '100%',
    },
    dialogContainer: {
      width: '100%',
      height: '100%',
    },
    dialog: {
      borderRadius: 'md',
      bg: colorMode === 'dark' ? `purple.100` : 'white',
      maxW: 'unset',
      width: 'max-content',
      maxH: 'unset',
    },
  }),
  variants: {
    simple: simpleModal,
  },
});

export default Modal;
