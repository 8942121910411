import { AlarmLevel } from '@/pages/CentralMain/types';

import { DefaultDevice } from './device/DefaultDevice';
import { MpSeries } from './device/MpSeries';
import { MvSeries } from './device/MvSeries';

let tempMpBedIdCount = 0;

export const devices = Array(32)
  .fill('dummy')
  .map((_, index) => {
    let hasAlarm: AlarmLevel = 'unknown';
    // Amy : bedId 를 mp 와 똑같은 것을 만들기 위해
    if ([2, 5, 8, 9, 14].includes(index)) {
      if (index === 2) {
        hasAlarm = 'HIGH';
      }
      return new DefaultDevice(index + 1, '6F_RW', hasAlarm, 'HFT700');
    }
    if ([6, 7, 13, 15].includes(index)) {
      return new DefaultDevice(index + 1, '6F_RW', hasAlarm, 'HFT750');
    }
    if ([1, 16, 17, 18].includes(index)) {
      if (index === 17) {
        hasAlarm = 'MEDIUM';
      }
      return new MvSeries(index + 1, 'ICU', hasAlarm, 'MV50');
    }
    if ([3, 4, 20, 10, 11, 21, 22, 30].includes(index)) {
      return new MvSeries(index + 1, 'ICU', hasAlarm, 'MV2000');
    }

    if (index === 19) {
      hasAlarm = 'MEDIUM';
    }
    tempMpBedIdCount += 1;
    return new MpSeries(tempMpBedIdCount + 1, '6F_RW', hasAlarm, 'MP1300');
  });

export const patients = [
  {
    eat: '',
    pat: 'pat1',
    patId: 'patient1',
    patLastName: '',
    patFirstName: '김민수',
    patBirthdate: '1967-01-07',
    patBirthdateFormatCode: 'yyyy-MM-dd',
    sex: 'M',
    race: '',
    otherInfo: '',
    ssn: '123-4567',
    isAdmit: false,
  },
  {
    eat: '',
    pat: 'pat2',
    patId: 'patient2',
    patLastName: '',
    patFirstName: '이서현',
    patBirthdate: '1998-12-31',
    patBirthdateFormatCode: 'yyyy-MM-dd',
    sex: 'F',
    race: '',
    otherInfo: '',
    ssn: '567-8910',
    isAdmit: false,
  },
  {
    eat: '',
    pat: 'pat3',
    patId: 'patient3',
    patLastName: '',
    patFirstName: '박지훈',
    patBirthdate: '2004-07-21',
    patBirthdateFormatCode: 'yyyy-MM-dd',
    sex: 'M',
    race: '',
    otherInfo: '',
    ssn: '891-5678',
    isAdmit: false,
  },
  {
    eat: '',
    pat: 'pat4',
    patId: 'patient4',
    patLastName: '',
    patFirstName: '최예린',
    patBirthdate: '2000-05-01',
    patBirthdateFormatCode: 'yyyy-MM-dd',
    sex: 'F',
    race: '',
    otherInfo: '',
    ssn: '557-2234',
    isAdmit: false,
  },
  {
    eat: '',
    pat: 'pat5',
    patId: 'patient5',
    patLastName: '',
    patFirstName: '정우진',
    patBirthdate: '1992-07-31',
    patBirthdateFormatCode: 'yyyy-MM-dd',
    sex: 'M',
    race: '',
    otherInfo: '',
    ssn: '554-4547',
    isAdmit: false,
  },
  {
    eat: '',
    pat: 'pat6',
    patId: 'patient6',
    patLastName: '',
    patFirstName: '강민지',
    patBirthdate: '1994-12-01',
    patBirthdateFormatCode: 'yyyy-MM-dd',
    sex: 'F',
    race: '',
    otherInfo: '',
    ssn: '114-2347',
    isAdmit: false,
  },
  {
    eat: '',
    pat: 'pat7',
    patId: 'patient7',
    patLastName: '',
    patFirstName: '윤하준',
    patBirthdate: '1987-01-02',
    patBirthdateFormatCode: 'yyyy-MM-dd',
    sex: 'M',
    race: '',
    otherInfo: '',
    ssn: '127-5874',
    isAdmit: false,
  },
  {
    eat: '',
    pat: 'pat8',
    patId: 'patient8',
    patLastName: '',
    patFirstName: '한소영',
    patBirthdate: '1974-06-17',
    patBirthdateFormatCode: 'yyyy-MM-dd',
    sex: 'F',
    race: '',
    otherInfo: '',
    ssn: '258-6541',
    isAdmit: false,
  },
];
