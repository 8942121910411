export const getRandomStr = () => Math.random().toString(36).substring(2, 12);

export const getRandomNumber = (min: number, max: number, step: number) => {
  const numberOfSteps = Math.floor((max - min) / step);
  return Math.floor(Math.random() * numberOfSteps) * step + min;
};

export const getRandomInt = (max: number) => {
  return getRandomNumber(0, max, 1);
};

export class SeededRandom {
  private seed: number;

  constructor(seed: number) {
    this.seed = seed;
  }

  private lcg(): number {
    const a = 1664525;
    const c = 1013904223;
    const m = 2 ** 32;
    this.seed = (a * this.seed + c) % m;
    return this.seed / m;
  }

  public nextInRange(min: number, max: number): number {
    const randomFloat = this.lcg(); // 0과 1 사이의 랜덤 값
    return min + (max - min) * randomFloat;
  }
}
